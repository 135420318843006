export const landing_data = {
    title:'Primus sets you up for success',
    description:'Get serious customers and great support. Earn a salary plus bonuses, with zero expenses. Become a Primus Agent.',
    image: require("../assets/carrer-images/career.jpg"),
}

export const our_advantage = {
    title:'Everything you need to be a top producer',
    details:[
        '100+ serious customer introductions per year',
        'Support staff for calls & paperwork',
        'Salary, health insurance, dues, mileage, vacation',
        'Technology to collaborate with customers',
        'Individualized training to help you meet your goals',
    ]
}

export const values_work = {
    title:'Bring your values to work',
    description:"Your values are our values: to primus real estate in the consumer's favor",
    details:[
        {
            title:'Mission',
            description:'Customers first, sales second'
        },
        {
            title:'Team',
            description:'All for one, one for all'
        },
        {
            title:'Vision',
            description:"Embrace real estate's future, not its past"
        },
    ]
}

export const career_gain = {
    title:'What happens to your career',
    description:"We gives you to good environment for your job to gain.",
    image: require("../assets/carrer-images/career-gain.jpg"),
    details:[
        {
            title:'Gain wealth',
            description:'Opportunity to earn more every year'
        },
        {
            title:'Gain skills',
            description:'The best team, training, technology'
        },
        {
            title:'Gain responsibilities',
            description:"51% of our real estate managers and directors started in agent or support roles"
        },
    ]
}

export const primus_employee = {
    quote:'Primus agents allows me to focus on delivering stellar customer service while having a good work-life balance via its robust, tech-powered solutions and an inclusive, fast-paced, and forward-thinking support team.',
    name:'Mahin Abedin | Principal Agent at Primus'
}