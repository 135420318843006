import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { IoCloseOutline } from "react-icons/io5";
import { PiVideo } from "react-icons/pi";
import YouTube from "react-youtube";

const PropertyVideoGallery = ({ map, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    //for youtube video
    if (playerRef.current) {
      playerRef.current.stopVideo();
    }
  };

  const customStyles = {
    overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      //   marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "40px 10vw 40px 10vw",
      borderRadius: "0px",
      width: "100%",
      height: "100%",
      overflowY: "auto",
      backgroundColor: "rgba(34, 34, 34)",
    },
  };

  //for youtube video
  const playerRef = useRef(null);

  const videoStop = (event) => {
    playerRef.current = event.target;
  };

  const opts = {
    width: "790",
    height: "490",
    playerVars: {
      autoplay: 0,
    },
  };

  /* eslint-disable*/
  function getYouTubeVideoID(url) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }
  /* eslint-disable*/

  return (
    <div>
      <button onClick={openModal}>
        <div className="flex items-center bg-yellow-light text-ash-500 px-2.5 py-1.5 h-fit font-bold flex-1">
          <PiVideo size={25} />
          &nbsp;
          <p>
            {text}&nbsp;
            <span className="bg-gray p-1">{map.length}</span>
          </p>
        </div>
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Image Gallery"
        style={customStyles}
      >
        <button
          onClick={closeModal}
          className="bg-white text-black px-3 py-1 mb-12 relative z-50"
        >
          <IoCloseOutline size={25} />
        </button>
        {map.length === 0 && (
          <div className="flex justify-center items-center w-full h-full">
            <p className="text-white text-lg">Video is not available!!</p>
          </div>
        )}

        {map.length > 0 && (
          <div className="flex justify-center items-center scale-[0.45] sm:scale-75 md:scale-90 lg:scale-110">
            <YouTube
              videoId={getYouTubeVideoID(map[0].video_url)}
              onReady={videoStop}
              opts={opts}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PropertyVideoGallery;
