import {
  AGENT_MAIL_POST_FAILURE,
  AGENT_MAIL_POST_REQUEST,
  AGENT_MAIL_POST_SUCCESS,
  MAKE_APPRAISAL_POST_FAILURE,
  MAKE_APPRAISAL_POST_REQUEST,
  MAKE_APPRAISAL_POST_SUCCESS,
  PROPERTY_ENQUIRY_POST_FAILURE,
  PROPERTY_ENQUIRY_POST_REQUEST,
  PROPERTY_ENQUIRY_POST_SUCCESS,
} from "./actionTypes";

export const postMakeAppraisalRequest = (requestData) => {
  return {
    type: MAKE_APPRAISAL_POST_REQUEST,
    payload: requestData,
    meta: "Make appraisal data request",
  };
};

export const postMakeAppraisalSuccess = (responseData) => {
  return {
    type: MAKE_APPRAISAL_POST_SUCCESS,
    payload: responseData,
    meta: "Make appraisal data success",
  };
};

export const postMakeAppraisalFailure = (error) => {
  return {
    type: MAKE_APPRAISAL_POST_FAILURE,
    payload: error,
    meta: "Make appraisal data failure",
  };
};

export const postPropertyEnquiryRequest = (requestData) => {
  return {
    type: PROPERTY_ENQUIRY_POST_REQUEST,
    payload: requestData,
    meta: "Property enquiry data request",
  };
};

export const postPropertyEnquirySuccess = (responseData) => {
  return {
    type: PROPERTY_ENQUIRY_POST_SUCCESS,
    payload: responseData,
    meta: "Property enquiry data success",
  };
};

export const postPropertyEnquiryFailure = (error) => {
  return {
    type: PROPERTY_ENQUIRY_POST_FAILURE,
    payload: error,
    meta: "Property enquiry data failure",
  };
};

export const postAgentEmailRequest = (requestData) => {
  return {
    type: AGENT_MAIL_POST_REQUEST,
    payload: requestData,
    meta: "Agent email data request",
  };
};

export const postAgentEmailSuccess = (responseData) => {
  return {
    type: AGENT_MAIL_POST_SUCCESS,
    payload: responseData,
    meta: "Agent email data success",
  };
};

export const postAgentEmailFailure = (error) => {
  return {
    type: AGENT_MAIL_POST_FAILURE,
    payload: error,
    meta: "Agent email data failure",
  };
};