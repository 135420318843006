import axios from "axios";
import { RENT_GET_API_URL } from "../../../api/LaravelApiUrl";

export const getApi = async () => {
  const csrfToken = document.head.querySelector(
    'meta[name="csrf-token"]'
  ).content;

  try {
    const response = await axios.get(RENT_GET_API_URL, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": csrfToken,
        "Content-Type": "application/json;charset=utf-8",
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error making GET request:", error);
  }
};
