import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomRightArrow from "./CustomRightArrow";
import CustomLeftArrow from "./CustomLeftArrow";
import { Image } from "./Image";
import PropertyImageGallery from "./PropertyImageGallery";
import PropertyPlanImageGallery from "./PropertyPlanImageGallery";
import PropertyVideoGallery from "./PropertyVideoGallery";

const ImageSlider = ({
  images,
  floorplan_images,
  maps,
  name,
  isLeased,
  isSold,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="relative z-0">
      <div className="mx-[-5vw] sm:mx-[-10vw] xl:mx-[-15vw] mt-[-20px]">
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows={true}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          centerMode={true}
          focusOnSelect={true}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          shouldResetAutoplay
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="w-full h-60 sm:h-[400px] md:h-[500px] lg:h-[650px] pr-2"
            >
              <img
                src={image.url}
                alt={`property-thumbnail ${index + 1}`}
                className="w-full h-full"
              />
            </div>
          ))}
        </Carousel>
        <div className="flex justify-start items-center gap-3 mt-5 mx-[5vw] sm:mx-[10vw] xl:mx-[15vw] flex-wrap">
          <p className="bg-yellow-light text-ash-500 px-2.5 py-1.5 h-fit w-fit font-bold uppercase">
            {name}
          </p>
          <PropertyImageGallery image={images} text="Photos" />
          <PropertyPlanImageGallery image={floorplan_images} text="Floorplan"/>
          <PropertyVideoGallery map={maps} text="Video"/>
        </div>
      </div>
      {/* leased stamp image */}
      {isLeased && (
        <Image
          src="../assets/logos/leased-red-stamp.png"
          className="w-[80px] h-[70px] absolute top-2 left-2 z-50"
        />
      )}
      {isSold && (
        <Image
          src="../assets/logos/sold-red-stamp.png"
          className="w-[90px] h-[70px] absolute top-2 left-2 z-50"
        />
      )}
    </div>
  );
};

export default ImageSlider;
