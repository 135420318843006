import {EXPERT_GET_FAILURE, EXPERT_GET_REQUEST, EXPERT_GET_SUCCESS} from "./actionTypes";

const initialState = {
    isLoading: false,
    experts_profile: [],
    error: {},
  };

  export const expertReducer = (state = initialState, action) => {
    switch (action.type) {
      case EXPERT_GET_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case EXPERT_GET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          experts_profile: action.payload,
        };
      case EXPERT_GET_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };