import React from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import {
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useNavBarOpen } from "./ConextAPI/NavBarContext";

const NavBarMobileSubMenu = ({ name, routeTo, subMenu, onClick }) => {
  const { setOpen } = useNavBarOpen();

  const activeLink = ({ isActive }) => {
    return {
      color: isActive ? "#F7C332" : "black",
    };
  };
  return (
      <>
        <AccordionHeader
          className="text-xs py-3 border-0 font-normal"
          onClick={() => onClick(routeTo)}
        >
          <NavLink
            to={routeTo}
            style={activeLink}
            className="flex justify-center items-center uppercase"
          >
            {name}&nbsp;&nbsp;
            <IoIosArrowDown size={12} color="black" />
          </NavLink>
        </AccordionHeader>
        <AccordionBody className="py-0 flex flex-col gap-1 text-[10px]">
          {subMenu.map((item, index) => (
            <NavLink
              to={item.sub_route}
              className="px-4 py-2 bg-white w-full uppercase"
              onClick={() => setOpen(false)}
              key={index}
            >
              {item.name}
            </NavLink>
          ))}
        </AccordionBody>
      </>
  );
};

export default NavBarMobileSubMenu;
