import React from "react";
import { Image } from "../../../components/Image";
import {
  career_gain,
  landing_data,
  our_advantage,
  values_work,
} from "../../../data/careerData";
import { Button } from "../../../components/Button";
import { BsPatchCheck } from "react-icons/bs";

const CareersPage = () => {
  const image_src = {
    backgroundImage: `url(${landing_data.image})`,
  };
  return (
    <div className="pt-16">
      <div className="bg-gray">
        <div className="block sm:hidden">
          <div
            style={image_src}
            className="bg-cover h-[320px] bg-center w-full"
          ></div>
          <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-8 space-y-5">
            <h1 className="text-2xl lg:text-3xl">{landing_data.title}</h1>
            <p>{landing_data.description}</p>
            <a href="mailto:carrer@primusagents.com">
              <Button
                name="Apply Now"
                className="px-5 py-3 bg-yellow-light text-ash-500 mt-5"
              />
            </a>
          </div>
        </div>
        <div className="hidden sm:flex sm:items-center">
          <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-8 space-y-5">
            <h1 className="text-2xl lg:text-3xl">{landing_data.title}</h1>
            <p>{landing_data.description}</p>
            <a href="mailto:carrer@primusagents.com">
              <Button
                name="Apply Now"
                className="px-5 py-3 bg-yellow-light text-ash-500 mt-5"
              />
            </a>
          </div>
          <div
            style={image_src}
            className="bg-cover h-[450px] bg-center w-full"
          ></div>
        </div>
      </div>
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] space-y-6 py-10 lg:flex lg:space-y-0 lg:space-x-6">
        <div className="space-y-6">
          <h1 className="text-2xl lg:text-3xl">{our_advantage.title}</h1>
          <div className="space-y-3">
            {our_advantage.details.map((item, index) => (
              <div key={index} className="flex gap-1.5">
                <div>
                  <BsPatchCheck className="w-6 h-6 text-yellow" />
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="space-y-4">
          <div className="space-y-4">
            <h1 className="text-2xl lg:text-3xl">{values_work.title}</h1>
            <p>{values_work.description}</p>
          </div>
          <div className="space-y-4">
            {values_work.details.map((item, index) => (
              <div key={index}>
                <h1 className="text-xl">{item.title}</h1>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-gray">
        <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] space-y-6 md:-space-y-2 md:space-x-6 lg:space-x-10 xl:space-x-16 py-10 md:flex md:items-start xl:items-center">
          <Image
            src={career_gain.image}
            className="mb:max-h-[350px] w-full md:max-w-[350px] xl:max-w-[550px] xl:max-h-[500px]"
          />
          <div className="space-y-4">
            <div className="space-y-4">
              <h1 className="text-2xl lg:text-3xl">{career_gain.title}</h1>
              <p>{career_gain.description}</p>
            </div>
            <div className="space-y-4">
              {career_gain.details.map((item, index) => (
                <div key={index}>
                  <h1 className="text-xl">{item.title}</h1>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersPage;
