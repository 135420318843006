import React from "react";
import { explore_section_data } from "../../../data/homeData";
import { Image } from "../../../components/Image";
import { NavLink } from "react-router-dom";
const ExploreSection = () => {
  return (
    <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-20 ">
      <div className="text-center space-y-1 mb-10">
        <h1 className="text-3xl font-playfair">LET US CONNECT YOU</h1>
        <p className="font-lato">What would you like to explore?</p>
      </div>
      <div className="flex flex-col sm:flex-row gap-3 justify-center items-center flex-wrap">
        <NavLink to="/sell">
          <div className="relative h-[250px] w-full md:max-w-[280px] lg:max-w-[320px] cursor-pointer overflow-hidden flex-1 flex justify-center items-center shadow-custom">
            <Image
              src={explore_section_data.sell_image_url}
              className="bg-cover bg-center h-[300px] w-full  hover:scale-[1.05] transition-transform duration-200 ease-in-out"
            />
            <h1 className="font-lato text-xl absolute bg-ash text-white px-10 py-2 rounded text-black/90">
              SELL
            </h1>
          </div>
        </NavLink>
        <NavLink to="/buy">
          <div className="relative h-[250px] w-full md:max-w-[280px] lg:max-w-[320px] cursor-pointer overflow-hidden flex-1 flex justify-center items-center shadow-custom">
            <Image
              src={explore_section_data.buy_image_url}
              className="bg-cover bg-center h-[300px] w-full hover:scale-[1.05] transition-transform duration-200 ease-in-out"
            />
            <h1 className="font-lato text-xl absolute bg-ash text-white px-10 py-2 rounded text-black/90">
              BUY
            </h1>
          </div>
        </NavLink>
        <NavLink to="/rent">
          <div className="relative h-[250px] w-full md:max-w-[280px] lg:max-w-[320px] cursor-pointer overflow-hidden flex-1 flex justify-center items-center shadow-custom">
            <Image
              src={explore_section_data.rent_image_url}
              className="bg-cover bg-center h-[300px] w-full hover:scale-[1.05] transition-transform duration-200 ease-in-out"
            />
            <h1 className="font-lato text-xl absolute bg-ash text-white px-10 py-2 rounded text-black/90">
              RENT
            </h1>
          </div>
        </NavLink>
        <NavLink to="/experts">
          <div className="relative h-[250px] w-full md:max-w-[280px] lg:max-w-[320px] cursor-pointer overflow-hidden flex-1 flex justify-center items-center shadow-custom">
            <Image
              src={explore_section_data.experts_image_url}
              className="bg-cover bg-center h-[300px] w-full hover:scale-[1.05] transition-transform duration-200 ease-in-out"
            />
            <h1 className="font-lato text-xl absolute bg-ash text-white px-10 py-2 rounded text-black/90">
              EXPERTS
            </h1>
          </div>
        </NavLink>
        {/* <NavLink to="/strata">
          <div className="relative h-[250px]  md:max-w-[280px] lg:max-w-[320px]  cursor-pointer overflow-hidden flex-1 flex justify-center items-center shadow-custom">
            <Image
              src={explore_section_data.strata_image_url}
              className="bg-cover bg-center h-[300px] w-full hover:scale-[1.05] transition-transform duration-200 ease-in-out"
            />
            <h1 className="text-xl absolute bg-ash text-white px-10 py-2 rounded text-black/90">
              STARTA
            </h1>
          </div>
        </NavLink> */}
        <NavLink to="/services">
          <div className="relative h-[250px] w-full md:max-w-[280px] lg:max-w-[320px] cursor-pointer overflow-hidden flex-1 flex justify-center items-center shadow-custom">
            <Image
              src={explore_section_data.services_image_url}
              className="bg-cover bg-center h-[300px] w-full hover:scale-[1.05] transition-transform duration-200 ease-in-out"
            />
            <h1 className="font-lato text-xl absolute bg-ash text-white px-10 py-2 rounded text-black/90">
              SERVICES
            </h1>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default ExploreSection;
