import React, { useState } from "react";
import Modal from "react-modal";
import { IoImagesOutline, IoCloseOutline } from "react-icons/io5";
import Carousel from "react-multi-carousel";

const PropertyImageGallery = ({ image, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const customStyles = {
    overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      //   marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "40px 10vw 40px 10vw",
      borderRadius: "0px",
      width: "100%",
      height: "100%",
      overflowY: "auto",
      backgroundColor: "rgba(34, 34, 34)",
    },
  };

  const responsiveStyles = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <button onClick={openModal}>
        <div className="flex items-center bg-yellow-light text-ash-500 px-2.5 py-1.5 h-fit font-bold flex-1">
          <IoImagesOutline className="w-6 h-6" />
          &nbsp;
          <p>
            {text}&nbsp;
            <span className="bg-gray p-1">{image.length}</span>
          </p>
        </div>
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Image Gallery"
        style={customStyles}
      >
        <button
          onClick={closeModal}
          className="bg-white text-black px-3 py-1 mb-2"
        >
          <IoCloseOutline size={25} />
        </button>
        <div>
          <Carousel
            responsive={responsiveStyles}
            additionalTransfrom={0}
            arrows={true}
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
          >
            {image.map((item, index) => (
                <div
                  key={index}
                  className="w-full h-60 sm:h-[400px] md:h-[500px] lg:h-[650px] pr-2"
                >
                  <img
                    src={item.url}
                    alt={`property-thumbnail ${index + 1}`}
                    className="w-full h-full"
                  />
                </div>
              ))}
          </Carousel>
        </div>
      </Modal>
    </div>
  );
};

export default PropertyImageGallery;
