import React, { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { head_office, local_office, office_details } from "../../../data/contactData";

const AccordionDetailsSection = () => {
  const [open, setOpen] = useState(null);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <div className="py-20 px-[5vw] sm:px-[10vw] xl:px-[15vw]">
      <Accordion open={open === 1}>
        <AccordionHeader onClick={() => handleOpen(1)} className="border-none py-3">
          <div className="flex gap-2 items-center">
            {open === 1 ? (
              <AiOutlineMinusCircle className="lg:w-6 lg:h-6" />
            ) : (
              <AiOutlinePlusCircle className="lg:w-6 lg:h-6 " />
            )}
            <p className="uppercase text-[12px] lg:text-[16px]">{office_details.title}</p>
          </div>
        </AccordionHeader>
        <AccordionBody className="lg:text-[16px] pl-[24px] lg:pl-[32px]">
        <div>
            <p><strong className="font-bold">License No:</strong>&nbsp;{office_details.license_no}</p>
            <p><strong className="font-bold">Trading as:</strong>&nbsp;{office_details.trading_as}</p>
            <p><strong className="font-bold">Email:</strong>&nbsp;{office_details.email}</p>
            <p><strong className="font-bold">Website:</strong>&nbsp;{office_details.website}</p>
          </div>
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 2}>
        <AccordionHeader onClick={() => handleOpen(2)} className="border-none py-3">
          <div className="flex gap-2 items-center">
            {open === 2 ? (
              <AiOutlineMinusCircle className="lg:w-6 lg:h-6" />
            ) : (
              <AiOutlinePlusCircle className="lg:w-6 lg:h-6" />
            )}
            <p className="uppercase text-[12px] lg:text-[16px]">{head_office.title}</p>
          </div>
        </AccordionHeader>
        <AccordionBody className="text-[12px] lg:text-[16px] pl-[24px] lg:pl-[32px]">
          <div>
            <p><strong className="font-bold">Phone:</strong>&nbsp;{head_office.phone}</p>
          </div>
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 3}>
        <AccordionHeader onClick={() => handleOpen(3)} className="border-none py-3">
          <div className="flex gap-2 items-center">
            {open === 3 ? (
              <AiOutlineMinusCircle className="lg:w-6 lg:h-6" />
            ) : (
              <AiOutlinePlusCircle className="lg:w-6 lg:h-6" />
            )}
            <p className="uppercase text-[12px] lg:text-[16px]">{local_office.title}</p>
          </div>
        </AccordionHeader>
        <AccordionBody className="text-[12px] lg:text-[16px] pl-[24px] lg:pl-[32px]">
        <div>
            <p><strong className="font-bold">Phone:</strong>&nbsp;{local_office.phone}</p>
          </div>
        </AccordionBody>
      </Accordion>
    </div>
  );
};

export default AccordionDetailsSection;
