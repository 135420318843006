import React from "react";
import { landing_section_data } from "../../../data/contactData";

const LandingSection = () => {
  const image_src = {
    backgroundImage: `url(${landing_section_data.image_url})`,
  };

  return (
    <div className="pt-20 xl:pt-24 relative">
      <div
        style={image_src}
        className="bg-cover bg-center w-full h-[320px] md:h-[450px]"
      ></div>
      <div className="bg-cover h-[320px] md:h-[450px] w-full bg-center bg-yellow/50 absolute top-20 xl:top-24 left-0 right-0 bottom-0"></div>
      <div className="px-[5vw] sm:px-[10vw] py-5 mb:py-10 flex justify-center items-center absolute top-20 xl:top-24 left-0 right-0 bottom-0">
        <div className="sm:w-[60vw] lg:w-auto text-center space-y-4 bg-ash/80 p-6 text-white rounded">
          <h1 className="uppercase text-2xl xl:text-4xl font-bold font-playfair">
            {landing_section_data.title}
          </h1>
          <p>{landing_section_data.description}</p>
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
