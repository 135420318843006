import { combineReducers } from "redux";
import { contactReducer } from "../pages/ContactPage/ReduxStore/reducer";
import { buyReducer } from "../pages/BuyPage/ReduxStore/reducer";
import { expertReducer } from "../pages/ExpertsPage/ReduxStore/reducer";
import { rentReducer } from "../pages/RentPage/ReduxStore/reducer";
import { commercialReducer } from "../pages/CommercialPage/ReduxStore/reducer";
import {
  makeAppraisalReducer,
  propertyEnquiryReducer,
  agentMailReducer,
} from "../redux/redux-mail-store/reducer";
import { documentReducer } from "../redux/document-store/reducer";

export const rootReducer = combineReducers({
  contactData: contactReducer,
  buyData: buyReducer,
  rentData: rentReducer,
  expertData: expertReducer,
  commercialData: commercialReducer,
  makeAppraisalData: makeAppraisalReducer,
  propertyEnquiryData: propertyEnquiryReducer,
  agentMailData: agentMailReducer,
  documentData: documentReducer,
});
