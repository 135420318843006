export const strata_management = {
    title: 'STRATA MANAGEMENT',
    description: 'At Whelan our expertise extends to degrees of strata schemes both large and small. Our portfolio is diverse and is not limited by any means.',
    details:[

        'Building Management Committees (BMC’s)',
        'Commercial Strata Schemes',
        'Community Schemes',
        'Golf Estates',
        'Mixed Residential',
        'Residential Strata Schemes',
        'Retail Strata Schemes',
        'Serviced Apartments'
    
    ]
}

export const strata_community = {
    title: 'STRATA & COMMUNITY MANAGEMENT',
    description: 'A professional Strata Manager can also assist an individual’s Owners Corporation or engage in Community Management with the following:',
    details:[

        'Administration and Agendas',
        'Act as Chairperson',
        'Advice on How to Terminate your Current Strata Manager',
        'Advise on Strata Legislation including Strata Legislation Reform',
        'By-laws',
        'Handle Strata Emergencies Inside and Outside of Business Hours',
        'Manage Compulsory Appointments and Disputes',
        'Manage Maintenance Programs',
        'Maintain Common Seals, Security Keys, Swipes and Strata Roll',
        'Minutes, Meetings and Notices (Strata Committee, Annual General, General)',
        'Preventative Maintenance',
        'Respond to Correspondence, Record Keeping and Archiving'
    ]
}

export const strata_financial = {
    title: 'FINANCIAL MANAGEMENT',
    description: 'At Whelan Property Group we don’t believe clients should be burdened with Strata Financial hardship. We can lend financial assistance to each client when demanding the following',
    details:[

        'Accounting, Administrative and Capital Fund Budgets',
        'Act as Treasurer',
        'Arranging the Preparation of your Annual Tax Returns and Assisting your Auditor',
        'Collection of Levies',
        'Establish and Maintain you Trust Accounts',
        'Financial reporting',
        'Online Access to Financial Reports',
        'Payment of Invoices Daily (or as agreed with your Executive Committee)',
        'Quarterly BAS',
        'Recommend Interest Bearing Accounts',
        'Recovery of Levies in Arrears'
    ]
}

export const strata_thirdParty = {
    title: 'THIRD PARTY RELATIONSHIPS',
    description: 'Our Strata Management does all the heavy lifting so to speak. In areas that aid further upkeep, our Whelan employees maintain solid business relationships with trustworthy independents and providers. Here is where Whelan Property Group’s involvement is proven:',
    details:[

        'Arrange building inspections and expert reports',
        'Assist with Tender selection processes',
        'Deal with local authorities as required',
        'Engage professionals to conduct the Annual Fire Inspection',
        'Engagement of professional services',
        'Investigate ways to reduce your levies',
        'Manage third party contractors',
        'Negotiate contracts',
        'Obtain quotations',
        'Prepare and lodge applications for mediation, adjudication',
        'Liaise with solicitors'
    ]
}

export const strata_insurance = {
    title: 'STRATA INSURANCE',
    description: 'Whelan Property Group understands the need for indemnity cover and at Whelan, our team of experts can assist with professional guidance from beginning to end in the following areas:',
    details:[

        'Arrange insurance valuations as required',
        'Manage insurance claims',
        'Obtain quotations for insurance renewal'
    ]
}

export const strata_additional = {
    title: 'ADDITIONAL SPECIALITY SERVICESE',
    description: 'Aside from all the routine strata management services, Whelan Property Group offers sound assistance with:',
    details:[

        'Defect Management',
        'Easement Management',
        'Project Management'
    ],
}

export const conclusion ={
    first:'Whelan Property Group always acts in accordance with our agreements with your schemes and under the direction of your Strata Committee.',
    last:'The large majority of our Strata Managers are fully licensed by the Department of Fair Trading and some hold personal memberships with the Strata Community Association (SCA).',
}