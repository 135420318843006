import React, { useEffect, useState } from "react";
import landing_expert_section_image from "../../../assets/expert-images/expert_landing.jpg";
import { Image } from "../../../components/Image";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BiCurrentLocation } from "react-icons/bi";
import default_profile_image from "../../../assets/expert-images/profile.jpg";
import { ButtonWithBorder } from "../../../components/Button";
import { social_link } from "../../../data/footerData";
import {
  BiLogoFacebook,
  BiLogoYoutube,
  BiLogoLinkedin,
  BiLogoInstagram,
} from "react-icons/bi";
import PropertyPortfolioTab from "./Expert.PropertyPortfolioTab";
import EmailAgentButton from "./Expert.EmailAgentButton";
import RequestAppraisalButton from "./Expert.RequestAppraisalButton";

let profileImage = "";
let expert_specialty_area = [];

const IndividualProfilePage = () => {
  const expert_id = atob(useParams().id);
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [isAppraisalOpen, setIsAppraisalOpen] = useState(false);

  //redux hooks
  const { experts_profile } = useSelector((state) => state.expertData);
  const [expert_details, setExpertDetails] = useState([]);

  useEffect(() => {
    // Update local state when experts_profile changes
    if (Object.keys(experts_profile).length !== 0) {
      setExpertDetails(experts_profile.data || []);
    }
  }, [experts_profile]);

  //filter expert-id from experts_profile.data
  const expert = expert_details
    .filter((obj) => obj.user_id === expert_id)
    .reduce((acc, obj) => ({ ...acc, ...obj }), {});

  //image url
  const image_src = {
    backgroundImage: `url(${landing_expert_section_image})`,
  };

  //for expert object condition
  if (Object.keys(expert).length > 0) {
    profileImage =
      expert.profile_image.length > 0
        ? expert.profile_image[0].url
        : default_profile_image;
    expert_specialty_area = expert.specialty_area;
  } else {
    profileImage = default_profile_image;
    expert_specialty_area = [];
  }

  const handleAppOpen = () => {
    window.open("tel:", "_self");
  };

  return (
    <>
      {/* background image with name section */}
      <div className="relative pt-20">
        <div
          style={image_src}
          className=" bg-cover bg-center w-full h-[400px] md:h-[450px] xl:h-[550px]"
        ></div>
        <div className="bg-cover h-[400px] md:h-[450px] xl:h-[550px] w-full bg-center bg-ash opacity-60 absolute top-20 left-0 right-0 bottom-0"></div>
        <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] w-full text-white absolute top-20 left-0 right-0 bottom-0 flex flex-col justify-center items-center gap-6">
          <div className="text-center">
            <Image
              src={profileImage}
              className="w-[180px] h-[180px] lg:w-[250px] lg:h-[250px] rounded-full"
            />
            <h1 className="text-2xl lg:text-4xl mt-1">{expert.name}</h1>
            <div className="mt-2">
              <p className="text-black lg:text-xl">
                {expert.designation ? expert.designation : "Property Expert"}
              </p>
              <p className="text-yellow-light lg:text-xl">
                {expert.location ? expert.location : "No precise location"}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* others section  */}
      <div className="py-10 px-[5vw] sm:px-[10vw] xl:px-[15vw]">
        <div className="flex flex-col md:flex-row md:items-start gap-10 md:justify-center">
          {/* about and specialty area section*/}
          <div className="space-y-5 md:border-r-2 md:border-r-[#7e7e7e] md:pr-10">
            <div>
              <h1 className="uppercase mb-3 font-playfair text-xl">
                About&nbsp;{expert.name}
              </h1>
              <p>{expert.about}</p>
            </div>
            <div>
              <h1 className="uppercase mb-3 font-playfair text-xl">
                specialty areas
              </h1>
              <div className="flex flex-wrap">
                {expert_specialty_area.length === 0
                  ? "No suburb are available at the moment!"
                  : expert_specialty_area.map((item, index) => (
                      <div key={index} className="flex items-center gap-1 pr-3">
                        <BiCurrentLocation />
                        <p>{item.specialty_area}</p>
                      </div>
                    ))}
              </div>
            </div>
          </div>

          {/* contact section */}
          <div className="flex flex-col items-center justify-center gap-10 md:sticky md:top-32">
            <div className="flex flex-col gap-3 lg:gap-5 items-center justify-center">
              <h1 className="uppercase font-playfair text-xl truncate">
                Contact&nbsp;{expert.name}
              </h1>
              <ButtonWithBorder
                name={`Call ${expert.company_phone}`}
                className="bg-yellow-light px-6 py-1 hover:bg-white"
                width="w-[250px]"
                onClick={handleAppOpen}
              />
              <ButtonWithBorder
                name="Email Agent"
                className="bg-yellow-light px-6 py-1 hover:bg-white"
                width="w-[250px]"
                onClick={() => setIsEmailOpen(true)}
              />
              <ButtonWithBorder
                name="Request Appraisal"
                className="bg-yellow-light px-6 py-1 hover:bg-white"
                width="w-[250px]"
                onClick={() => setIsAppraisalOpen(true)}
              />
            </div>
            <div className="flex gap-3">
              <a href={social_link.facebook} target="_blank" rel="noreferrer">
                <BiLogoFacebook className="h-9 w-9 p-2 bg-white-500 rounded-full transition-transform duration-300 ease-in-out hover:-translate-y-1 text-[#1877F2]" />
              </a>
              <a href={social_link.linkedin} target="_blank" rel="noreferrer">
                <BiLogoLinkedin className="h-9 w-9 p-2 bg-white-500 rounded-full transition-transform duration-300 ease-in-out hover:-translate-y-1 text-[#0072b1]" />
              </a>
              <a href={social_link.instagram} target="_blank" rel="noreferrer">
                <BiLogoInstagram className="h-9 w-9 p-2 bg-white-500 rounded-full transition-transform duration-300 ease-in-out hover:-translate-y-1 text-[#d62976]" />
              </a>
              <a href={social_link.youtube} target="_blank" rel="noreferrer">
                <BiLogoYoutube className="h-9 w-9 p-2 bg-white-500 rounded-full transition-transform duration-300 ease-in-out hover:-translate-y-1 text-[#CD201F]" />
              </a>
            </div>
          </div>
        </div>
        {/* property porfolio */}
        <div className="pt-20">
          <PropertyPortfolioTab expertID={expert.user_id} />
        </div>

        {/* Email agent Modal Action*/}
        <div
          className={`fixed z-50 flex justify-center items-center overflow-y-auto transform transition-transform duration-300 ease-in-out w-full bg-black/30 left-0 right-0 top-0 bottom-0 ${
            isEmailOpen ? "translate-y-[0px]" : "-translate-y-[200%]"
          }`}
        >
          <EmailAgentButton
            handleCloseModal={setIsEmailOpen}
            expertID={expert_id}
          />
        </div>
        {/* Request appraisal Modal Action*/}
        <div
          className={`fixed z-50 flex justify-center items-center overflow-y-auto transform transition-transform duration-300 ease-in-out w-full bg-black/30 left-0 right-0 top-0 bottom-0 ${
            isAppraisalOpen ? "translate-y-[0px]" : "-translate-y-[200%]"
          }`}
        >
          <RequestAppraisalButton handleCloseModal={setIsAppraisalOpen} />
        </div>
      </div>
    </>
  );
};

export default IndividualProfilePage;
