import {
  DOCUMENT_GET_REQUEST,
  DOCUMENT_GET_SUCCESS,
  DOCUMENT_GET_FAILURE,
} from "./actionTypes";

const initialState = {
  isLoading: false,
  property_documents: {},
  error: {},
};

export const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DOCUMENT_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        property_documents: action.payload,
      };
    case DOCUMENT_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
