import React from 'react'
import PropertyManagementSection from './section/PropertyManagementSection';
import CommercialSection from './section/CommercialSection';
import ConveyancingSection from './section/ConveyancingSection';
import services_landing_image from '../../assets/services-images/service.png';
import { Image } from '../../components/Image';

const ServicesPage = () => {
  const image_src = {
    backgroundImage: `url(${services_landing_image})`,
  }
  return (
    <div className='flex flex-col w-full items-center'>
      <div style={image_src} className='bg-cover h-[450px] w-full bg-center block md:hidden'></div>
      <Image src={services_landing_image} className="h-[500px] xl:h-[600px] w-full hidden md:block" />
      <PropertyManagementSection/>
      <CommercialSection/>
      <ConveyancingSection/>
    </div>
  )
}

export default ServicesPage;