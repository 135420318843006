import React, { useState } from "react";
import { useField } from "formik";

const InputField = (props) => {
  const [field, meta] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div className="w-full">
      <div className="relative">
        <input
          {...field}
          {...props}
          placeholder=""
          onFocus={handleFocus}
          className={`focus:outline-none focus:bg-white w-full px-1.5 pt-5 pb-1 transition-all duration-300 font-lato border-b-[1px] border-b-ash-200`}
        />
        <label className={`${isFocused ? 'top-[-3px] text-black/80' : 'top-3'} text-blue-gray-400 font-lato text-sm absolute z-20 top-3 left-0 ml-[2px] px-1 pointer-events-none transition-all duration-300`}>
          {/* <div className="h-[2px] w-full bg-white absolute -z-10 top-[13px] left-[-1px]"></div> */}
          {props.placeholder}
        </label>
      </div>
      {meta.error && meta.touched && (
        <div className="text-[12px] text-red mt-1">
          {meta.error}
          <sup>*</sup>
        </div>
      )}
    </div>
  );
};

export default InputField;
