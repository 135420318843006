import { call, put, takeEvery } from "redux-saga/effects";
import { EXPERT_GET_REQUEST } from "./actionTypes";
import { getExpertSuccess, getExpertFailure } from "./actions";
import { getApi } from "./getApi";

//worker saga
function* getExpertWorkerSaga(action) {
  try {
    const data = yield call(getApi);
    yield put(getExpertSuccess(data));
    // console.log('expert-data:',data)
  } catch (error) {
    yield put(getExpertFailure(error));
    // console.log('expert-error:',error)
  } 
}

//watcher saga effects
//take every request send with reducer action request
export function* ExpertWatcherSaga() {
  yield takeEvery(EXPERT_GET_REQUEST, getExpertWorkerSaga);
}