import {
    CONTACT_POST_FAILURE,
    CONTACT_POST_REQUEST,
    CONTACT_POST_SUCCESS,
  } from "./actionTypes";
  
  export const postContactRequest = (requestData) => {
    return {
      type: CONTACT_POST_REQUEST,
      payload: requestData,
      meta: "Contact data request",
    };
  };
  
  export const postContactSuccess = (data) => {
    return {
      type: CONTACT_POST_SUCCESS,
      payload: data,
      meta: "Contact data success",
    };
  };
  
  export const postContactFailure = (error) => {
    return {
      type: CONTACT_POST_FAILURE,
      payload: error,
      meta: "Contact data failure",
    };
  };
  