import React, { useRef } from 'react';
import { Button } from '../../../components/Button';
import YouTube from 'react-youtube';

const AdvertiseSectionModal = (props) => {
  const playerRef = useRef(null);
  const videoId = 'JdWPfDnH1Ms';

  const handleClose = (value) => {
    if (playerRef.current) {
      playerRef.current.stopVideo();
    }
    props.handleClose(value);
  };

  const videoStop = (event) => {
    playerRef.current = event.target;
  }

  const opts ={
    width: '790',
    height: '490',
    playerVars: {
        autoplay: 0
      }
  }
  
  return (
    <div
      className={`${
        props.isOpen ? 'visible scale-100' : 'invisible opacity-0 scale-0'
      } opacity-100 transition-all duration-500 ease-in-out fixed z-50 top-0 bottom-0 left-0 right-0 w-full h-full bg-black/90 flex justify-center items-center`}
    >
      <div
        className={`scale-[0.45] sm:scale-75 md:scale-90 lg:scale-110 transform flex flex-col items-end gap-3 text-white`}
      >
        <Button
          name="Close"
          onClick={() => handleClose(!props.isOpen)}
          className="px-3 py-1 bg-ash-200 text-white-500 text-4xl sm:text-lg xl:text-base font-lato"
          primary={true}
        />
        <YouTube videoId={videoId} onReady={videoStop} opts={opts} /> 
      </div>
    </div>
  );
};

export default AdvertiseSectionModal;
