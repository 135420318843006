import React, { useEffect } from "react";
import { MdOutlineApartment } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { VscEye } from "react-icons/vsc";
import PuffLoader from "react-spinners/PuffLoader";
import { getCommercialRequest } from "../ReduxStore/actions";
import Pagination from "../../../components/Pagination";
import NoPropertyFound from "../../../components/NoPropertyFound";
import LeasedCommercialCardComponent from "../component/LeasedCommercialCardComponent";

let itemsPerPage = 8;
let commercialPropertyData = [];

const BusinessCommercialPage = () => {
  //redux hooks
  const dispatch = useDispatch();
  const { isLoading, commercial_properties } = useSelector(
    (state) => state.commercialData
  );

  useEffect(() => {
    dispatch(getCommercialRequest());
  }, [dispatch]);

  // Check commercial_properties
  if (Object.keys(commercial_properties).length > 0) {
    commercialPropertyData = commercial_properties.data || [];
  }

  //filter leased property
  const business_commercial_property = commercialPropertyData.filter(
    (obj) =>
      obj.status === true &&
      obj.category === "commercial" &&
      obj.commercial_category === 3
  );

  return (
    <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] pt-28 lg:pt-32">
      <div className="flex gap-2 items-center font-playfair uppercase text-ash-500 bg-yellow-light py-3 px-3">
        <MdOutlineApartment />
        <p>Our Business Commercial Property</p>
      </div>
      <p className="text-sm text-ash-500 pt-2 flex items-center">
        <VscEye className="w-6 h-6" />
        &nbsp;&nbsp;
        {business_commercial_property.length === 0
          ? "0 Properties Found"
          : `${business_commercial_property.length} Properties Found`}
      </p>
      <div className="py-5">
        <div className="py-5 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
          {isLoading ? (
            <div className="h-[30vh] w-full flex items-center justify-center">
              <PuffLoader color="#36d7b7" size={100} />
            </div>
          ) : business_commercial_property.length === 0 ? (
            <NoPropertyFound />
          ) : (
            <Pagination
              items={business_commercial_property}
              itemsPerPage={itemsPerPage}
              componentName={LeasedCommercialCardComponent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessCommercialPage;
