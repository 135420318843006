const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JLY",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const fullMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getDate = (date) => {
  const getData = new Date(date);
  return getData.getDate();
};

export const getDay = (date) => {
  const getData = new Date(date);
  return dayNames[getData.getDay()];
};

export const getHalfMonth = (date) => {
  const getData = new Date(date);
  return monthNames[getData.getMonth()];
};

export const getFullMonth = (date) => {
  const getData = new Date(date);
  return fullMonthNames[getData.getMonth()];
};

export const convertTo12HourFormat = (time24Hour) => {
  const [hours, minutes] = time24Hour.split(":");
  const parsedHours = parseInt(hours, 10);
  const dateObj = new Date(2000, 0, 1, parsedHours, parseInt(minutes, 10));
  const formattedTime = dateObj.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formattedTime;
};
