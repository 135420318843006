import React, { useState } from "react";
import ImageSlider from "../../../components/ImageSlider";
import { ButtonWithBorder } from "../../../components/Button";
import { TiInputChecked } from "react-icons/ti";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getDate,
  getDay,
  getHalfMonth,
  convertTo12HourFormat,
} from "../../../utils/getDateAndDayObject";
import { checkCurrentTimeWithTargetTime } from "../../../utils/checkCurrentTimeWithTargetTime";
import noImageAvailable from "../../../assets/logos/no-image-available.png";
import { Image } from "../../../components/Image";
import default_profile_image from "../../../assets/expert-images/profile.jpg";
import MakeAnAppointmentModal from "./Buy.MakeAnAppoinmentModal";
// import Map from "../../../components/GoogleMap";
import { CiLocationOn } from "react-icons/ci";
import { dumy_map_url } from "../../../data/mapUrlData";

let buyPropertyData = [];
let agentData = [];

const BuyIndividualPropertyPage = () => {
  const buy_id = atob(useParams().id);
  const [isOpen, setIsOpen] = useState(false);

  //redux hooks
  const { buy_properties = {} } = useSelector((state) => state.buyData);
  const { experts_profile = {} } = useSelector((state) => state.expertData);

  // Check buy_properties
  if (Object.keys(buy_properties).length > 0) {
    buyPropertyData = buy_properties.data || [];
  }
  // Check experts_profile
  if (Object.keys(experts_profile).length !== 0) {
    agentData = experts_profile.data || [];
  }

  //filter buy-id from buy_properties.data
  const individualBuy = buyPropertyData
    .filter((obj) => obj.id === buy_id)
    .reduce((acc, obj) => ({ ...acc, ...obj }), {});

  //filter inspection time with current time
  const current_inspection_times =
    individualBuy && individualBuy.inspections
      ? individualBuy.inspections.filter((obj) => {
          const hasInspections = individualBuy.inspections.length > 0;
          const isFutureInspection =
            hasInspections &&
            checkCurrentTimeWithTargetTime(obj.end_time, obj.date);
          return isFutureInspection;
        })
      : [];

  //filter auction time with current time
  const current_auction_times =
    individualBuy && individualBuy.auctions
      ? individualBuy.auctions.filter((obj) => {
          const hasAuctions = individualBuy.auctions.length > 0;
          const isFutureAuction =
            hasAuctions && checkCurrentTimeWithTargetTime(obj.time, obj.date);
          return isFutureAuction;
        })
      : [];

  //filter agent_id from property_agents data
  const agentsUserIds = individualBuy.agents.map((agent) => agent.user_id);
  const agents = agentData.filter((agentDataItem) =>
    agentsUserIds.includes(agentDataItem.user_id)
  );

  //built-in hooks
  const [isExpanded, setIsExpanded] = useState(false);
  const [description, setDescription] = useState(
    `${individualBuy.description}`.substring(0, 575)
  );

  //see more/less button actions
  const toggleText = () => {
    if (!isExpanded) {
      setDescription(individualBuy.description);
    } else {
      setDescription(`${individualBuy.description}`.substring(0, 575));
    }
    setIsExpanded(!isExpanded);
  };

  //show the property feature item
  const itemsPerDiv = 5;
  let propertyFeature = [];

  //filter for property features
  if (individualBuy && individualBuy.features) {
    for (
      let index = 0;
      index < individualBuy.features.length;
      index += itemsPerDiv
    ) {
      propertyFeature.push(
        individualBuy.features.slice(index, index + itemsPerDiv)
      );
    }
  }

  //image slider check
  let individualBuyImages = [];

  if (
    individualBuy &&
    Array.isArray(individualBuy.images) &&
    individualBuy.images.length > 0
  ) {
    individualBuyImages = individualBuy.images;
  } else {
    individualBuyImages.push({
      url: noImageAvailable,
      property_id: buy_id,
    });
  }

  //floorplan image slider check
  let individualBuyFloorplanImages = [];

  if (
    individualBuy &&
    Array.isArray(individualBuy.floorplan_images) &&
    individualBuy.floorplan_images.length > 0
  ) {
    individualBuyFloorplanImages = individualBuy.floorplan_images;
  } else {
    individualBuyFloorplanImages.push({
      plan_url: noImageAvailable,
      property_id: buy_id,
    });
  }

  const handleAppOpen = () => {
    window.open("tel:", "_self");
  };

  return (
    <div className="pt-28 lg:pt-32 pb-10 px-[5vw] sm:px-[10vw] xl:px-[15vw] flex flex-col gap-6">
      <ImageSlider
        images={individualBuyImages}
        floorplan_images={individualBuyFloorplanImages}
        maps={individualBuy.maps}
        name={individualBuy.status === true ? "Sold" : "For sale"}
        isSold={individualBuy.status}
      />
      <div className="flex flex-col lg:flex-row lg:gap-2 justify-between">
        <div className="flex-1">
          <div className="flex justify-start pb-8">
            <div className="space-y-3">
              <p className="text-2xl lg:text-3xl">
                {individualBuy.street_no}&nbsp;{individualBuy.street_name}
                &nbsp;{individualBuy.suburb}
              </p>
              <p className="text-xl">
                {individualBuy.bed}&nbsp;Beds&nbsp;|&nbsp;{individualBuy.bath}
                &nbsp;Baths&nbsp;|&nbsp;{individualBuy.car}&nbsp;Car
              </p>
              <p className="text-green font-semibold text-xl">
                ${individualBuy.price}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="font-bold">PROPERTY&nbsp;DESCRIPTION</h1>
            <p>{description}</p>
            <p
              className="text-blue cursor-pointer font-bold"
              onClick={toggleText}
            >
              {isExpanded ? "Show Less" : "Show More"}
            </p>
            {description && (
              <div>
                <p>
                  <strong>Disclaimer:</strong> This information is provided by
                  the vendor and collected from third parties. PRIMUS AGENTS
                  does not guarantee, warrant, and/or represent that information
                  contained in this advertising and marketing document is
                  correct. Any interested parties should make their own
                  inquiries as to the accuracy of the information. We exclude
                  all inferred or implied terms, conditions, and warranties
                  arising out of this document and any liability for loss or
                  damage arising therefrom.
                </p>
              </div>
            )}
          </div>
          <hr className="my-2" />
          <div className="flex flex-col gap-3">
            <h1 className="font-bold">PROPERTY&nbsp;DETAILS</h1>
            <div>
              <p>Property&nbsp;Type:&nbsp;{individualBuy.property_type}</p>
              <p>Land&nbsp;Area:&nbsp;{individualBuy.land_area}&nbsp;SQM</p>
            </div>
          </div>
          <hr className="my-2" />
          <div className="flex flex-col gap-3">
            <h1 className="font-bold">PROPERTY&nbsp;FEATURES</h1>
            <div className="lg:flex lg:gap-12">
              {propertyFeature.length !== 0
                ? propertyFeature.map((property, propertyIndex) => (
                    <div key={propertyIndex}>
                      {property.map((item, itemIndex) => (
                        <div
                          key={itemIndex}
                          className="flex items-center gap-1"
                        >
                          <TiInputChecked className="w-6 h-6 text-yellow" />
                          <p>{item.feature}</p>
                        </div>
                      ))}
                    </div>
                  ))
                : "No features are available at the moment!"}
            </div>
          </div>
          <hr className="my-2" />
          <div className="space-y-4">
            <h1 className="font-bold">INSPECTION&nbsp;TIME</h1>
            <div className="flex flex-wrap gap-3">
              {/* inspection time card */}
              {current_inspection_times.length !== 0 ? (
                <div className="flex gap-3 flex-wrap">
                  {current_inspection_times.map((item, index) => (
                    <div
                      key={index}
                      className="flex gap-3 px-5 py-2 flex-1 w-fit border-[1px] border-[#7e7e7e]"
                    >
                      <div className="flex flex-col justify-center">
                        <p>{getHalfMonth(item.date)}</p>
                        <p className="text-[20px] text-center font-black text-yellow">
                          {getDate(item.date)}
                        </p>
                      </div>
                      <div className="border-r-2 border-r-[#7e7e7e]"></div>
                      <div>
                        <p className="text-[14px]">{getDay(item.date)}</p>
                        <div className="flex ">
                          <p className="text-[14px] font-bold lowercase truncate">
                            {convertTo12HourFormat(item.start_time)}
                          </p>
                          <p className="text-[14px] font-bold lowercase truncate">
                            &nbsp;&nbsp;-&nbsp;&nbsp;
                            {convertTo12HourFormat(item.end_time)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                "No inspections are currently scheduled. Contact the agent to arrange an appointment."
              )}
              {/* inspection time card */}
            </div>
            <ButtonWithBorder
              name="Make An Appointment"
              className="hidden mb:block px-12 py-2"
              onClick={() => setIsOpen(true)}
            />
            <div className="mb:hidden fixed z-50 bottom-0 left-0 right-0 bg-yellow-500 w-full flex justify-center py-5">
              <ButtonWithBorder
                name="Make An Appointment"
                className="px-12 py-2 outline-yellow"
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>
          <hr className="my-2" />
          <div className="space-y-4">
            <h1 className="font-bold">AUCTION&nbsp;TIME</h1>
            <div className="flex flex-wrap gap-3">
              {/* Auction time card */}
              {current_auction_times.length !== 0 ? (
                <div className="flex gap-3 flex-wrap">
                  {current_auction_times.map((item, index) => (
                    <div
                      key={index}
                      className="flex gap-3 px-5 py-2 flex-1 w-fit border-[1px] border-[#7e7e7e]"
                    >
                      <div className="flex flex-col justify-center">
                        <p>{getHalfMonth(item.date)}</p>
                        <p className="text-[20px] text-center font-black text-yellow">
                          {getDate(item.date)}
                        </p>
                      </div>
                      <div className="border-r-2 border-r-[#7e7e7e]"></div>
                      <div>
                        <p className="text-[14px]">{getDay(item.date)}</p>
                        <div className="flex ">
                          <p className="text-[14px] font-bold lowercase truncate">
                            {convertTo12HourFormat(item.time)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                "No auctions are currently scheduled. Contact the agent to arrange an appointment."
              )}
              {/* Auction time card */}
            </div>
          </div>
          <hr className="my-2" />
          {/* GoogleMap */}
          <div className="space-y-3">
            <h1 className="font-bold">PROPERTY&nbsp;LOCATION</h1>
            <div className="flex gap-1 items-center ">
              <CiLocationOn className="text-yellow w-[25px] h-[25px]" />
              <p>
                {individualBuy.street_no}&nbsp;{individualBuy.street_name}
                &nbsp;{individualBuy.suburb}
              </p>
            </div>
            <div className="max-w-[600px] max-h-[300px]">
              <iframe
                title="Goggle Embedded Content"
                src={dumy_map_url}
                loading="lazy"
                width="600"
                height="300"
                style={{ width: "100%" }}
              ></iframe>
              {/* <Map
                geoLocation={
                  individualRent.maps[0].length === 0
                    ? {
                        lat: -3.745,
                        lng: -38.523,
                      }
                    : {
                        lat: individualRent.maps[0].latitude,
                        lng: individualRent.maps[0].longitude,
                      }
                }
              /> */}
            </div>
          </div>
          <hr className="my-2" />
        </div>

        {/* property agent info card*/}
        <div>
          {agents.length > 0 && (
            <div className="space-y-3 bg-white h-fit mt-5 md:mt-0 lg:w-[290px] lg:sticky md:top-24 lg:top-28">
              <div className="py-2 bg-yellow-light text-ash-500 uppercase text-center font-bold">
                <p>Contact With Agent</p>
              </div>
              <div className="flex flex-col sm:flex-row lg:flex-col gap-3 flex-wrap justify-center items-center">
                {agents.map((item, index) => (
                  <div key={index} className="bg-gray py-6 mb:max-w-[290px]">
                    <div className="text-center flex flex-col items-center">
                      <Image
                        src={
                          item.profile_image.length !== 0
                            ? item.profile_image[0].url
                            : default_profile_image
                        }
                        className="w-[150px] h-[150px] rounded-full"
                      />
                      <div className="py-3">
                        <p className="uppercase">{item.name}</p>
                        <p className="text-sm text-ash">{item.designation}</p>
                      </div>
                    </div>
                    <div className="space-y-3 px-[10vw] md:px-[3vw]">
                      <ButtonWithBorder
                        name={`Call ${
                          item.company_phone === null ? "" : item.company_phone
                        }`}
                        className="w-full py-1 px-3"
                        onClick={handleAppOpen}
                      />
                      <ButtonWithBorder
                        name="Email"
                        className="w-full py-1 px-3"
                        onClick={setIsOpen}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Make An Appointment Modal Action*/}
      <div
        className={`fixed z-50 flex justify-center items-center overflow-y-auto transform transition-transform duration-300 ease-in-out w-full bg-black/30 left-0 right-0 top-0 bottom-0 ${
          isOpen ? "translate-y-[0px]" : "-translate-y-[200%]"
        }`}
      >
        <MakeAnAppointmentModal
          handleCloseModal={setIsOpen}
          userId={
            individualBuy.agents.length > 0
              ? individualBuy.agents[0].user_id
              : null
          }
          propertyId={buy_id}
        />
      </div>
    </div>
  );
};

export default BuyIndividualPropertyPage;
