import React from 'react'
import { parallax_image_url } from '../../../data/sellData';

const ParallaxSection = () => {
  const image_url = {
    backgroundImage: `url(${parallax_image_url})`,
  } 
  return (
    <div>
        <div style={image_url} className='bg-fixed bg-cover h-[180px] sm:h-[260px] lg:h-[350px] bg-center'></div>
    </div>
  )
}

export default ParallaxSection;