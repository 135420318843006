import React from "react";
import { landing_data } from "../../../data/sellData";
// import { Button } from "../../../components/Button";
// import { NavLink } from 'react-router-dom'

const LandingSection = () => {
  const image_src = {
    backgroundImage: `url(${landing_data.image_url})`,
  };
  return (
    <div className="pt-20">
      <div
        style={image_src}
        className="bg-cover h-[550px] bg-center w-full px-[5vw] sm:px-[10vw] xl:px-[15vw] flex flex-col justify-center"
      >
        <div className="sm:max-w-[600px] flex flex-col justify-center gap-6">
          <div className="text-3xl  xl:text-5xl text-white flex flex-col lg:gap-3">
            <h1 className="font-playfair">{landing_data.title}</h1>
            <h1 className="font-playfair">{landing_data.heading}</h1>
          </div>
          <p className="text-white text-base">{landing_data.description}</p>
          {/* <NavLink to="property-report"> */}
          {/* <Button name="Instant Appraisal" className="px-7 py-2 text-base" primary={true}/> */}
          {/* </NavLink> */}
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
