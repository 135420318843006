export const buy_sub_menu = [
    { name: "Search Property", sub_route: "/buy" },
    { name: "Inspection Times", sub_route: "/buy/inspection-times" },
    { name: "Auction Times", sub_route: "/buy/auction-times" },
    { name: "Sold Property", sub_route: "/buy/sold-properties" },
  ];

  export const rent_sub_menu = [
    { name: "Search Property", sub_route: "/rent" },
    { name: "Inspection Times", sub_route: "/rent/inspection-times" },
    { name: "Leased Property", sub_route: "/rent/leased-properties" },
    { name: "documents", sub_route: "/documents" },
  ];
  
  export const commercial_sub_menu = [
    { name: "Properties for sale", sub_route: "/commercial" },
    { name: "Sold Commercial Property", sub_route: "/commercial/sale-commercial" },
    { name: "Properties for lease", sub_route: "/commercial/commercial-for-lease" },
    { name: "Leased Commercial Property", sub_route: "/commercial/leased-commercial" },
    { name: "Businesses for sale", sub_route: "/commercial/business-for-sale" },
    { name: "Sold Commercial Business", sub_route: "/commercial/business-commercial" },
  ];

  export const experts_sub_menu = [
    // { name: "Find an Expert", sub_route: "/experts" },
    { name: "Market Appraisal", sub_route: "/experts/market-appraisal" },
    { name: "Careers with us", sub_route: "/experts/careers" },
  ];