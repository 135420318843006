import { call, put, takeEvery } from "redux-saga/effects";
import { DOCUMENT_GET_REQUEST } from "./actionTypes";
import { getDocumentSuccess, getDocumentFailure } from "./actions";
import { getApi } from "./getApi";

//worker saga
function* getDocumentWorkerSaga(action) {
  try {
    const data = yield call(getApi);
    yield put(getDocumentSuccess(data));
    // console.log('data:',data)
  } catch (error) {
    yield put(getDocumentFailure(error));
    // console.log('error:',error)
  }
}

//watcher saga effects
//take every request send with reducer action request
export function* DocumentWatcherSaga() {
  yield takeEvery(DOCUMENT_GET_REQUEST, getDocumentWorkerSaga);
}
