import React from "react";
import { landing_section_data } from "../../data/contactData";

const PrivacyPolicyPage = () => {
  const image_src = {
    backgroundImage: `url(${landing_section_data.image_url})`,
  };
  return (
    <>
      <div className="relative pt-20 xl:pt-24">
        <div
          style={image_src}
          className="bg-cover bg-center w-full h-[320px] md:h-[450px]"
        ></div>
        <div className="bg-cover h-[320px] md:h-[450px] w-full bg-center bg-yellow/50 absolute top-20 xl:top-24 left-0 right-0 bottom-0"></div>
        <div className="px-[5vw] sm:px-[10vw] py-5 mb:py-10 flex justify-center items-center absolute top-20 xl:top-24 left-0 right-0 bottom-0">
          <div className="sm:w-[60vw] lg:w-auto text-center space-y-4">
            <h1 className="uppercase text-2xl xl:text-4xl font-bold font-playfair">
              Privacy policy
            </h1>
          </div>
        </div>
      </div>
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-20">
        <div className="space-y-4">
          <p>
            <strong>What is Personal Information?</strong>
          </p>
          <p>
            When used in this Privacy Policy, the term “Personal Information”
            has the meaning given to it in the Act. In general terms, it is any
            information that can be used to personally identify you. This may
            include your name, address, telephone number, email address and
            profession or occupation. If the information we collect personally
            identifies you, or you are reasonably identifiable from it, the
            information will be considered Personal Information.
          </p>
          <p>
            <strong>
              What type of Personal Information does primus property collect and
              hold about me?
            </strong>
          </p>
          <p>
            Primus property may collect any of the following information about
            you if you are a current or prospective vendor, purchaser or
            landlord of real estate or if you visit our website or make an
            enquiry with us via another method:
          </p>
          <div>
            <li>
              contact details (including, name, address, telephone number and
              email address);
            </li>
            <li>driver’s license number;</li>
            <li>current property ownership and title details;</li>
            <li>desired property ownership details; and</li>
            <li>details of properties sold or acquired by you</li>
          </div>
          <p>
            If you are a current or prospective tenant for a rental property,
            primus property may collect the following additional information
            from you:
          </p>
          <div>
            <li>
              contact details (including, name, address, telephone number and
              email address);
            </li>
            <li>driver’s license number;</li>
            <li>
              employment details such as your employment status, employer,
              salary, length of employment;
            </li>
            <li>other sources of income; and</li>
            <li>
              personal and financial referees whom we may contact about your
              application.
            </li>
          </div>
          <p>
            Sometimes primus property may also need to ask you for other forms
            of Personal Information to enable us to provide services to you.
          </p>
          <p>
            <strong>
              How does primus property collect Personal Information about me?
            </strong>
          </p>
          <p>
            primus property collects your Personal Information directly from you
            unless it is unreasonable or impracticable to do so. When collecting
            Personal Information from you, we may collect in various ways,
            including through when you write to or email us, when you access and
            use our website and during conversations between you and our
            representatives;
          </p>
          <p>
            primus property may collect information about you from the following
            third party sources:
          </p>
          <div>
            <li>
              various databases in the public domain such as Telstra White
              Pages, Australia Post DPID database, Titles Office or other
              property databases;
            </li>
            <li>
              credit reporting bodies, law enforcement agencies and other
              government entities; and
            </li>
            <li>
              referrals and recommendations from existing clients of primus
              property.
            </li>
          </div>
          <p>
            As part of its due diligence process prior to entering into lease or
            rental agreements with you, primus property may disclose your
            information to authorised credit-checking agencies.
          </p>
          <p>
            <strong>
              What happens if we can’t collect your Personal Information?
            </strong>
          </p>
          <p>
            If you do not provide us with the Personal Information described
            above, some or all of the following may happen:
          </p>
          <div>
            <li>
              we may not be able to provide our services to you, either to the
              same standard or at all;
            </li>
            <li>
              we may not be able to provide you with information about services
              that you may want, including information about new properties that
              are available for lease or purchase; or
            </li>
            <li>
              we may be unable to tailor the content of our website to your
              preferences and your experience of our website may not be as
              enjoyable or useful.
            </li>
          </div>
          <p>
            <strong>
              Why does primus property collect, hold, use and disclose your
              Personal Information?
            </strong>
          </p>
          <p>
            primus property collects this information for a range of purposes
            including:
          </p>
          <div>
            <li>
              enable primus property to provide you with the services and/or
              products you may require;
            </li>
            <li>
              to comply with local, state and federal legislation or
              regulations, including with respect to real estate property sales,
              rental and administration;
            </li>
            <li>
              to enable primus property and its third party partner businesses
              to assist you with related or ancillary services, as required;
            </li>
            <li>
              for our internal administrative, marketing, planning, product
              development and research requirements
            </li>
            <li>
              to share your Personal Information within the primus property
              group (including between primus property Pty Ltd’s related
              entities and primus property franchisees), contractors or service
              providers;
            </li>
            <li>
              to update primus property’s records and keep your contact details
              up to date;
            </li>
            <li>
              to deal with your queries or customer service issues promptly,
              whether by email, telephone or mail;
            </li>
            <li>
              to keep you up-to-date with services we think would be of
              particular interest to you;
            </li>
            <li>to conduct relevant business processing functions; and</li>
            <li>to process and respond to any complaint made by you.</li>
          </div>
          <p>
            Your Personal Information will not be shared, sold, rented or
            disclosed other than as described in this Privacy Policy.
          </p>
          <p>
            <strong>
              How does primus property use the Personal Information held about
              me?
            </strong>
          </p>
          <p>
            Any Personal Information about you that primus property collects and
            records will only be used or disclosed by primus property for the
            purpose of:
          </p>
          <div>
            <li>
              compliance with obligations under real-estate regulations and laws
              applicable for all states in Australia;
            </li>
            <li>
              Helping primus property efficiently and effectively deliver and
              improve on products and services;
            </li>
            <li>
              any other purpose if you would reasonably expect primus property
              to use or disclose that Personal Information for that other
              purpose and that other purpose is related to the purpose for which
              the information is collected;
            </li>
            <li>
              to provide you with access to protected areas of our website and
              to assess the performance of our website and to improve the
              operation of our website;
            </li>
            <li>
              to conduct business processing functions including sharing
              Personal Information within primus property and with its
              contractors, service providers or other third parties; and
            </li>
            <li>
              for primus property’s administrative, planning, product or service
              development, quality control and research purposes; and
            </li>
            <li>complying with any other relevant laws or regulations.</li>
          </div>
          <p>
            primus property may also use or disclose the information it collects
            for any other purpose specified to you at the time of collection.
          </p>
          <p>
            If primus property wishes to use or disclose any of your Personal
            Information in any other ways than described above, primus property
            will not do so unless it:
          </p>
          <div>
            <li>has your express consent to do so;</li>
            <li>
              has reasonable grounds to believe that the use or disclosure is
              necessary to prevent a threat to life or health; or
            </li>
            <li>
              has reasonable grounds to believe that the use or disclosure is
              required by law or to enforce the law.
            </li>
          </div>
          <p>
            <strong>What about direct marketing?</strong>
          </p>
          <p>
            We may send you direct marketing communications and information
            about our services that we consider may be of interest to you. These
            may include:
          </p>
          <div>
            <li>
              offering to provide you with products or services provided by
              primus property and third party business partners; or
            </li>
            <li>
              sending you news and other information about primus property’s
              activities and general promotional material which primus property
              believes may be of interest to you.
            </li>
          </div>
          <p>
            These communications may be sent in various forms, including mail,
            SMS, fax and email, in accordance with applicable marketing laws,
            such as the Spam Act 2003 (Cth). When primus property does this,
            primus property will provide you with an express opportunity to
            decline receiving any further communication from primus property of
            this type via an opt-out mechanism. If you indicate a preference for
            a method of communication, we will endeavour to use that method
            whenever practical to do so.
          </p>
          <p>
            You may at any time request not to receive direct marketing from
            primus property by contacting us (see the details below) or by using
            opt-out facilities provided in the marketing communications and we
            will then ensure that your name is removed from our mailing list.
          </p>
          <p>
            <strong>The primus property website</strong>
          </p>
          <p>
            Primus property’s privacy policy also applies to our website at
            www.primusagents.com.au
          </p>
          <p>
            When you access our website, we may send a “cookie” (which is a
            small summary file containing a unique ID number) to your computer.
            This enables us to recognise your computer and greet you each time
            you visit our website without bothering you with a request to
            register. It also enables us to keep track of services you view so
            that, if you consent, we can send you news about those services. We
            also use cookies to measure traffic patterns, to determine which
            areas of our website have been visited and to measure transaction
            patterns in the aggregate. We use this to research our users’ habits
            so that we can improve our online services and create a better
            online experience for you. Our cookies do not collect Personal
            Information, although they do identify your browser. If you do not
            wish to receive cookies, you can set your browser so that your
            computer does not accept them. We may log IP addresses (that is, the
            electronic addresses of computers connected to the internet) to
            analyse trends, administer the website, track users movements, and
            gather broad demographic information.
          </p>
          <p>
            As our website is linked to the Internet, and the Internet is
            inherently insecure, we cannot provide any assurance regarding the
            security of transmission of information you communicate to us
            online. We also cannot guarantee that the information you supply
            will not be intercepted while being transmitted over the Internet.
            Accordingly, any Personal Information or other information, which
            you transmit to us online, is transmitted at your own risk.
          </p>
          <p>
            Our website may contain links to other websites operated by third
            parties. We make no representations or warranties in relation to the
            privacy practices of any third party website and we are not
            responsible for the privacy policies or the content of any third
            party website. Third party websites are responsible for informing
            you about their own privacy practices.
          </p>
          <p>
            <strong>
              Can I be anonymous when I deal with primus property?
            </strong>
          </p>
          <p>
            To some extent you can preserve your anonymity when you deal with
            primus property. You can browse our website without providing any
            Personal Information, you can access our real-estate advertising in
            various publications and you can generally attend auctions without
            providing personal details if you do not intend to bid.
          </p>
          <p>
            However, when you enter into transactions with primus property
            (including primus property franchisees) that involve the sale,
            purchase or rental of real estate property, then various Acts,
            statutory legislation and regulations require you to provide primus
            property with Personal Information so as to identify you.
          </p>
          <p>
            <strong>
              What happens if I want to access or correct the Personal
              Information held about me?
            </strong>
          </p>
          <p>
            You may request access to your Personal Information held by primus
            property, at any time by contacting us (see details below). We will
            try to provide you with suitable means of accessing your Personal
            Information (for example, by mailing or emailing it to you), subject
            to some exceptions. We will respond to your request for access
            within 14 days and endeavour to provide the requested information
            within 30 days.
          </p>
          <p>
            Primus property may charge you a reasonable fee to cover its
            administrative and other reasonable costs in providing the
            information to you. primus property will not charge for simply
            making the request and will not charge for making any corrections to
            your Personal Information.
          </p>
          <p>
            There may be instances where primus property cannot grant you access
            to the Personal Information we hold. For example, we may not be able
            to provide access to information in the following situations:
          </p>
          <div>
            <li>
              where in primus property’s opinion providing your access may
              create a serious threat to the life or health of any individual or
              may be an unreasonable intrusion into the privacy of another
              individual;
            </li>
            <li>
              where your request for access is, in primus property’s opinion,
              frivolous or vexatious; or
            </li>
            <li>
              where providing access would be unlawful, may prejudice an
              investigation of possible unlawful activity, may prejudice
              enforcement of law, or denying access is specifically authorised
              by law.
            </li>
          </div>
          <p>
            If for any reason primus property does not allow you to access your
            Personal Information, primus property will provide you with reasons
            in writing for its decision.
          </p>
          <p>
            If you believe that Personal Information primus property holds about
            you is incorrect, incomplete or inaccurate, then you may request
            that we amend it. primus property will consider if the information
            requires amendment. If primus property does not agree that there are
            grounds for amendment, then primus property will add a note to the
            Personal Information stating that you disagree with it.
          </p>
          <p>
            <strong>
              Who does primus property disclose my Personal Information to?
            </strong>
          </p>
          <p>We may disclose your Personal Information:</p>
          <div>
            <li>
              within primus property, including between primus property
              franchisees and subsidiaries of primus property Pty Ltd;
            </li>
            <li>
              to primus property employees and our contractors or service
              providers for the purposes of operation of our website or our
              business, fulfilling requests by you, and to otherwise provide
              services to you;
            </li>
            <li>
              if you are a tenant of any property that we are managing, to the
              National Tenancy Database;
            </li>
            <li>
              to suppliers and other third parties with whom we have commercial
              relationships, for business, marketing, and related purposes; and
            </li>
            <li>
              to any organisation for any authorised purpose with your express
              consent.
            </li>
          </div>
          <p>
            There are some instances when primus property (including primus
            property franchisees) need to provide your Personal Information to
            third parties. primus property or primus property franchisees may be
            bound by law to provide your details to government-related bodies,
            including the Titles Office or the Residential Tenancies Bond
            Authority.
          </p>
          <p>
            Sometimes primus property or primus property franchisees may need to
            outsource some of our administrative and other property-related
            functions to external organisations such as advertising agencies,
            signwriters, building and garden maintenance contractors or
            consultants such as accountants, lawyers, surveyors and other
            advisors. Other third parties may include web hosting providers, IT
            systems administrators, mailing houses, couriers, payment
            processors, data entry service providers, electronic network
            administrators, debt collectors, and other business advisors and
            consultants. Most of the above organisations are bound by express or
            implied confidentiality obligations to primus property.
          </p>
          <p>
            Primus property Pty Ltd may also need to provide your details to
            primus property franchisees. primus property may also provide your
            details to third party business partners for the purpose of offering
            to provide you with products or services provided by primus property
            or these business partners that primus property considers relevant
            to you.
          </p>
          <p>
            Primus property does not sell your personal details to other
            organisations. We may however use the information about you to
            assist us with internal marketing and research.
          </p>
          <p>
            We may disclose Personal Information to our related bodies corporate
            and third party suppliers and service providers located overseas for
            some of the purposes listed above.
          </p>
          <p>
            We take reasonable steps to ensure that the overseas recipients of
            your Personal Information do not breach the privacy obligations
            relating to your Personal Information.
          </p>
          <p>
            We may disclose your Personal Information to entities located
            outside of Australia, including our data hosting and other IT
            service providers, who may pass information to secondary data
            hosting providers located outside of Australia.
          </p>
          <p>
            <strong>
              How does primus property keep my Personal Information secure?
            </strong>
          </p>
          <p>
            Primus property will take reasonable steps to ensure information
            primus property collects, uses or discloses is stored in a secure
            environment that is accessed only by persons authorised by primus
            property so as to prevent interference, misuse, loss, unauthorised
            access, modification or disclosure.
          </p>
          <p>
            If the Personal Information is no longer needed for any purpose,
            primus property will take reasonable steps to destroy or permanently
            de-identify the Personal Information.
          </p>
          <p>
            Primus property endeavours to provide a secure environment and a
            reliable system but you should be aware that there are inherent
            risks associated with the electronic storage and transmission of
            information (particularly via the Internet) which cannot be
            guaranteed to be 100% secure.
          </p>
          <p>
            <strong>What if I have a complaint or concern?</strong>
          </p>
          <p>
            Primus property may change this Privacy Policy from time to time.
            Any updated versions of this Privacy Policy will be posted on our
            website and will be effective from the date of posting.
          </p>
          <p>This privacy policy was last updated on 01 December 2023.</p>
          <p>
            Although primus property intends to observe this policy at all
            times, it is not legally binding on primus property in any way
            unless as otherwise described as required under the relevant
            legislation. From time to time primus property may regard it as
            necessary or desirable to act outside the policy. primus property
            may do so, subject only to any other applicable contractual rights
            you have and any statutory rights you have under the Act or other
            applicable legislation.
          </p>{" "}
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
