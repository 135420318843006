import React, { useEffect } from "react";
import { MdOutlineApartment } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getBuyRequest} from "../ReduxStore/actions";
import { VscEye } from "react-icons/vsc";
import PuffLoader from "react-spinners/PuffLoader";
import NoPropertyFound from "../../../components/NoPropertyFound";
import Pagination from "../../../components/Pagination";
import SoldCardComponent from "../component/SoldCardComponent";

let itemsPerPage = 8;
let buyPropertyData = [];

const BuySoldPage = () => {
  //redux hooks
  const dispatch = useDispatch();
  const { isLoading, buy_properties } = useSelector((state) => state.buyData);

  useEffect(() => {
    dispatch(getBuyRequest());
  }, [dispatch]);

  // Check buy_properties
  if (Object.keys(buy_properties).length > 0) {
    buyPropertyData = buy_properties.data || [];
  }

  //filter leased property from buy_properties.data
  const sold_property = buyPropertyData.filter(
    (obj) => obj.status === true && obj.category === "buy"
  );

  return (
    <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] pt-28 lg:pt-32">
      <div className="flex gap-2 items-center font-playfair uppercase text-ash-500 bg-yellow-light py-3 px-3">
        <MdOutlineApartment />
        <p>Our Sold Property</p>
      </div>
      <p className="text-sm text-ash-500 pt-2 flex items-center">
        <VscEye className="w-6 h-6" />
        &nbsp;&nbsp;
        {sold_property.length === 0
          ? "0 Properties Found"
          : `${sold_property.length} Properties Found`}
      </p>
      <div className="py-5">
        <div className="py-5 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
          {isLoading ? (
            <div className="h-[30vh] w-full flex items-center justify-center">
              <PuffLoader color="#36d7b7" size={100} />
            </div>
          ) : sold_property.length === 0 ? (
            <NoPropertyFound />
          ) : (
            <Pagination
              items={sold_property}
              itemsPerPage={itemsPerPage}
              componentName={SoldCardComponent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BuySoldPage;
