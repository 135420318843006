import React from "react";
import { Image } from "../../../components/Image";
import default_profile_image from "../../../assets/expert-images/profile.jpg";
import { useNavigate } from "react-router-dom";

const ExpertCardComponent = ({data}) => {
  const navigate = useNavigate();

    //handle individual expert details
    const handleExpertProfile = (expert_id) => {
      navigate(`/experts/${btoa(expert_id)}`);
    };


  return (
    <div onClick={() => handleExpertProfile(data.user_id)} className="bg-gray flex-1 min-w-[280px] lg:max-w-[320px] flex flex-col items-center gap-3 py-5 rounded cursor-pointer shadow-custom transition-transform duration-300 ease-in-out hover:scale-[1.01]">
      <Image src={data.profile_image.length !== 0 ? data.profile_image[0].url : default_profile_image} className="w-[250px] h-[250px] rounded-full"/>
      <div className="flex flex-col text-center">
        <h1 className="font-bold text-yellow-200">{data.name}</h1>
        <p className="text-yellow-200">{data.designation ? data.designation : "Property Expert"}</p>
        <p className="text-yellow">{data.location ? data.location : ""}</p>
      </div>
    </div>
  );
};

export default ExpertCardComponent;
