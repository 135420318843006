import React from "react";
import { Image } from "../../../components/Image";
import { IoMdBed, IoIosCar } from "react-icons/io";
import { MdBathtub } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { convertTo12HourFormat } from "../../../utils/getDateAndDayObject";
import noImageAvailable from "../../../assets/logos/no-image-available.png";

const RentInspectionCardComponent = ({ data, targetDate }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray md:flex rounded">
      <div>
        <Image
          src={data.images.length > 0 ? data.images[0].url : noImageAvailable}
          className="max-h-[200px] w-full md:max-w-[180px] lg:max-w-[300px] md:h-full "
        />
      </div>
      <div className="p-3 md:p-4 lg:pr-10 flex flex-col gap-3 md:flex-row md:justify-between md:items-center md:w-full">
        <div className="space-y-0.5 text-yellow-200">
          <p className="md:w-[20vw]">
            {data.street_no}&nbsp;{data.street_name}
            &nbsp;{data.suburb}
          </p>
          <div className="flex items-center gap-2">
            <div className="flex gap-1 items-center">
              <IoIosCar className="w-6 h-6" />
              <p>{data.car}</p>
            </div>
            <div className="flex gap-1 items-center">
              <IoMdBed className="w-6 h-6" />
              <p>{data.bed}</p>
            </div>
            <div className="flex gap-1 items-center">
              <MdBathtub className="w-6 h-6" />
              <p>{data.bath}</p>
            </div>
          </div>
          <p className="text-green font-semibold">
            ${data.price}&nbsp;per week
          </p>
        </div>
        <div>
          {data.inspections.map((item, index) => (
            <div key={index}>
              {item.date === targetDate ? (
                <div className="lowercase flex items-center">
                  <p>{convertTo12HourFormat(item.start_time)}</p>
                  <p>
                    &nbsp;&nbsp;-&nbsp;&nbsp;
                    {convertTo12HourFormat(item.end_time)}
                  </p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <p
          className="text-green hover:text-midnight cursor-pointer"
          onClick={() => navigate(`/rent/${btoa(data.id)}`)}
        >
          View Details
        </p>
      </div>
    </div>
  );
};

export default RentInspectionCardComponent;
