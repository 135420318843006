import React, { useEffect, useState } from "react";
import { ButtonIcon } from "../../../components/Button";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const SearchSection = () => {
  const navigate = useNavigate();

  const [checkData, setCheckData] = useState(null);
  const [selectedData, setSelectedData] = useState({
    suburb: "",
  });
  const [selectedItem, setSelectedItem] = useState("buy");

  useEffect(() => {
    if (selectedData.suburb.length !== 0 && selectedData.suburb !== "") {
      setCheckData(true);
    }
  }, [selectedData]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (selectedData.suburb.length === 0 && selectedData.suburb === "") {
      setCheckData(false);
    } else {
      if (selectedItem === "buy") {
        navigate(`/buy?search=${selectedData.suburb}`);
      } else if (selectedItem === "rent") {
        navigate(`/rent?search=${selectedData.suburb}`);
      } else if (selectedItem === "commercial") {
        navigate(`/commercial?search=${selectedData.suburb}`);
      } else if (selectedItem === "experts") {
        navigate(`/experts?search=${selectedData.suburb}`);
      }
    }
  };

  return (
    <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-20 space-y-10 bg-yellow-light w-full">
      <div className="flex flex-col items-center w-full gap-6">
        <div className="text-center text-2xl text-ash-500 uppercase">
          <h1 className="font-lato">Whatever it is you want,</h1>
          <h1 className="font-lato">we’ll have the solution you need.</h1>
        </div>
        <div className="w-full max-w-[820px]">
          <div className="flex flex-wrap items-center gap-3 text-center">
            {/* <p className="flex-1 uppercase truncate font-lato text-ash-500 px-2 py-0.5 border border-dashed">
              Select item
            </p> */}
            <p
              onClick={() => setSelectedItem("buy")}
              className={`flex-1 uppercase ${
                selectedItem === "buy"
                  ? "bg-ash-200 text-white"
                  : "bg-white text-ash-500"
              } w-fit px-3 py-1 rounded cursor-pointer shadow-custom-500`}
            >
              Buy
            </p>
            <p
              onClick={() => setSelectedItem("rent")}
              className={`flex-1 uppercase ${
                selectedItem === "rent"
                  ? "bg-ash-200 text-white"
                  : "bg-white text-ash-500"
              } w-fit px-3 py-1 rounded cursor-pointer shadow-custom-500`}
            >
              Rent
            </p>
            <p
              onClick={() => setSelectedItem("commercial")}
              className={`flex-1 uppercase ${
                selectedItem === "commercial"
                  ? "bg-ash-200 text-white"
                  : "bg-white text-ash-500"
              } w-fit px-3 py-1 rounded cursor-pointer shadow-custom-500`}
            >
              Commercial
            </p>
            <p
              onClick={() => setSelectedItem("experts")}
              className={`flex-1 uppercase ${
                selectedItem === "experts"
                  ? "bg-ash-200 text-white"
                  : "bg-white text-ash-500"
              } w-fit px-3 py-1 rounded cursor-pointer shadow-custom-500`}
            >
              Experts
            </p>
          </div>
          <form className="flex gap-2 mt-4" onSubmit={handleFormSubmit}>
            <input
              type="text"
              className="font-lato w-full px-4 py-2.5 md:px-6 md:py-3 rounded shadow-custom-500 focus:outline-gray border border-white-500"
              placeholder="Search by Suburb..."
              value={selectedData.suburb}
              onChange={(event) =>
                setSelectedData({
                  ...selectedData,
                  suburb: event.target.value,
                })
              }
            />
            <ButtonIcon
              className="bg-white px-4 py-2.5 md:px-6 md:py-3 rounded mb:px-10 shadow-custom-500 cursor-pointer border border-white-500"
              Icon={<BsSearch className="w-8 h-6" />}
              type="submit"
            />
          </form>
          {checkData === false && (
            <p className="text-red text-sm mt-1">
              Please, fill out the input field!
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
