import React from 'react';
import LandingSection from './section/LandingSection';
import SearchSection from './section/SearchSection';
import ExploreSection from './section/ExploreSection';
import AdvertiseSection from './section/AdvertiseSection';
import OurStorySection from './section/OurStorySection';
import FormSection from './section/FormSection';

const HomePageLayout = () => {
  return (
    <>
      <LandingSection/>
      <SearchSection/>
      <ExploreSection/>
      <AdvertiseSection/>
      <OurStorySection/>
      <FormSection/>
    </>
  )
}

export default HomePageLayout;
