import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import BuySearchComponent from "./component/BuySearchComponent";
import BuyCardComponent from "./component/BuyCardComponent";
import { useDispatch, useSelector } from "react-redux";
import { getBuyRequest } from "./ReduxStore/actions";
import PuffLoader from "react-spinners/PuffLoader";
import NoPropertyFound from "../../components/NoPropertyFound";
import { getExpertRequest } from "../ExpertsPage/ReduxStore/actions";
import { searchProperty } from "../../utils/searchProperty";
import { useLocation } from "react-router-dom";

const itemsPerPage = 8;

const BuyPageLayout = () => {
  const queryLocation = useLocation();
  const queryParams = new URLSearchParams(queryLocation.search);
  const receivedData = queryParams.get("search");

  const [searchData, setSearchData] = useState([]);
  const [searchOutlet, setSearchOutlet] = useState(false);
  const [buyProperties, setBuyProperties] = useState([]);

  //use redux hooks
  const dispatch = useDispatch();
  const { isLoading, buy_properties } = useSelector((state) => state.buyData);

  useEffect(() => {
    dispatch(getBuyRequest());
    // call expert_profile
    dispatch(getExpertRequest());
  }, [dispatch]);

  useEffect(() => {
    // Update local state when data changes
    if (Object.keys(buy_properties).length !== 0) {
      setBuyProperties(buy_properties.data || []);
    }
  }, [buy_properties]);

  //filter available rent property from rent_properties.data
  const available_buy_properties = buyProperties.filter(
    (obj) => obj.status === false && obj.category === "buy"
  );

  //it used when home page search active
  useEffect(() => {
    if (receivedData !== null) {
      const filterExpert = searchProperty(buyProperties, {
        suburb: receivedData,
      });
      setSearchData(filterExpert);
      setSearchOutlet(true);
    }
  }, [receivedData, buyProperties]);

  const handleSearchInput = (searchCriteria) => {
    const filteredProperties = searchProperty(buyProperties, searchCriteria);
    setSearchData(filteredProperties);
    setSearchOutlet(true);
  };

  return (
    <>
      <BuySearchComponent
        totalProduct={available_buy_properties.length}
        getInput={handleSearchInput}
      />
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-10">
        {isLoading ? (
          <div className="h-[30vh] w-full flex items-center justify-center">
            <PuffLoader color="#36d7b7" size={100} />
          </div>
        ) : (
          <>
            {searchOutlet ? (
              <>
                {searchData.length === 0 ? (
                  <NoPropertyFound />
                ) : (
                  <>
                    <Pagination
                      items={searchData}
                      itemsPerPage={itemsPerPage}
                      componentName={BuyCardComponent}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {available_buy_properties.length === 0 ? (
                  <NoPropertyFound />
                ) : (
                  <Pagination
                    items={available_buy_properties}
                    itemsPerPage={itemsPerPage}
                    componentName={BuyCardComponent}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BuyPageLayout;
