import React from 'react';
import { BsChevronDoubleLeft } from "react-icons/bs";

const CustomLeftArrow = ({ onClick, ...rest }) => {
    return (
        <button
            onClick={() => onClick()}
            className="custom-arrow custom-left-arrow"
            {...rest}
        >
            <BsChevronDoubleLeft/>
        </button>
    );
};

export default CustomLeftArrow;