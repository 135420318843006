export const property_agents = [
    {
        id: 1,
        name: "Ayman Mohammad",
        designation: "Property Manager",
        email: "ayman@primusagents.com.au",
        phone: "0403318777",
        image_url: "https://www.primusagents.com.au/primus_agents/employees/property_manager/ayman.png",
    },
    {
        id: 2,
        name: "demo",
        designation: "Property Manager",
        email: "demo@primusagents.com.au",
        phone: "01565",
        image_url: "",
    },
];