import {
    DOCUMENT_GET_REQUEST,
    DOCUMENT_GET_SUCCESS,
    DOCUMENT_GET_FAILURE,
  } from "./actionTypes";
  
  export const getDocumentRequest = () => {
    return {
      type: DOCUMENT_GET_REQUEST,
      meta: "Document data request",
    };
  };
  
  export const getDocumentSuccess = (data) => {
    return {
      type: DOCUMENT_GET_SUCCESS,
      payload: data,
      meta: "Document data success",
    };
  };
  
  export const getDocumentFailure = (error) => {
    return {
      type: DOCUMENT_GET_FAILURE,
      payload: error,
      meta: "Document data failure",
    };
  };
  