export const DEVELOP_API_URL = "http://127.0.0.1:8000";

export const PUBLIC_PDF_FILE_URL = "https://www.primusagents.com.au";

export const LARAVEL_API_URL = "https://api.primusagents.com.au";

// export const FAKE_API_URL = "https://fakestoreapi.com/products";

export const CONTACT_POST_API_URL = `${LARAVEL_API_URL}/api/send-contact-mail`;

export const BUY_GET_API_URL = `${LARAVEL_API_URL}/api/buy/properties`;

export const RENT_GET_API_URL = `${LARAVEL_API_URL}/api/rent/properties`;

export const COMMERCIAL_GET_API_URL = `${LARAVEL_API_URL}/api/commercial/properties`;

export const EXPERT_GET_API_URL = `${LARAVEL_API_URL}/api/user/agents`;

export const DOCUMENT_GET_API_URL = `${LARAVEL_API_URL}/api/property_documents`;

export const MAKE_APPRAISAL_POST_API_URL = `${LARAVEL_API_URL}/api/send-property-appraisal`;

export const PROPERTY_ENQUIRY_POST_API_URL = `${LARAVEL_API_URL}/api/send-property-enquiry`;

export const AGENT_MAIL_POST_API_URL = `${LARAVEL_API_URL}/api/send-agent-mail`;