import { all, fork } from "redux-saga/effects";
import { ContactWatcherSaga } from "../pages/ContactPage/ReduxStore/sagas";
import { BuyWatcherSaga } from "../pages/BuyPage/ReduxStore/sagas";
import { ExpertWatcherSaga } from "../pages/ExpertsPage/ReduxStore/sagas";
import { RentWatcherSaga } from "../pages/RentPage/ReduxStore/sagas";
import { CommercialWatcherSaga } from "../pages/CommercialPage/ReduxStore/sagas";
import { MailWatcherSaga } from "../redux/redux-mail-store/sagas";
import { DocumentWatcherSaga } from "../redux/document-store/sagas";

export function* rootWatcherSaga() {
    yield all([fork(ContactWatcherSaga)]);
    yield all([fork(BuyWatcherSaga)]);
    yield all([fork(ExpertWatcherSaga)]);
    yield all([fork(RentWatcherSaga)]);
    yield all([fork(CommercialWatcherSaga)]);
    yield all([fork(MailWatcherSaga)]);
    yield all([fork(DocumentWatcherSaga)]);
  }