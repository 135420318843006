import React, { useState, useEffect } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";

const SelectButton = ({ options, onChange, label, width, getValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(getValue !== undefined ? getValue : null);

  useEffect(() => {
    setValue(getValue);
  }, [getValue]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    //select data handle
    onChange(option);
    //hooks
    setIsOpen(false);
    setValue(option.label);
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  return (
    <div className={`relative ${width}`}>
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <button
          className="text-ash-500 truncate flex items-center justify-between w-full px-3 py-1.5 bg-white rounded shadow-custom-500 border border-white-500"
          onClick={toggleDropdown}
        >
          <p>{value ? value : label}</p>
          {/* <p
            className={`font-lato text-ash-500 absolute transition-transform duration-200 ease-out px-2 ${
              isOpen
                ? "-translate-y-[19px] bg-white text-xs opacity-100"
                : `opacity-0 ${value !== null ? "hidden" : "visible"}`
            }`}
          >
            {label}
          </p> */}
          {value && (
            <p className="font-lato text-ash-500 absolute top-[-8px] text-xs px-2 bg-white">
              {label}
            </p>
          )}
          <div>
            {isOpen ? (
              <BiChevronUp className="w-6 h-6" />
            ) : (
              <BiChevronDown className="w-6 h-6" />
            )}
          </div>
        </button>
        {isOpen && (
          <div className="absolute z-10 mt-2 w-full py-1 bg-white rounded max-h-[250px] overflow-y-auto shadow-custom-500 border border-blue-gray-500">
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => handleSelect(option)}
                className="px-4 py-2 cursor-pointer hover:bg-gray"
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

// Set default prop values
SelectButton.defaultProps = {
  getValue: null,
};

export default SelectButton;
