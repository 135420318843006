import React from "react";
import { Image } from "../../components/Image";
import { Button } from "../../components/Button";
import NavBarSubMenu from "./NavBarSubMenu";
import { NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import NavigationMobile from "./NavigationMobile";
import { useNavBarOpen } from "./ConextAPI/NavBarContext";
import {
  buy_sub_menu,
  commercial_sub_menu,
  experts_sub_menu,
  rent_sub_menu,
} from "./NavBarSubMenuData";

const NavigationBar = () => {
  // const [open, setOpen] = useState(false);
  const { open, setOpen } = useNavBarOpen();

  return (
    <>
      <div className="fixed z-40 top-0 w-full bg-white flex items-center justify-between px-[5vw] py-6 shadow-xl text-base lg:px-3 xl:px-12 lg:py-7">
        <div>
          <NavLink to="/">
            <Image
              className="w-[170px] xl:w-[220px]"
              src="../../assets/logos/primus-logo.png"
            />
          </NavLink>
        </div>

        {/* For mobile or small screen ham-burger*/}
        <button className="block lg:hidden" onClick={() => setOpen(true)}>
          <RxHamburgerMenu className="w-10 h-7" />
        </button>
        {/* For mobile or small screen ham-burger*/}

        {/* For 1024px to large screen */}
        <div className="hidden lg:flex lg:cursor-pointer">
          <NavBarSubMenu name="SELL" route="/sell" border={true} />
          <NavBarSubMenu
            name="BUY"
            route="/buy"
            border={true}
            subMenu={true}
            subMenuData={buy_sub_menu}
          />
          <NavBarSubMenu
            name="RENT"
            route="/rent"
            border={true}
            subMenu={true}
            subMenuData={rent_sub_menu}
          />
          <NavBarSubMenu
            name="COMMERCIAL"
            route="/commercial"
            border={true}
            subMenu={true}
            subMenuData={commercial_sub_menu}
          />
          <NavBarSubMenu
            name="EXPERTS"
            // route="/experts"  #its for only experts for pc
            route="/experts/market-appraisal"
            border={true}
            subMenu={true}
            subMenuData={experts_sub_menu}
          />
          {/* <NavBarSubMenu name="STRATA" route="/strata" border={true} /> */}
          <NavBarSubMenu name="SERVICES" route="/services" border={true} />
          <NavBarSubMenu name="CONTACT" route="/contact-us" />
        </div>
        <div className="hidden lg:text-[10px] xl:text-xs lg:flex lg:gap-3">
          <a
            href="https://dashboard.primusagents.com.au/"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              className="px-3 xl:px-5 py-1.5 xl:py-2 bg-ash-200 text-white-500"
              name="AGENT LOGIN"
            />
          </a>
          {/* <NavLink to="client-login">
            <Button
              className="px-3 xl:px-5 py-1.5 xl:py-2 bg-ash-200 text-white-500"
              name="PROPERTY HUB"
            />
          </NavLink> */}
        </div>
        {/* For 1024px to large screen */}
      </div>
      {/* For mobile or small screen modal*/}
      <div
        className={`${
          open ? "translate-x-0" : "translate-x-full"
        } fixed z-50 top-0 left-0 bottom-0 right-0 transform transition-transform duration-500 ease-in-out`}
      >
        <NavigationMobile />
      </div>
      {/* For mobile or small screen modal*/}
    </>
  );
};

export default NavigationBar;
