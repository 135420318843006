import { call, put, takeEvery } from "redux-saga/effects";
import { getApi } from "./getApi";
import { getCommercialSuccess, getCommercialFailure } from "./actions";
import { COMMERCIAL_GET_REQUEST } from "./actionTypes";

//worker saga
function* getCommercialWorkerSaga(action) {
    try {
      const data = yield call(getApi);
      yield put(getCommercialSuccess(data));
      // console.log('data:',data)
    } catch (error) {
      yield put(getCommercialFailure(error));
      // console.log('error:',error)
    }
  }

  //watcher saga effects
//take every request send with reducer action request
export function* CommercialWatcherSaga() {
    yield takeEvery(COMMERCIAL_GET_REQUEST, getCommercialWorkerSaga);
  }