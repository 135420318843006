import React, { useEffect } from "react";
import { MdOutlineApartment } from "react-icons/md";
import LeasedCardComponent from "../component/LeasedCardComponent";
import { useSelector, useDispatch } from "react-redux";
import { getRentRequest } from "../ReduxStore/actions";
import { VscEye } from "react-icons/vsc";
import PuffLoader from "react-spinners/PuffLoader";
import NoPropertyFound from "../../../components/NoPropertyFound";
import Pagination from "../../../components/Pagination";

let itemsPerPage = 8;
let rentPropertyData = [];

const RentLeasedPage = () => {
  //redux hooks
  const dispatch = useDispatch();
  const { isLoading, rent_properties } = useSelector((state) => state.rentData);

  useEffect(() => {
    dispatch(getRentRequest());
  }, [dispatch]);

  // Check rent_properties
  if (Object.keys(rent_properties).length > 0) {
    rentPropertyData = rent_properties.data || [];
  }

  //filter leased property from rent_properties.data
  const leased_property = rentPropertyData.filter(
    (obj) => obj.status === true && obj.category === "rent"
  );

  return (
    <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] pt-28 lg:pt-32">
      <div className="flex gap-2 items-center font-playfair uppercase text-ash-500 bg-yellow-light py-3 px-3">
        <MdOutlineApartment />
        <p>Our Leased Property</p>
      </div>
      <p className="text-sm text-ash-500 pt-2 flex items-center">
        <VscEye className="w-6 h-6" />
        &nbsp;&nbsp;
        {leased_property.length === 0
          ? "0 Properties Found"
          : `${leased_property.length} Properties Found`}
      </p>
      <div className="py-5">
        <div className="py-5 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
          {isLoading ? (
            <div className="h-[30vh] w-full flex items-center justify-center">
              <PuffLoader color="#36d7b7" size={100} />
            </div>
          ) : leased_property.length === 0 ? (
            <NoPropertyFound />
          ) : (
            <Pagination
              items={leased_property}
              itemsPerPage={itemsPerPage}
              componentName={LeasedCardComponent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RentLeasedPage;
