import { ByDateCategory, getAuctionDateCategory } from "./getArrayOfObjectCategory";

export const searchProperty = (properties, searchCriteria) => {
  if (Object.entries(searchCriteria).length === 1 && searchCriteria.suburb === "") {
    return [];
  }
  const filteredProperties = properties.filter((property) => {
    return Object.entries(searchCriteria).every(([key, value]) => {
      // Handle special cases (e.g., case-insensitive string comparison)
      switch (key) {
        case "suburb":
          return property.suburb.toLowerCase().includes(value.toLowerCase());
        case "maxBed":
          return property.bed <= value;
        case "minBed":
          return property.bed >= value;
        case "maxBath":
          return property.bath <= value;
        case "minBath":
          return property.bath >= value;
        case "parking":
          return property.car >= value;
        case "subject":
          return property.property_type
            .toLowerCase()
            .includes(value.toLowerCase());
        default:
          return property[key] === value;
      }
    });
  });

  return filteredProperties;
};

export const searchCommercialProperty = (properties, searchCriteria) => {
  if (Object.entries(searchCriteria).length === 1 && searchCriteria.suburb === "") {
    return [];
  }

  const filteredProperties = properties.filter((property) => {
    return Object.entries(searchCriteria).every(([key, value]) => {
      // Handle special cases (e.g., case-insensitive string comparison)
      switch (key) {
        case "suburb":
          return property.suburb.toLowerCase().includes(value.toLowerCase());
        case "maxPrice":
          return property.price <= value;
        case "minPrice":
          return property.price >= value;
        case "maxLand":
          return property.land_area <= value;
        case "minLand":
          return property.land_area >= value;
        case "subject":
          return property.property_type
            .toLowerCase()
            .includes(value.toLowerCase());
        default:
          return property[key] === value;
      }
    });
  });

  return filteredProperties;
};

export const searchTimeWithinProperty = (properties, pickDate) => {
  return Object.entries(ByDateCategory(properties)).some(
    ([date]) => date === pickDate
  );
};

export const searchTimeWithinAuctionProperty = (properties, pickDate) => {
  return Object.entries(getAuctionDateCategory(properties)).some(
    ([date]) => date === pickDate
  );
};