import React from 'react'
import { ButtonWithIcon } from '../../../components/Button';
import { Image } from '../../../components/Image';
import { next_step_data } from '../../../data/sellData';
import { HiMiniArrowLongRight } from "react-icons/hi2";
import { NavLink } from 'react-router-dom';

const NextStepSection = () => {
  return (
    <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw] flex flex-col items-center sm:flex-row py-20 gap-5'>
        <Image src={next_step_data.image_url} className="w-full sm:hidden"/>
        <div className='flex flex-col gap-6 lg:gap-12'>
            <div className='flex flex-col gap-4'>
                <h1 className='text-2xl lg:text-4xl font-playfair'>{next_step_data.title}</h1>
                <p className='text-sm md:text-base'>{next_step_data.description}</p>
            </div>
            <NavLink to="/experts/market-appraisal" className="w-fit">
              <ButtonWithIcon name="Book Appraisal" Icon={<HiMiniArrowLongRight/>} className="px-5 py-1 text-base" />
            </NavLink>
        </div>
        <Image src={next_step_data.image_url} className="hidden sm:block sm:w-[45%] sm:rounded-l-full"/>
    </div>
  )
}

export default NextStepSection;