import {BUY_GET_FAILURE, BUY_GET_REQUEST, BUY_GET_SUCCESS} from "./actionTypes";

const initialState = {
    isLoading: false,
    buy_properties: [],
    error: {},
  };

  export const buyReducer = (state = initialState, action) => {
    switch (action.type) {
      case BUY_GET_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case BUY_GET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          buy_properties: action.payload,
        };
      case BUY_GET_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };