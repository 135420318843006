import {
    COMMERCIAL_GET_REQUEST, COMMERCIAL_GET_SUCCESS, COMMERCIAL_GET_FAILURE
  } from "./actionTypes";
  
  export const getCommercialRequest = () => {
    return {
      type: COMMERCIAL_GET_REQUEST,
      meta: "Commercial data request",
    };
  };
  
  export const getCommercialSuccess = (data) => {
    return {
      type: COMMERCIAL_GET_SUCCESS,
      payload: data,
      meta: "Commercial data success",
    };
  };
  
  export const getCommercialFailure = (error) => {
    return {
      type: COMMERCIAL_GET_FAILURE,
      payload: error,
      meta: "Commercial data failure",
    };
  };