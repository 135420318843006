import React, { useState } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const Pagination = ({
  items,
  itemsPerPage,
  componentName: PassedComponent,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the indices for the current page's items
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(items.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const buttonValues = Array.from({ length: totalPages })
    .map((_, index) =>
      index === 0 ||
      index === totalPages - 1 ||
      index + 1 === currentPage - 1 ||
      index + 1 === currentPage ||
      index + 1 === currentPage + 1
        ? index + 1
        : index === 1 && currentPage - index > 2
        ? "..."
        : index === totalPages - 2 && totalPages - currentPage > 2
        ? "..."
        : null
    )
    .filter((button) => button !== null);

  return (
    <div>
      {/* Display the current page's items */}
      <div className="pt-5 pb-10 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
        {currentItems.map((item, index) => (
          <PassedComponent key={index} data={item}/>
        ))}
      </div>

      {/* Pagination controls */}
      <div className="overflow-x-auto p-2 flex gap-2.5 items-center justify-center">
        <button
          className="outline outline-[1px] outline-ash-200 p-2 rounded-full text-ash-200"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <BsArrowLeft className="w-5 h-5" />
        </button>
        {/* Display page numbers start*/}
        <div className="flex flex-wrap gap-2.5 items-center justify-center">
          {buttonValues.map((button, index) => (
            <button
              className={`px-4 py-2 rounded-full ${
                currentPage === button
                  ? "bg-yellow text-yellow-200"
                  : "text-ash bg-white-500"
              }`}
              key={index}
              onClick={() =>
                button !== "..." ? handlePageChange(button) : null
              }
              disabled={currentPage === button || button === "..."}
            >
              {button}
            </button>
          ))}
        </div>
        {/* Display page numbers end*/}
        <button
          className="outline outline-[1px] outline-ash-200 p-2 rounded-full text-ash-200"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <BsArrowRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
