import React from 'react';
import { BsChevronDoubleRight} from "react-icons/bs";

const CustomRightArrow = ({ onClick, ...rest }) => {
    return (
        <button
            onClick={() => onClick()}
            className="custom-arrow custom-right-arrow"
            {...rest}
        >
            <BsChevronDoubleRight/>
        </button>
    );
};

export default CustomRightArrow;