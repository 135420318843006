  import {EXPERT_GET_FAILURE, EXPERT_GET_REQUEST, EXPERT_GET_SUCCESS} from "./actionTypes";

  export const getExpertRequest = () => {
    return {
      type: EXPERT_GET_REQUEST,
      meta: "Expert data request",
    };
  };
  
  export const getExpertSuccess = (data) => {
    return {
      type: EXPERT_GET_SUCCESS,
      payload: data,
      meta: "Expert data success",
    };
  };
  
  export const getExpertFailure = (error) => {
    return {
      type: EXPERT_GET_FAILURE,
      payload: error,
      meta: "Expert data failure",
    };
  };
