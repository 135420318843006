export const home_section_data = {
    title: 'The Simplest Way to Find Property',
    description: 'Find your dream home with us and make your dream home.',
    image_url: require('../assets/home-images/home.jpg'),
}

export const explore_section_data = {
    buy_image_url: require('../assets/home-images/buy.jpg'),
    sell_image_url: require('../assets/home-images/sell.jpg'),
    rent_image_url: require('../assets/home-images/rent-scaled.jpg'),
    experts_image_url: require('../assets/home-images/experts.jpg'),
    strata_image_url: require('../assets/home-images/strata.jpg'),
    services_image_url: require('../assets/home-images/services.jpg'),
}

export const advertise_section_data = {
    title: 'What matters to you inspires what we do.',
    image_url: require('../assets/home-images/advertise.jpg'),
}

export const our_story_section_data = [
    {
        id: 1,
        total: "20+",
        title: 'REAL ESTATE PROFESSIONALS'
    },
    {
        id: 2,
        total: '35+',
        title: 'PROPERTIES FOR SALE/RENT'
    },
   {
        id:3,
        total: '$1 M',
        title: 'WORTH OF PROPERTIES SOLD THIS YEAR'
    },
    {
        id: 4,
        total: '10+',
        title: 'PROPERTIES SOLD TO DATE'
    }
]