import React, { useRef } from "react";
import { contactFormValidationSchema } from "../../../utils/formikValidateForm";
import { Formik, Form, ErrorMessage } from "formik";
import InputField from "../../../components/InputField";
import TextArea from "../../../components/TextArea";
import { ButtonSubmit } from "../../../components/Button";
import { form_section_data } from "../../../data/contactData";
import { useDispatch, useSelector } from "react-redux";
import { postContactRequest } from "../ReduxStore/actions";
import ReCAPTCHA from "react-google-recaptcha";
import { clientSiteKey } from "../../../api/reCaptchaKey";

const FormSection = () => {
  const recaptchaRef = useRef(null);

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.contactData);

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(postContactRequest(values));
    setSubmitting(false);
    resetForm();
    if (recaptchaRef.current) {
      recaptchaRef.current.reset(); 
    }
  };

  return (
    <div className="md:flex md:flex-col md:items-center w-full">
      <div className="px-[5vw] sm:px-[10vw] md:px-[10vw] py-[10vh] xl:px-[5vw] md:w-[800px] space-y-4">
        <div className="text-center space-y-2">
          <h1 className="uppercase text-xl lg:text-2xl font-playfair">
            {form_section_data.title}
          </h1>
          <p className="text-yellow-200">{form_section_data.description}</p>
        </div>
        <Formik
          initialValues={{ name: "", email: "", subject: "", message: "", recaptcha: "" }}
          validationSchema={contactFormValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="flex flex-col gap-3 py-5 w-full justify-start items-start rounded">
              <div className="flex flex-col mb:flex-row gap-3 w-full">
                <InputField type="text" name="name" placeholder="Name*" />
                <InputField type="text" name="email" placeholder="Email*" />
              </div>
              <InputField type="text" name="subject" placeholder="Subject*" />
              <TextArea name="message" placeholder="Message*" />
              <div>
                <ReCAPTCHA
                  sitekey={clientSiteKey}
                  onChange={(value) => setFieldValue("recaptcha", value)}
                  size="normal"
                  ref={recaptchaRef}
                />
                <ErrorMessage
                  name="recaptcha"
                  component="div"
                  className="text-red-900 text-[12px]"
                />
              </div>
              <ButtonSubmit
                type="submit"
                name="SUBMIT"
                className="px-6 py-2 w-full mt-6 uppercase bg-yellow-light text-ash-500"
                disabled={isSubmitting}
                loader={isLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FormSection;
