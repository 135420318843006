import { CONTACT_POST_FAILURE, CONTACT_POST_REQUEST, CONTACT_POST_SUCCESS } from "./actionTypes";

const initialState = {
    isLoading: false,
    contacts: {},
    error: {},
  };

  export const contactReducer = (state = initialState, action) => {
    switch (action.type) {
      case CONTACT_POST_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case CONTACT_POST_SUCCESS:
        return {
          ...state,
          isLoading: false,
          contacts: action.payload,
        };
      case CONTACT_POST_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };