import axios from "axios";
import { CONTACT_POST_API_URL } from "../../../api/LaravelApiUrl";

export const postApi = async (postData) => {

    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;

    const response = await axios.post(CONTACT_POST_API_URL, JSON.stringify(postData), {
        headers:{
            'X-CSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
        }
      });
  const data = response.data;
  return data;
};