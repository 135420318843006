import { call, put, takeEvery } from "redux-saga/effects";
import {
  RENT_GET_REQUEST
} from "./actionTypes";
import {
  getRentSuccess,
  getRentFailure
} from "./actions";
import { getApi } from "./getApi";

//worker saga
function* getRentWorkerSaga(action) {
  try {
    const data = yield call(getApi);
    yield put(getRentSuccess(data));
    // console.log('data:',data)
  } catch (error) {
    yield put(getRentFailure(error));
    // console.log('error:',error)
  }
}


//watcher saga effects
//take every request send with reducer action request
export function* RentWatcherSaga() {
  yield takeEvery(RENT_GET_REQUEST, getRentWorkerSaga);
}
