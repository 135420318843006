import React from 'react'
import { card_container_data } from '../../../data/sellData';
import { Image } from '../../../components/Image';

const CardContainerSection = () => {
  return (
    <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw] pb-20 sm:pb-48 flex flex-col  gap-8 sm:flex-wrap sm:flex-row'>
        {card_container_data.map((item)=>(
            <div key={item.id} className='flex flex-col items-center justify-center gap-4 p-8 shadow-custom flex-1 sm:min-w-[300px]'>
                <Image src={item.image_url} className="w-12"/>
                <h1 className='font-bold text-lg'>{item.title}</h1>
                <p className='text-justify'>{item.description}</p>
            </div>
        ))}
    </div>
  )
}

export default CardContainerSection;