import React, { useState, useEffect } from "react";
import { ButtonIcon } from "../../../components/Button";
import { BsSearch } from "react-icons/bs";
import { VscListFilter } from "react-icons/vsc";
import {
  max_land_options,
  max_price_options,
  min_land_options,
  min_price_options,
  subject_type_options,
} from "../../../components/SelectButtonData";
import SelectButton from "../../../components/SelectButton";
import { VscEye } from "react-icons/vsc";
import { useLocation } from "react-router-dom";

const CommercialSearchComponent = ({ totalProduct, getInput }) => {
  const queryLocation = useLocation();
  const queryParams = new URLSearchParams(queryLocation.search);
  const receivedData = queryParams.get("search");

  const [selectedData, setSelectedData] = useState({ suburb: "" });

  //used it for search param
  useEffect(() => {
    if (receivedData !== null) {
      setSelectedData({ suburb: receivedData });
    }
  }, [receivedData]);

  const handleSelectedButton = (values) => {
    switch (values.type) {
      case "subject":
        setSelectedData({ ...selectedData, subject: values.label });
        break;
      case "minPrice":
        setSelectedData({ ...selectedData, minPrice: values.value });
        break;
      case "maxPrice":
        setSelectedData({ ...selectedData, maxPrice: values.value });
        break;
      case "minLand":
        setSelectedData({ ...selectedData, minLand: values.value });
        break;
      case "maxLand":
        setSelectedData({ ...selectedData, maxLand: values.value });
        break;
      default:
        // Handle default case if needed
        break;
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    getInput(selectedData);
  };

  return (
    <div className="pt-28 lg:pt-32 pb-10 px-[5vw] sm:px-[10vw] xl:px-[15vw] bg-yellow-light flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <h1 className="text-ash-500">PROPERTIES FOR COMMERCIAL</h1>
        <p className="text-sm text-ash-500 flex items-center">
          <VscEye className="w-6 h-6" />
          &nbsp;&nbsp;{" "}
          {totalProduct === undefined
            ? "0 Properties Found"
            : `${totalProduct} Properties Found`}
        </p>
      </div>
      <form className="flex gap-2" onSubmit={handleFormSubmit}>
        <input
          type="text"
          className="w-full px-4 py-2.5 md:px-6 md:py-4 rounded-xl md:rounded-full shadow-custom-500 focus:outline-gray border border-white-500"
          placeholder="Search by Suburb..."
          value={selectedData.suburb}
          onChange={(event) =>
            setSelectedData({
              ...selectedData,
              suburb: event.target.value,
            })
          }
        />
        <ButtonIcon
          className="bg-white px-4 py-2.5 md:px-6 md:py-4 rounded-xl md:rounded-full mb:px-10 shadow-custom-500 cursor-pointer border border-white-500"
          Icon={<BsSearch className="w-8 h-6" />}
          type="submit"
        />
      </form>
      <div className="flex flex-col gap-5 sm:flex-row sm:gap-2 sm:flex-wrap">
        <div className="flex gap-2 items-center">
          <h1 className="text-ash-500">FILTER</h1>
          <VscListFilter className="text-ash-500" />
        </div>
        <div>
          <SelectButton
            label="Subject Type"
            options={subject_type_options}
            width="sm:w-[220px]"
            onChange={handleSelectedButton}
          />
        </div>
        <div className="flex gap-2">
          <SelectButton
            label="Min Price"
            options={min_price_options}
            width="w-1/2 sm:w-[130px]"
            onChange={handleSelectedButton}
          />
          <SelectButton
            label="Max Price"
            options={max_price_options}
            width="w-1/2 sm:w-[130px]"
            onChange={handleSelectedButton}
          />
        </div>
        <div className="flex gap-2">
          <SelectButton
            label="Min Land"
            options={min_land_options}
            width="w-1/2 sm:w-[130px]"
            onChange={handleSelectedButton}
          />
          <SelectButton
            label="Max Land"
            options={max_land_options}
            width="w-1/2 sm:w-[130px]"
            onChange={handleSelectedButton}
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <input type="checkbox" className="w-4 h-4 text-ash-500" />
        <label className="text-ash-500">Surrounding Suburbs</label>
      </div>
    </div>
  );
};

export default CommercialSearchComponent;
