import { call, put, takeEvery } from "redux-saga/effects";
import { BUY_GET_REQUEST } from "./actionTypes";
import { getBuySuccess, getBuyFailure } from "./actions";
import { getApi } from "./getApi";

//worker saga
function* getBuyWorkerSaga(action) {
  try {
    const data = yield call(getApi);
    yield put(getBuySuccess(data));
    // console.log('data:',data)
  } catch (error) {
    yield put(getBuyFailure(error));
    // console.log('error:',error)
  } 
}

//watcher saga effects
//take every request send with reducer action request
export function* BuyWatcherSaga() {
  yield takeEvery(BUY_GET_REQUEST, getBuyWorkerSaga);
}