import React, { useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import InputField from "../../../components/InputField";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextArea from "../../../components/TextArea";
import { ButtonSubmit } from "../../../components/Button";
import { makeAppointmentFormValidationSchema } from "../../../utils/formikValidateForm";
import ReCAPTCHA from "react-google-recaptcha";
import { clientSiteKey } from "../../../api/reCaptchaKey";
import { useDispatch, useSelector } from "react-redux";
import { postPropertyEnquiryRequest } from "../../../redux/redux-mail-store/actions";

const MakeAnAppointmentModal = ({ handleCloseModal, userId, propertyId  }) => {
  const recaptchaRef = useRef(null);

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.propertyEnquiryData);

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(postPropertyEnquiryRequest(values));
    setSubmitting(false);
    resetForm();
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  return (
    <div className="flex flex-col max-w-[550px] p-6 rounded-lg mt-[450px] ">
      <div className="flex items-center justify-between text-xl bg-yellow p-6">
        <p>Property Enquiry</p>
        <AiOutlineClose
          className="cursor-pointer"
          onClick={() => handleCloseModal(false)}
        />
      </div>

      <div className="bg-white p-6">
        <p>
          Please complete the form below and one of our friendly team will be in
          touch soon.
        </p>
        <div>
          <Formik
            initialValues={{
              user_id: userId,
              property_id: propertyId,
              category:"rent",
              fname: "",
              lname: "",
              email: "",
              phone: "",
              message: "",
              checked: [],
              recaptcha: "",
            }}
            validationSchema={makeAppointmentFormValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="flex flex-col gap-3 py-5 w-full justify-start items-start rounded">
                <div className="w-full flex flex-col gap-3">
                  <h1 className="text-lg">My Contact Details</h1>

                  <InputField
                    type="text"
                    name="fname"
                    placeholder="First Name*"
                  />
                  <InputField
                    type="text"
                    name="lname"
                    placeholder="Last Name*"
                  />
                  <InputField type="text" name="email" placeholder="Email*" />
                  <InputField type="text" name="phone" placeholder="Phone*" />
                </div>
                <div
                  className="flex flex-col gap-2 mb-3"
                  role="group"
                  aria-labelledby="checkbox-group"
                >
                  <h1 className="text-lg">My Enquiries</h1>
                  <label>
                    <Field
                      type="checkbox"
                      name="checked"
                      value="Please advise lease period?"
                      className="mr-2"
                    />
                    Please advise lease period?
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="checked"
                      value="When is the inspection time?"
                      className="mr-2"
                    />
                    When is the inspection time?
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="checked"
                      value="Are pets allowed?"
                      className="mr-2"
                    />
                    Are pets allowed?
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="checked"
                      value="Is parking available?"
                      className="mr-2"
                    />
                    Is parking available?
                  </label>
                </div>
                <TextArea name="message" placeholder="Send more details*" />
                <div>
                  <ReCAPTCHA
                    sitekey={clientSiteKey}
                    onChange={(value) => setFieldValue("recaptcha", value)}
                    size="normal"
                    ref={recaptchaRef}
                  />
                  <ErrorMessage
                    name="recaptcha"
                    component="div"
                    className="text-red text-[12px]"
                  />
                </div>
                <ButtonSubmit
                  type="submit"
                  name="SUBMIT"
                  className="px-6 py-2 w-full mt-6 uppercase bg-yellow-light text-ash-200"
                  disabled={isSubmitting}
                  loader={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MakeAnAppointmentModal;
