import {
  AGENT_MAIL_POST_FAILURE,
  AGENT_MAIL_POST_REQUEST,
  AGENT_MAIL_POST_SUCCESS,
  MAKE_APPRAISAL_POST_FAILURE,
  MAKE_APPRAISAL_POST_REQUEST,
  MAKE_APPRAISAL_POST_SUCCESS,
  PROPERTY_ENQUIRY_POST_FAILURE,
  PROPERTY_ENQUIRY_POST_REQUEST,
  PROPERTY_ENQUIRY_POST_SUCCESS,
} from "./actionTypes";

const initialAppraisalState = {
  isLoading: false,
  make_appraisal: {},
  error: {},
};

const initialPropertyEnquiryState = {
  isLoading: false,
  property_enquiry: {},
  error: {},
};

const initialAgentMailState = {
  isLoading: false,
  agent_mail: {},
  error: {},
};

export const makeAppraisalReducer = (state = initialAppraisalState, action) => {
  switch (action.type) {
    case MAKE_APPRAISAL_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MAKE_APPRAISAL_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        make_appraisal: action.payload,
      };
    case MAKE_APPRAISAL_POST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const propertyEnquiryReducer = (state = initialPropertyEnquiryState, action) => {
  switch (action.type) {
    case PROPERTY_ENQUIRY_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PROPERTY_ENQUIRY_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        property_enquiry: action.payload,
      };
    case PROPERTY_ENQUIRY_POST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const agentMailReducer = (state = initialAgentMailState, action) => {
  switch (action.type) {
    case AGENT_MAIL_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AGENT_MAIL_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        agent_mail: action.payload,
      };
    case AGENT_MAIL_POST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};