export const landing_data = {
  title:"Sell confidently with a",
  heading:"local expert.",
  description:"Get an instant property appraisal and find out how much your property is worth in today’s market. Click the button below to get started.",
  image_url:require("../assets/sell-images/landing-02.avif"),
}

export const sell_with_us_data = {
    title: "Why Sell With Us?",
    description:"We make selling your property simple, transparent and affordable. Our local experts work hard to get you a great result with award-winning services.",
    image_url: require("../assets/sell-images/Why-sell-with-us.jpg"),
}

export const card_container_data = [
  {
    id:1,
    title:"Dedicated Local Agents",
    description: "Our local experts are independent agents working together as a national network, backed up by a full support team to make the process easy and simple.",
    image_url: require('../assets/sell-images/agent.png'),
  },
  {
    id: 2,
    title: "Affordable Marketing",
    description:"We highlight your property’s best features with custom marketing campaigns that are affordable and designed to maximize exposure to increase buyer demand.",
    image_url:require('../assets/sell-images/money-3.png'),
  },
  {
    id: 3,
    title: "Fully Informed 24/7",
    description:"Transparency and communication are key with our clients. Our unique property hub allows you to access real-time updates on your property’s progress. Stay up to date 24/7",
    image_url: require('../assets/sell-images/support.png'),
  }
]

export const sets_us_apart_data = {
  title: "What Sets Us Apart?",
  description: "Our local agents make all the difference, see below a comparison chart of what we offer compared to what traditional agencies offer.",
  image_url: require("../assets/sell-images/what-asset-we-sell.jpg"),
}

export const sell_table_data = [
  {
    id:1,
    question: 'Avg Agent Experience of 10 years +',
    primus_agent: true,
    other_agencies: "Not all",
  },
  {
    id:2,
    question: '24/7 Access to listing performance / stats',
    primus_agent: true,
    other_agencies: "X",
  },
  {
    id:3,
    question: 'Customized marketing packages based on your property',
    primus_agent: true,
    other_agencies: "Not all",
  },
  {
    id:4,
    question: 'Digital / Social Media marketing packages',
    primus_agent: true,
    other_agencies: "Not all",
  },
  {
    id:5,
    question: 'National Network of 750+ agents working together',
    primus_agent: true,
    other_agencies: "X",
  },
  {
    id:6,
    question: 'Professional head office team providing agent support',
    primus_agent: true,
    other_agencies: "Not all",
  },
  {
    id:7,
    question: 'Secure trust accounting system to prevent fraud',
    primus_agent: true,
    other_agencies: "X",
  },
  {
    id:8,
    question: 'In-house mortgage broking to assist buyers with quick settlement',
    primus_agent: true,
    other_agencies: "X",
  },
  {
    id:9,
    question: 'In-house conveyancing for quick and simple settlement',
    primus_agent: true,
    other_agencies: "X",
  },
  {
    id:10,
    question: 'Free ads on 100 + international websites',
    primus_agent: true,
    other_agencies: "X",
  },
]

export const sell_advertise_data = {
  service:{
    title: "24/7 Access To Your Property's Statistics",
    description:"We’ve built the first modern real estate platform, pairing top talent with technology to make the selling experience intelligent and seamless.",
    details:[
      {
        id:1,
        item:"View property results instantly"
      },
      {
        id:2,
        item:"View current or past offers"
      },
      {
        id:3,
        item:"View listings live on all active platforms"
      },
      {
        id:4,
        item:"Save your search results and investment portfolio"
      },
    ],
    image_url:require('../assets/sell-images/primus-phone.png'),
  },
  media:{
    title:'Targeted Social Media Campaigns',
    description:'With our in-house digital media experts, you can significantly increase your property presence through social media. Target buyers who have;​',
    details:[
      {
        id:1,
        item:'Viewed a property in the same area in the last 60 days',
      },
      {
        id:2,
        item:'Visited the suburb and surrounding area recently',
      },
      {
        id:3,
        item:'Part of our “lookalike audience”',
      },
    ],
    image_url: require('../assets/sell-images/primus-phone-2.png'),
  }
}


export const parallax_image_url = require('../assets/sell-images/landing.jpg');

export const tell_the_story_data = {
  title:'Our Numbers Tell The Story.',
  expert:{
    total: "20+",
    details: 'Local Real Estate Experts Nationally',
    image_url: require('../assets/sell-images/agent.png'),
  },
  worth:{
    total: "1 M",
    details: 'Worth of property sold this year',
    image_url: require('../assets/sell-images/dollar.png'),
  },
  star:{
    total: "15+",
    details: '5 Star reviews on Google and Rate My Agent',
    image_url: require('../assets/sell-images/file.png'),
  }
}

export const next_step_data = {
  title: "What's The Next Step??",
  description:"Your local agent will visit your property to give you a professional, no-obligation appraisal. Virtual appraisals are now available as well",
  image_url: require("../assets/sell-images/appraisal.jpg"),
}

export const book_appraisal_data = {
  title:'GET YOUR FREE PROPERTY MARKET APPRAISAL!',
  description: 'Your appraisal will contain a detailed breakdown of your property’s size, location, how it compares to similar local properties, potential areas of improvement, & much more.',
  details: 'Get a better understanding of where your property stands in the current market today. Simply fill in your details below & your local @Primus Agent expert will be in contact shortly.',
  image_url: require('../assets/sell-images/book-appraisal.jpg'),
}

export const book_appraisal_card_data =[
  {
    id:1,
    title:'Fill in your property\'s details',
    description:'Fill in the short form above with your details and property address.',
    image_url: require("../assets/sell-images/book-appraisal-note.jpg"),
  },
  {
    id:2,
    title:'Your local Primus Agent will get in touch with you',
    description:'Your local @Primus Agent real estate agent will be in touch with you to organize a meeting with you.',
    image_url: require("../assets/sell-images/book-appraisal-handshake.jpg"),
  },
  {
    id:3,
    title:'Receive a detailed report of your property\'s value',
    description:'When you meet with your local @Primus Agent, you will be presented with a detailed report of your property.',
    image_url: require("../assets/sell-images/book-appraisal-home.jpg"),
  }
]