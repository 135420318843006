import React from 'react'
import { Image } from '../../../components/Image';
import { sell_advertise_data } from '../../../data/sellData';
import { TiTick } from "react-icons/ti";

const SellAdvertiseSection = () => {
  return (
    <div className=' px-[5vw] sm:px-0 flex flex-col gap-24 sm:pl-0 pb-20 sm:w-full'>
        <div className='sm:flex sm:justify-center sm:items-center sm:gap-10 sm:pr-[10vw] xl:pr-[15vw]'>
            <div className='flex justify-center sm:justify-end sm:w-[50vw] sm:h-[360px] sm:bg-gray sm:rounded-r-full sm:relative sm:z-0'>
                <Image src={sell_advertise_data.service.image_url} className="w-[420px] sm:w-[480px] sm:h-[460px] sm:absolute sm:z-20 sm:top-[-15%]"/>
            </div>
            <div className='flex flex-col gap-5 sm:w-[50vw]'>
                <h1 className='text-2xl lg:text-4xl font-playfair'>{sell_advertise_data.service.title}</h1>
                <p className='text-base'>{sell_advertise_data.service.description}</p>
                <div className='flex flex-col mb:pl-5'>
                    {sell_advertise_data.service.details.map((item)=>(
                        <div key={item.id} className='flex gap-2 text-sm'>
                            <TiTick className='h-8 w-10' color='#F7C332'/>
                            <p>{item.item}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className='sm:flex sm:justify-center sm:items-center sm:gap-10 sm:pl-[10vw] xl:pl-[15vw]'>
            <div className='flex justify-center sm:hidden'>
                <Image src={sell_advertise_data.media.image_url} className="w-[420px] sm:w-[480px] sm:h-[460px] sm:absolute sm:z-20 sm:top-[-15%]"/>
            </div>
            <div className='flex flex-col gap-5 sm:w-[50vw]'>
                <h1 className='text-2xl lg:text-4xl font-playfair'>{sell_advertise_data.media.title}</h1>
                <p className='text-base'>{sell_advertise_data.media.description}</p>
                <div className='flex flex-col mb:pl-5'>
                    {sell_advertise_data.service.details.map((item)=>(
                        <div key={item.id} className='flex gap-2 text-sm'>
                            <TiTick className='h-8 w-10' color='#F7C332'/>
                            <p>{item.item}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='hidden sm:flex sm:justify-start sm:w-[50vw] sm:h-[360px] sm:bg-gray sm:rounded-l-full sm:relative sm:z-0'>
                <Image src={sell_advertise_data.media.image_url} className="w-[480px] h-[460px] sm:absolute sm:z-20 sm:top-[-15%]"/>
            </div>
        </div>
    </div>
  )
}

export default SellAdvertiseSection;