import { call, put, takeEvery } from "redux-saga/effects";
import { CONTACT_POST_REQUEST } from "./actionTypes";
import { postContactFailure, postContactSuccess } from "./actions";
import { postApi } from "./postApi";
import { toast } from "react-toastify";

//worker saga
function* postContactWorkerSaga(action) {
  try {
    const data = yield call(postApi, action.payload);
    yield put(postContactSuccess(data));
    // console.log('data:',data)
    //toastify
    if(data.success){
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }else{
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

  } catch (error) {
    yield put(postContactFailure(error));
    // console.log('error:',error)
    toast.error("Email sent failed!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } finally {
    toast.clearWaitingQueue();
  }
}

//watcher saga effects
//take every request send with reducer action request
export function* ContactWatcherSaga() {
  yield takeEvery(CONTACT_POST_REQUEST, postContactWorkerSaga);
}