import React, { useState, useEffect } from "react";
import { BsChevronDoubleRight, BsChevronDoubleLeft } from "react-icons/bs";
import { IoImagesOutline } from "react-icons/io5";
import { Image } from "./Image";

const ImageSlider = ({ images, interval, name, isLeased, isSold }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const changeSlide = (index) => {
    setActiveSlide(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (activeSlide < images.length - 1 && activeSlide > -1) {
        setActiveSlide((prevActiveSlide) => prevActiveSlide + 1);
      } else {
        setActiveSlide(0);
      }
    }, interval); // 1000 milliseconds (1 second)

    return () => {
      clearInterval(intervalId);
    };
  }, [activeSlide, images.length, interval]);

  return (
    <div className="relative z-0">
      <div className="w-full h-60 sm:h-[400px] md:h-[500px] lg:h-[650px] flex items-center relative z-30">
        <div
          className="bg-yellow-500 w-fit h-fit p-3 rounded-full absolute z-40 left-5 cursor-pointer"
          onClick={() =>
            setActiveSlide(
              activeSlide < images.length && activeSlide > 0
                ? activeSlide - 1
                : images.length - 1
            )
          }
        >
          <BsChevronDoubleLeft />
        </div>
        <img
          src={images[activeSlide].url}
          alt={`property-thumbnail ${activeSlide + 1}`}
          className={`w-full h-full transition-opacity duration-500 ease-in-out`}
        />
        <div
          className="bg-yellow-500 w-fit h-fit p-3 rounded-full absolute z-40 right-5 cursor-pointer"
          onClick={() =>
            setActiveSlide(
              activeSlide < images.length - 1 && activeSlide > -1
                ? activeSlide + 1
                : 0
            )
          }
        >
          <BsChevronDoubleRight />
        </div>
      </div>
      <div className="flex justify-center items-center gap-5 w-full h-fit mt-5">
        <p className="bg-yellow-light text-ash-500 px-2 py-1 h-fit w-fit font-bold uppercase">
          {name}
        </p>
        <div className="hidden mb:flex gap-3 items-center overflow-x-auto w-[370px]">
          <div className="flex gap-3">
            {images.map((image, index) => (
              <button
                key={index}
                onClick={() => changeSlide(index)}
                className={`w-16 h-12 cursor-pointer focus:outline-none transition-all duration-300 ease-in-out ${
                  activeSlide === index
                    ? "opacity-100"
                    : "opacity-40"
                }`}
              >
                <img
                  src={image.url}
                  alt={`property-thumbnail ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </button>
            ))}
          </div>
        </div>
        <div className="flex items-center gap-2 bg-yellow-light text-ash-500 px-2 py-1 h-fit font-bold">
          <IoImagesOutline className="w-6 h-6" />
          <p>{images.length}</p>
        </div>
      </div>
      {/* leased stamp image */}
      {isLeased && (<Image src="../assets/logos/leased-red-stamp.png" className="w-[80px] h-[70px] absolute top-2 left-2 z-50"/>)}
      {isSold && (<Image src="../assets/logos/sold-red-stamp.png" className="w-[90px] h-[70px] absolute top-2 left-2 z-50"/>)}
    </div>
  );
};

export default ImageSlider;
