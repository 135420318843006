import React from "react";
import { Button } from "../../../components/Button";
import { service_conveyancing } from "../../../data/servicesData";
import { Image } from "../../../components/Image";
import { NavLink } from "react-router-dom";

const ConveyancingSection = () => {
  return (
    <div className="flex flex-col w-full items-center px-[5vw] sm:px-[10vw] xl:px-[15vw]">
      <div className="py-16 space-y-8 xl:w-[50vw] text-center">
        <div className="space-y-2">
          <h1 className="text-xl lg:text-2xl font-playfair">{service_conveyancing.title}</h1>
          <p>{service_conveyancing.sub_title}</p>
        </div>
        <div className="flex flex-col sm:flex-row gap-5">
          {service_conveyancing.card.map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center gap-2 text-center flex-1 bg-gray rounded px-3 py-5 shadow-custom"
            >
              <Image src={item.image} className="w-16 h-16" />
              <h1 className="text-yellow-200">{item.title}</h1>
              <p className="text-sm text-yellow-200">{item.description}</p>
            </div>
          ))}
        </div>
        <div className="space-y-2 text-justify">
          <p>{service_conveyancing.para1}</p>
          <p>{service_conveyancing.para2}</p>
        </div>
        <div>
          <NavLink to="/contact-us">
            <Button name="CONNECT WITH US!" className="px-5 py-2 text-sm bg-yellow-light text-ash-500"/>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ConveyancingSection;
