export const subject_type_options = [
  { type: "subject", value: 1, label: "Acreage/Semi-Rural" },
  { type: "subject", value: 2, label: "Alpine" },
  { type: "subject", value: 3, label: "Apartment" },
  { type: "subject", value: 4, label: "Block of Units" },
  { type: "subject", value: 5, label: "Flat" },
  { type: "subject", value: 6, label: "Home Design" },
  { type: "subject", value: 7, label: "House" },
  { type: "subject", value: 8, label: "Land" },
  { type: "subject", value: 9, label: "Villa" },
  { type: "subject", value: 10, label: "Other" },
];

export const min_beds_options = [
  { type: "minBed", value: 1, label: "1 Bed" },
  { type: "minBed", value: 2, label: "2 Beds" },
  { type: "minBed", value: 3, label: "3 Beds" },
  { type: "minBed", value: 4, label: "4 Beds" },
  { type: "minBed", value: 5, label: "5 Beds" },
];

export const max_beds_options = [
  { type: "maxBed", value: 1, label: "1 Bed" },
  { type: "maxBed", value: 2, label: "2 Beds" },
  { type: "maxBed", value: 3, label: "3 Beds" },
  { type: "maxBed", value: 4, label: "4 Beds" },
  { type: "maxBed", value: 5, label: "5 Beds" },
];

export const min_baths_options = [
  { type: "minBath", value: 1, label: "1 Bath" },
  { type: "minBath", value: 2, label: "2 Baths" },
  { type: "minBath", value: 3, label: "3 Baths" },
  { type: "minBath", value: 4, label: "4 Baths" },
  { type: "minBath", value: 5, label: "5 Baths" },
];

export const max_baths_options = [
  { type: "maxBath", value: 1, label: "1 Bath" },
  { type: "maxBath", value: 2, label: "2 Baths" },
  { type: "maxBath", value: 3, label: "3 Baths" },
  { type: "maxBath", value: 4, label: "4 Baths" },
  { type: "maxBath", value: 5, label: "5 Baths" },
];

export const parking_options = [
  { type: "parking", value: 1, label: "1+" },
  { type: "parking", value: 2, label: "2+" },
  { type: "parking", value: 3, label: "3+" },
  { type: "parking", value: 4, label: "4+" },
  { type: "parking", value: 5, label: "5+" },
];

export const min_price_options = [
  { type: "minPrice", value: 50000, label: "$50,000" },
  { type: "minPrice", value: 100000, label: "$100,000" },
  { type: "minPrice", value: 150000, label: "$150,000" },
  { type: "minPrice", value: 200000, label: "$200,000" },
  { type: "minPrice", value: 250000, label: "$250,000" },
  { type: "minPrice", value: 300000, label: "$300,000" },
  { type: "minPrice", value: 350000, label: "$350,000" },
  { type: "minPrice", value: 400000, label: "$400,000" },
  { type: "minPrice", value: 450000, label: "$450,000" },
  { type: "minPrice", value: 500000, label: "$500,000" },
  { type: "minPrice", value: 550000, label: "$550,000" },
  { type: "minPrice", value: 600000, label: "$600,000" },
  { type: "minPrice", value: 650000, label: "$650,000" },
  { type: "minPrice", value: 700000, label: "$700,000" },
  { type: "minPrice", value: 750000, label: "$750,000" },
  { type: "minPrice", value: 800000, label: "$800,000" },
  { type: "minPrice", value: 850000, label: "$850,000" },
  { type: "minPrice", value: 900000, label: "$900,000" },
  { type: "minPrice", value: 950000, label: "$950,000" },
  { type: "minPrice", value: 1000000, label: "$1,000,000" },
  { type: "minPrice", value: 1100000, label: "$1,100,000" },
  { type: "minPrice", value: 1200000, label: "$1,200,000" },
  { type: "minPrice", value: 1300000, label: "$1,300,000" },
  { type: "minPrice", value: 1400000, label: "$1,400,000" },
  { type: "minPrice", value: 1500000, label: "$1,500,000" },
  { type: "minPrice", value: 1700000, label: "$1,700,000" },
  { type: "minPrice", value: 1800000, label: "$1,800,000" },
  { type: "minPrice", value: 1900000, label: "$1,900,000" },
  { type: "minPrice", value: 2000000, label: "$2,000,000" },
  { type: "minPrice", value: 2500000, label: "$2,500,000" },
  { type: "minPrice", value: 3000000, label: "$3,000,000" },
  { type: "minPrice", value: 3500000, label: "$3,500,000" },
  { type: "minPrice", value: 4000000, label: "$4,000,000" },
  { type: "minPrice", value: 5000000, label: "$5,000,000" },
  { type: "minPrice", value: 6000000, label: "$6,000,000" },
  { type: "minPrice", value: 7000000, label: "$7,000,000" },
  { type: "minPrice", value: 8000000, label: "$8,000,000" },
  { type: "minPrice", value: 9000000, label: "$9,000,000" },
];

export const max_price_options = [
  { type: "maxPrice", value: 50000, label: "$50,000" },
  { type: "maxPrice", value: 100000, label: "$100,000" },
  { type: "maxPrice", value: 150000, label: "$150,000" },
  { type: "maxPrice", value: 200000, label: "$200,000" },
  { type: "maxPrice", value: 250000, label: "$250,000" },
  { type: "maxPrice", value: 300000, label: "$300,000" },
  { type: "maxPrice", value: 350000, label: "$350,000" },
  { type: "maxPrice", value: 400000, label: "$400,000" },
  { type: "maxPrice", value: 450000, label: "$450,000" },
  { type: "maxPrice", value: 500000, label: "$500,000" },
  { type: "maxPrice", value: 550000, label: "$550,000" },
  { type: "maxPrice", value: 600000, label: "$600,000" },
  { type: "maxPrice", value: 650000, label: "$650,000" },
  { type: "maxPrice", value: 700000, label: "$700,000" },
  { type: "maxPrice", value: 750000, label: "$750,000" },
  { type: "maxPrice", value: 800000, label: "$800,000" },
  { type: "maxPrice", value: 850000, label: "$850,000" },
  { type: "maxPrice", value: 900000, label: "$900,000" },
  { type: "maxPrice", value: 950000, label: "$950,000" },
  { type: "maxPrice", value: 1000000, label: "$1,000,000" },
  { type: "maxPrice", value: 1100000, label: "$1,100,000" },
  { type: "maxPrice", value: 1200000, label: "$1,200,000" },
  { type: "maxPrice", value: 1300000, label: "$1,300,000" },
  { type: "maxPrice", value: 1400000, label: "$1,400,000" },
  { type: "maxPrice", value: 1500000, label: "$1,500,000" },
  { type: "maxPrice", value: 1700000, label: "$1,700,000" },
  { type: "maxPrice", value: 1800000, label: "$1,800,000" },
  { type: "maxPrice", value: 1900000, label: "$1,900,000" },
  { type: "maxPrice", value: 2000000, label: "$2,000,000" },
  { type: "maxPrice", value: 2500000, label: "$2,500,000" },
  { type: "maxPrice", value: 3000000, label: "$3,000,000" },
  { type: "maxPrice", value: 3500000, label: "$3,500,000" },
  { type: "maxPrice", value: 4000000, label: "$4,000,000" },
  { type: "maxPrice", value: 5000000, label: "$5,000,000" },
  { type: "maxPrice", value: 6000000, label: "$6,000,000" },
  { type: "maxPrice", value: 7000000, label: "$7,000,000" },
  { type: "maxPrice", value: 8000000, label: "$8,000,000" },
  { type: "maxPrice", value: 9000000, label: "$9,000,000" },
];

export const min_land_options = [
  { type: "minLand", value: 50, label: "50 m²" },
  { type: "minLand", value: 100, label: "100 m²" },
  { type: "minLand", value: 150, label: "150 m²" },
  { type: "minLand", value: 200, label: "200 m²" },
  { type: "minLand", value: 250, label: "250 m²" },
  { type: "minLand", value: 300, label: "300 m²" },
  { type: "minLand", value: 350, label: "350 m²" },
  { type: "minLand", value: 400, label: "400 m²" },
  { type: "minLand", value: 500, label: "500 m²" },
  { type: "minLand", value: 750, label: "750 m²" },
  { type: "minLand", value: 1000, label: "1000 m²" },
  { type: "minLand", value: 1500, label: "1500 m²" },
  { type: "minLand", value: 2000, label: "2000 m²" },
  { type: "minLand", value: 3000, label: "3000 m²" },
  { type: "minLand", value: 5000, label: "5000 m²" },
  { type: "minLand", value: 10000, label: "10000 m²" },
  { type: "minLand", value: 20000, label: "20000 m²" },
  { type: "minLand", value: 30000, label: "30000 m²" },
  { type: "minLand", value: 50000, label: "50000 m²" },
];

export const max_land_options = [
  { type: "maxLand", value: 50, label: "50 m²" },
  { type: "maxLand", value: 100, label: "100 m²" },
  { type: "maxLand", value: 150, label: "150 m²" },
  { type: "maxLand", value: 200, label: "200 m²" },
  { type: "maxLand", value: 250, label: "250 m²" },
  { type: "maxLand", value: 300, label: "300 m²" },
  { type: "maxLand", value: 350, label: "350 m²" },
  { type: "maxLand", value: 400, label: "400 m²" },
  { type: "maxLand", value: 500, label: "500 m²" },
  { type: "maxLand", value: 750, label: "750 m²" },
  { type: "maxLand", value: 1000, label: "1000 m²" },
  { type: "maxLand", value: 1500, label: "1500 m²" },
  { type: "maxLand", value: 2000, label: "2000 m²" },
  { type: "maxLand", value: 3000, label: "3000 m²" },
  { type: "maxLand", value: 5000, label: "5000 m²" },
  { type: "maxLand", value: 10000, label: "10000 m²" },
  { type: "maxLand", value: 20000, label: "20000 m²" },
  { type: "maxLand", value: 30000, label: "30000 m²" },
  { type: "maxLand", value: 50000, label: "50000 m²" },
];