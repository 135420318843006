import React, { useState, useEffect } from "react";
import CommercialSearchComponent from "./component/CommercialSearchComponent";
import CommercialCardComponent from "./component/CommercialCardComponent";
import Pagination from "../../components/Pagination";
import NoPropertyFound from "../../components/NoPropertyFound";
import { useDispatch, useSelector } from "react-redux";
import { getExpertRequest } from "../ExpertsPage/ReduxStore/actions";
import { getCommercialRequest } from "../CommercialPage/ReduxStore/actions";
import PuffLoader from "react-spinners/PuffLoader";
import { searchCommercialProperty } from "../../utils/searchProperty";
import { useLocation } from "react-router-dom";

const itemsPerPage = 8;

const CommercialPageLayout = () => {
  const queryLocation = useLocation();
  const queryParams = new URLSearchParams(queryLocation.search);
  const receivedData = queryParams.get("search");

  const [searchData, setSearchData] = useState([]);
  const [searchOutlet, setSearchOutlet] = useState(false);
  const [commercialProperties, setCommercialProperties] = useState([]);

  //use redux hooks
  const dispatch = useDispatch();
  const { isLoading, commercial_properties } = useSelector(
    (state) => state.commercialData
  );

  useEffect(() => {
    dispatch(getCommercialRequest());
    // call expert_profile
    dispatch(getExpertRequest());
  }, [dispatch]);

  useEffect(() => {
    // Update local state when experts_profile changes
    if (Object.keys(commercial_properties).length !== 0) {
      setCommercialProperties(commercial_properties.data || []);
    }
  }, [commercial_properties]);

  //filter available rent property from rent_properties.data
  const available_commercial_sale_properties = commercialProperties.filter(
    (obj) =>
      obj.status === false &&
      obj.category === "commercial" &&
      obj.commercial_category === 1
  );

    //it used when home page search active
    useEffect(() => {
      if (receivedData !== null) {
        const filterExpert = searchCommercialProperty(commercialProperties, {
          suburb: receivedData,
        });
        setSearchData(filterExpert);
        setSearchOutlet(true);
      }
    }, [receivedData, commercialProperties]);

  const handleSearchInput = (searchCriteria) => {
    const filteredProperties = searchCommercialProperty(available_commercial_sale_properties, searchCriteria);
    setSearchData(filteredProperties);
    setSearchOutlet(true);
  };

  return (
    <>
      <CommercialSearchComponent
        totalProduct={available_commercial_sale_properties.length}
        getInput={handleSearchInput}
      />
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-10">
        {isLoading ? (
          <div className="h-[30vh] w-full flex items-center justify-center">
            <PuffLoader color="#36d7b7" size={100} />
          </div>
        ) : (
          <>
            {searchOutlet ? (
              <>
                {searchData.length === 0 ? (
                  <NoPropertyFound />
                ) : (
                  <>
                    <Pagination
                      items={searchData}
                      itemsPerPage={itemsPerPage}
                      componentName={CommercialCardComponent}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {available_commercial_sale_properties.length === 0 ? (
                  <NoPropertyFound />
                ) : (
                  <Pagination
                    items={available_commercial_sale_properties}
                    itemsPerPage={itemsPerPage}
                    componentName={CommercialCardComponent}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CommercialPageLayout;
