import {
  COMMERCIAL_GET_REQUEST,
  COMMERCIAL_GET_SUCCESS,
  COMMERCIAL_GET_FAILURE,
} from "./actionTypes";

const initialCommercialState = {
  isLoading: false,
  commercial_properties: {},
  error: {},
};

export const commercialReducer = (state = initialCommercialState, action) => {
  switch (action.type) {
    case COMMERCIAL_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case COMMERCIAL_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        commercial_properties: action.payload,
      };
    case COMMERCIAL_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
