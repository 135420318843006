import React, {useRef} from "react";
import {
  book_appraisal_card_data,
  book_appraisal_data,
} from "../../../data/sellData";
import { Image } from "../../../components/Image";
import { Formik, Form, ErrorMessage } from "formik";
import { marketAppraisalFormValidationSchema } from "../../../utils/formikValidateForm";
import InputField from "../../../components/InputField";
import { ButtonSubmit } from "../../../components/Button";
import ReCAPTCHA from "react-google-recaptcha";
import { clientSiteKey } from "../../../api/reCaptchaKey";
import { useDispatch, useSelector } from "react-redux";
import { postMakeAppraisalRequest } from "../../../redux/redux-mail-store/actions";

const MarketAppraisalPage = () => {
  const recaptchaRef = useRef(null);

  //redux store
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.makeAppraisalData);

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(postMakeAppraisalRequest(values));
    setSubmitting(false);
    resetForm();
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <div>
      <div className="relative">
        <div
          style={{ backgroundImage: `url(${book_appraisal_data.image_url})` }}
          className="bg-cover h-[1050px] bg-center"
        ></div>
        <div className="bg-cover h-[1000px] w-full bg-center bg-yellow opacity-10 absolute top-0 left-0 right-0 bottom-0"></div>
        <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] w-full absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center gap-6">
          <div className="flex flex-col gap-12 justify-center items-center bg-ash/90 mt-20 mb:mt-10 px-3 py-3 mb:px-6 mb:py-10  rounded md:w-3/4">
            <div className="text-2xl lg:text-4xl flex flex-col items-center gap-3">
              <h1 className="text-center font-playfair text-white">
                {book_appraisal_data.title}
              </h1>
              <p className="text-base text-center xl:w-3/4 text-white">
                {book_appraisal_data.description}
              </p>
              <p className="text-base text-center xl:w-3/4 text-white">
                {book_appraisal_data.details}
              </p>
            </div>
            <Formik
              initialValues={{
                fname: "",
                lname: "",
                phone: "",
                email: "",
                address: "",
                information: "",
                recaptcha: "",
              }}
              validationSchema={marketAppraisalFormValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form className="flex flex-col gap-3 w-full max-w-[500px] justify-start items-start rounded pb-6">
                  <div className="flex flex-col mb:flex-row gap-3 w-full">
                    <InputField
                      type="text"
                      name="fname"
                      placeholder="First Name*"
                    />
                    <InputField
                      type="text"
                      name="lname"
                      placeholder="Last Name*"
                    />
                  </div>
                  <InputField type="text" name="phone" placeholder="Phone*" />
                  <InputField type="text" name="email" placeholder="Email*" />
                  <InputField
                    type="text"
                    name="address"
                    placeholder="Property address*"
                  />
                  <div className="mt-2">
                    <ReCAPTCHA
                      sitekey={clientSiteKey}
                      onChange={(value) => setFieldValue("recaptcha", value)}
                      size="normal"
                      ref={recaptchaRef}
                    />
                    <ErrorMessage
                      name="recaptcha"
                      component="div"
                      className="text-red-900 text-[12px]"
                    />
                  </div>
                  <ButtonSubmit
                  type="submit"
                  name="SUBMIT"
                  className="px-6 py-2 w-full mt-6 uppercase bg-yellow-light text-ash-200"
                  disabled={isSubmitting}
                  loader={isLoading}
                />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-20 space-y-10">
        <div className="text-center flex flex-col gap-2 items-center">
          <h1 className="uppercase text-xl lg:text-3xl">
            HOW YOUR FREE APPRAISAL WILL WORK
          </h1>
          <p className="text-base lg:w-3/4">
            You get a free, no-obligation appraisal with one of our friendly,
            highly experienced real estate agents, so you can see the full value
            of selling your property with @Primus Agent
          </p>
        </div>
        <div className="flex flex-col gap-4 sm:flex-wrap sm:flex-row">
          {book_appraisal_card_data.map((item) => (
            <div
              key={item.description}
              className="bg-gray px-6 py-5 flex flex-col items-center gap-2 text-center flex-1 sm:min-w-[250px] shadow-custom"
            >
              <Image src={item.image_url} className="w-20 h-20" />
              <h1 className="text-lg font-bold uppercase">{item.title}</h1>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarketAppraisalPage;
