import React from 'react'
import { tell_the_story_data } from '../../../data/sellData';
import { Image } from '../../../components/Image';
const NumberTellStorySection = () => {
  return (
    <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw] pt-12'>
        <h1 className='text-xl lg:text-2xl text-center pb-10 sm:pb-16 sm:text-left font-playfair uppercase'>{tell_the_story_data.title}</h1>
        <div className='flex flex-col items-start gap-8 sm:flex-row'>
            <div className='flex items-center gap-6 w-fit sm:flex-col sm:flex-1'>
                <Image src={tell_the_story_data.expert.image_url} className="h-14 sm:h-20 sm:w-fit"/>
                <div>
                    <h1 className='text-2xl font-bold pb-3'>{tell_the_story_data.expert.total}</h1>
                    <p>{tell_the_story_data.expert.details}</p>
                </div>
            </div>
            <div className='flex items-center gap-6 w-fit sm:flex-col sm:flex-1'>
                <Image src={tell_the_story_data.worth.image_url} className="h-14 sm:h-20 sm:w-fit"/>
                <div>
                    <h1 className='text-2xl font-bold pb-3'>{tell_the_story_data.worth.total}</h1>
                    <p>{tell_the_story_data.worth.details}</p>
                </div>
            </div>
            <div className='flex items-center gap-6 w-fit sm:flex-col sm:flex-1'>
                <Image src={tell_the_story_data.star.image_url} className="h-10 sm:h-16 sm:w-fit"/>
                <div>
                    <h1 className='text-2xl font-bold pb-3'>{tell_the_story_data.star.total}</h1>
                    <p>{tell_the_story_data.star.details}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NumberTellStorySection;