import React from "react";

const CurrentDateTime = ({currentDate}) => {

  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayOfWeek = dayNames[currentDate.getDay()];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="flex py-3">
      <p>{dayOfWeek},&nbsp;</p>
      <p>{day}&nbsp;</p>
      <p>{monthNames[month]}&nbsp;</p>
      <p>{year}</p>
    </div>
  );
};

export default CurrentDateTime;
