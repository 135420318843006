import React from "react";
import LandingSection from "./section/LandingSection";
import FormSection from "./section/FormSection";
import RegisterButtonSection from "./section/RegisterButtonSection";

const ContactPageLayout = () => {
  return (
    <>
      <LandingSection/>
      {/* <AccordionDetailsSection/> */}
      <FormSection/>
      <RegisterButtonSection/>
    </>
  );
};

export default ContactPageLayout;
