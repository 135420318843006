import React from 'react'
import { ButtonWithIcon } from '../../../components/Button';
import { Image } from '../../../components/Image';
import { sell_with_us_data } from '../../../data/sellData';
import { HiMiniArrowLongRight } from "react-icons/hi2";
import { NavLink } from 'react-router-dom';

const SellWithUsSection = () => {
  return (
    <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw] flex flex-col items-center sm:flex-row py-20 gap-5'>
        <Image src={sell_with_us_data.image_url} className="w-full sm:hidden"/>
        <div className='flex flex-col gap-6 lg:gap-12'>
            <div className='flex flex-col gap-4'>
                <h1 className='text-2xl lg:text-4xl font-playfair'>{sell_with_us_data.title}</h1>
                <p className='text-sm md:text-base'>{sell_with_us_data.description}</p>
            </div>
            <NavLink to="/experts" className="w-fit">
              <ButtonWithIcon name="Find Agent" Icon={<HiMiniArrowLongRight/>} className="px-5 py-1 text-base" />
            </NavLink>
        </div>
        <Image src={sell_with_us_data.image_url} className="hidden sm:block sm:w-[45%] sm:rounded-l-full"/>
    </div>
  )
}

export default SellWithUsSection;