import React from "react";
import { Carousel } from "@material-tailwind/react";
import { Button } from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import noImageAvailable from "../../../assets/logos/no-image-available.png";

let buyImage = [];

const BuyCardComponent = ({ data }) => {
  const navigate = useNavigate();

  //handle individual details
  const handleIndividualBuyProperty = (property_id) => {
    navigate(`/buy/${btoa(property_id)}`);
  };

  //for card images condition
  if (data.images.length >= 3) {
    buyImage = data.images.slice(0, 3);
  } else {
    buyImage = data.images;
  }

  return (
    <div className="bg-gray flex-1 min-w-[280px] xl:max-w-[320px] shadow-custom transition-transform duration-300 ease-in-out hover:scale-[1.01]">
      <Carousel
        className="h-[220px] min-w-[280px]"
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute z-40 bottom-4 left-2/4 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, index) => (
              <span
                key={index}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === index ? "w-8 bg-white" : "w-4 bg-white/50"
                }`}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>
        )}
      >
        {buyImage.length !== 0 ? (
          buyImage.map((item, index) => (
            <img
              key={index}
              src={item.url}
              alt="rent_image"
              className="h-full w-full object-cover"
            />
          ))
        ) : (
          <img
            src={noImageAvailable}
            alt="no_image"
            className="h-full w-full object-cover"
          />
        )}
      </Carousel>
      <div className="flex flex-col gap-2 px-3.5 py-3 text-sm">
        <div>
          <h1 className="text-yellow-200 font-bold text-[17px]">
            {data.street_no + " " + data.street_name}
          </h1>
          <p className="text-yellow-200">{data.suburb}</p>
        </div>
        <div>
          <p className="text-yellow-200">
            {data.bed}&nbsp;Beds&nbsp;|&nbsp;{data.bath}
            &nbsp;Baths&nbsp;|&nbsp;{data.car}&nbsp;Car
          </p>
          <p className="text-green font-semibold mt-1">
            ${data.price}
          </p>
        </div>
        <Button
          name="See Details"
          className="px-2.5 py-1.5 bg-yellow-200 font-system font-[500] text-white-500 shadow-none"
          onClick={() => handleIndividualBuyProperty(data.id)}
        />
      </div>
    </div>
  );
};

export default BuyCardComponent;
