export const PROPERTY_ENQUIRY_POST_REQUEST = "PROPERTY_ENQUIRY_POST_REQUEST";
export const PROPERTY_ENQUIRY_POST_SUCCESS = "PROPERTY_ENQUIRY_POST_SUCCESS";
export const PROPERTY_ENQUIRY_POST_FAILURE = "PROPERTY_ENQUIRY_POST_FAILURE";

export const AGENT_MAIL_POST_REQUEST = "AGENT_MAIL_POST_REQUEST";
export const AGENT_MAIL_POST_SUCCESS = "AGENT_MAIL_POST_SUCCESS";
export const AGENT_MAIL_POST_FAILURE = "AGENT_MAIL_POST_FAILURE";

export const MAKE_APPRAISAL_POST_REQUEST = "MAKE_APPRAISAL_POST_REQUEST";
export const MAKE_APPRAISAL_POST_SUCCESS = "MAKE_APPRAISAL_POST_SUCCESS";
export const MAKE_APPRAISAL_POST_FAILURE = "MAKE_APPRAISAL_POST_FAILURE";