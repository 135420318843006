import React from 'react'
import { our_story_section_data } from '../../../data/homeData';
const OurStorySection = () => {
  return (
    <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw] py-20 space-y-10'>
        <div className='text-center'>
            <h1 className='uppercase text-2xl font-playfair'>WE ARE THE DIFFERENCE</h1>
            <p className='text-base font-lato'>Better service, better results</p>
        </div>
        <div className='flex flex-col justify-center sm:flex-row gap-3 flex-wrap'>
            {
                our_story_section_data.map(item => (
                    <div key={item.id} className='bg-gray px-6 py-5 text-center flex-1 w-full sm:max-w-[260px] shadow-custom font-lato'>
                        <h1 className='text-2xl text-yellow'>{item.total}</h1>
                        <p className='text-yellow-200'>{item.title}</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default OurStorySection;