import ScaleLoader from "react-spinners/ScaleLoader";

export const Button = ({
  className,
  name,
  onClick,
  disabled,
  type,
  primary = false,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`tracking-widest cursor-pointer shadow-custom-500 font-lato font-semibold truncate rounded w-fit hover:outline hover:outline-1 hover:outline-ash-500  ${className}`}
    >
      {name}
    </button>
  );
};

export const ButtonWithIcon = ({ className, name, Icon, onClick, type }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`tracking-widest cursor-pointer font-lato font-semibold text-ash-200 shadow-custom-500 truncate flex items-center justify-center bg-yellow-light ${
        Icon ? "gap-2" : "gap-0"
      } rounded w-fit hover:outline hover:outline-1 hover:outline-ash ${className}`}
    >
      <span>{name}</span>
      <span>{Icon}</span>
    </button>
  );
};

export const ButtonIcon = ({ className, onClick, Icon, type }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${className} tracking-widest cursor-pointer w-fit font-lato shadow-custom-500`}
    >
      {Icon}
    </button>
  );
};

export const ButtonWithBorder = ({ className, name, onClick, type, width }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`tracking-widest cursor-pointer shadow-custom-500 font-lato truncate rounded ${width ? width : "w-fit"} outline outline-1 hover:outline-yellow-light ${className}`}
    >
      {name}
    </button>
  );
};


export const ButtonSubmit = (props) => {
  return (
      <button
        type={props.type}
        disabled={props.disabled}
        className={`tracking-widest truncate font-lato rounded shadow-custom-500 hover:outline hover:outline-1 hover:outline-ash ${props.className}`}
        onClick={props.onClick}
      >
        {props.loader ? (
          <div className="flex items-center justify-center w-full gap-2">
            <ScaleLoader color="#36d7b7" height={16} />
            <span>Processing...</span>
          </div>
        ) : (
          `${props.name}`
        )}
      </button>
  );
};
