import React, { useState } from 'react'
import { advertise_section_data } from '../../../data/homeData'
import { ButtonWithBorder } from '../../../components/Button'
import AdvertiseSectionModal from './AdvertiseSectionModal'

const AdvertiseSection = () => {
  const [toggleModal, setToggleModal]=useState(false);

  const handleToggleModal =(value)=>{
    setToggleModal(value);
  }
    const image_src = {
        backgroundImage: `url(${advertise_section_data.image_url})`,
      }

  return (
    <>
    <div className='relative'>
        <div style={image_src} className='bg-cover h-[600px] bg-center'></div>
        <div className='bg-cover h-[600px] w-full bg-center bg-yellow opacity-10 absolute top-0 left-0 right-0 bottom-0'></div>
        <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw] w-full absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
            <div className='max-w-[360px] py-8 px-5 bg-ash/80 flex flex-col items-center gap-3 rounded shadow-custom'>
               <h1 className='uppercase font-lato text-white-500'>Everything Primus Agents</h1>
               <p className='text-center text-2xl lg:text-4xl font-playfair text-white-500'>{advertise_section_data.title}</p>
               <ButtonWithBorder name="Watch" onClick={()=>handleToggleModal(!toggleModal)} className="px-6 py-2 mt-3 rounded-full text-white-500 outline-white-500 transition-transform transform hover:scale-105"/>
            </div>
        </div>
    </div>
    <AdvertiseSectionModal isOpen={toggleModal} handleClose={handleToggleModal}/>
    </>
  )
}

export default AdvertiseSection;