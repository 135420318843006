import {BUY_GET_FAILURE, BUY_GET_REQUEST, BUY_GET_SUCCESS} from "./actionTypes";
  
  export const getBuyRequest = () => {
    return {
      type: BUY_GET_REQUEST,
      meta: "Buy data request",
    };
  };
  
  export const getBuySuccess = (data) => {
    return {
      type: BUY_GET_SUCCESS,
      payload: data,
      meta: "Buy data success",
    };
  };
  
  export const getBuyFailure = (error) => {
    return {
      type: BUY_GET_FAILURE,
      payload: error,
      meta: "Buy data failure",
    };
  };
