import React from "react";
import { Formik, Form } from "formik";
import { agentLoginValidationSchema } from "../../utils/formikValidateForm";
import { Button } from "../../components/Button";
import InputField from "../../components/InputField";
import { MdOutlineManageAccounts } from "react-icons/md";

const AgentLoginPage = () => {
  return (
    <div className="mt-24 px-[5vw] sm:px-[10vw] xl:px-[15vw] py-10 lg:py-28 w-full flex items-center justify-center">
      <div className="w-full sm:w-[450px]">
        <div className="flex flex-col items-center gap-2">
          <MdOutlineManageAccounts className="w-28 h-28 bg-gray rounded-full p-5"/>
          <div className="h-0.5 w-full bg-yellow"></div>
          <h1 className="text-xl uppercase">Agent Log in</h1>
          <div className="h-0.5 w-full bg-yellow"></div>
        </div>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={agentLoginValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-3 py-5 w-full justify-center items-center rounded">
              <div className="flex flex-col gap-4 w-full">
                <InputField type="text" name="email" placeholder="Email*" />
                <InputField
                  type="password"
                  name="password"
                  placeholder="Password*"
                />
                <div className="flex items-center gap-2">
                  <input type="checkbox" className="w-4 h-4" />
                  <label>Remember me</label>
                </div>
              </div>
              <Button
                name="LOG IN"
                className="px-6 py-2 w-full mt-3 bg-yellow-light text-ash-500"
                disabled={isSubmitting}
                primary={true}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AgentLoginPage;
