import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  AGENT_MAIL_POST_REQUEST,
  MAKE_APPRAISAL_POST_REQUEST,
  PROPERTY_ENQUIRY_POST_REQUEST,
} from "./actionTypes";
import {
  postMakeAppraisalFailure,
  postMakeAppraisalSuccess,
  postPropertyEnquirySuccess,
  postPropertyEnquiryFailure,
  postAgentEmailSuccess,
  postAgentEmailFailure,
} from "./actions";
import { postAppraisalApi, postPropertyApi, postAgentMailApi } from "./postApi";

//worker saga
function* postMakeAppraisalWorkerSaga(action) {
  try {
    const data = yield call(postAppraisalApi, action.payload);
    yield put(postMakeAppraisalSuccess(data));
    // console.log('data:',data)
    //toastify
    if (data.success) {
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  } catch (error) {
    yield put(postMakeAppraisalFailure(error));
    // console.log('error:',error)
    toast.error("Email sent failed!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } finally {
    toast.clearWaitingQueue();
  }
}

function* postPropertyEnquiryWorkerSaga(action) {
  try {
    const data = yield call(postPropertyApi, action.payload);
    yield put(postPropertyEnquirySuccess(data));
    // console.log('sagas data:',data)
    //toastify
    if (data.success) {
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  } catch (error) {
    yield put(postPropertyEnquiryFailure(error));
    // console.log('sagas error:',error)
    toast.error("Email sent failed!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } finally {
    toast.clearWaitingQueue();
  }
}

function* postAgentMailWorkerSaga(action) {
  try {
    const data = yield call(postAgentMailApi, action.payload);
    yield put(postAgentEmailSuccess(data));
    // console.log('data:',data)
    //toastify
    if (data.success) {
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  } catch (error) {
    yield put(postAgentEmailFailure(error));
    // console.log('error:',error)
    toast.error("Email sent failed!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } finally {
    toast.clearWaitingQueue();
  }
}

//watcher saga effects
//take every request send with reducer action request
export function* MailWatcherSaga() {
  yield takeEvery(MAKE_APPRAISAL_POST_REQUEST, postMakeAppraisalWorkerSaga);
  yield takeEvery(PROPERTY_ENQUIRY_POST_REQUEST, postPropertyEnquiryWorkerSaga);
  yield takeEvery(AGENT_MAIL_POST_REQUEST, postAgentMailWorkerSaga);
}
