import React, { useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import InputField from "../../../components/InputField";
import TextArea from "../../../components/TextArea";
import { ButtonSubmit } from "../../../components/Button";
import ReCAPTCHA from "react-google-recaptcha";
import { clientSiteKey } from "../../../api/reCaptchaKey";
import { requestAppraisalFormValidationSchema } from "../../../utils/formikValidateForm";
import { postMakeAppraisalRequest } from "../../../redux/redux-mail-store/actions";
import { useDispatch, useSelector } from "react-redux";

const RequestAppraisalButton = ({ handleCloseModal }) => {
  const recaptchaRef = useRef(null);

  //redux store
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.makeAppraisalData);

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(postMakeAppraisalRequest(values));
    setSubmitting(false);
    resetForm();
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  const listStyle = { listStyleType: "square" };

  return (
    <div className="flex flex-col max-w-[550px] p-6 rounded-lg mt-[450px] ">
      <div className="flex items-center justify-between text-xl bg-yellow p-6">
        <p>Request Property Appraisal</p>
        <AiOutlineClose
          className="cursor-pointer"
          onClick={() => handleCloseModal(false)}
        />
      </div>

      <div className="bg-white p-6">
        <div className="px-6">
          <ul style={listStyle}>
            <li>Free, no-obligation valuation of your property.</li>
            <li>
              Extended? Renovated? Remortgaging? Considering selling later? Or
              just curious?
            </li>
            <li>
              Knowing your property’s value can help you make better informed
              long-term decisions.
            </li>
          </ul>
        </div>
        <div>
          <Formik
            initialValues={{
              fname: "",
              lname: "",
              phone: "",
              email: "",
              address: "",
              information: "",
              recaptcha: "",
            }}
            validationSchema={requestAppraisalFormValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="flex flex-col gap-3 py-5 w-full justify-start items-start rounded">
                <div className="w-full flex flex-col gap-3">
                  <InputField
                    type="text"
                    name="fname"
                    placeholder="First Name*"
                  />
                  <InputField
                    type="text"
                    name="lname"
                    placeholder="Last Name*"
                  />
                  <InputField type="text" name="phone" placeholder="Phone*" />
                  <InputField type="text" name="email" placeholder="Email*" />
                  <InputField
                    type="text"
                    name="address"
                    placeholder="Property address*"
                  />
                </div>
                <TextArea
                  name="information"
                  placeholder="Additional Information*"
                />
                <div>
                  <ReCAPTCHA
                    sitekey={clientSiteKey}
                    onChange={(value) => setFieldValue("recaptcha", value)}
                    size="normal"
                    ref={recaptchaRef}
                  />
                  <ErrorMessage
                    name="recaptcha"
                    component="div"
                    className="text-red-900 text-[12px]"
                  />
                </div>
                <ButtonSubmit
                  type="submit"
                  name="SUBMIT"
                  className="px-6 py-2 w-full mt-6 uppercase bg-yellow-light text-ash-200"
                  disabled={isSubmitting}
                  loader={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RequestAppraisalButton;
