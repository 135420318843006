
export const landing_section_data = {
    title:'Get in touch.',
    description:'Please complete the form below and someone from our head office team will be in touch to assist you',
    image_url:require('../assets/contact-images/landing-contact.jpg'),
}

export const form_section_data = {
    title:'How can we help?',
    description:'Our team are ready to answer your enquiry so please fill in the form below.',
    image_url:require('../assets/contact-images/form-contact.jpg'),
}

export const office_details = {
    title:'Welcome to primus pty ltd',
    license_no: '10132517',
    trading_as: 'Primus Property',
    email:'rbo@primusagents.com.au',
    website:'www.primusagents.com.au'
}

export const head_office = {
    title:'Head Office',
    phone:'18008PRIMUS',
}

export const local_office = {
    title:'Local Office',
    phone:'0451593383',
}