import React, { useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import InputField from "../../../components/InputField";
import { ButtonSubmit } from "../../../components/Button";
import { homeSectionValidationSchema } from "../../../utils/formikValidateForm";
import ReCAPTCHA from "react-google-recaptcha";
import { clientSiteKey } from "../../../api/reCaptchaKey";
import { useDispatch, useSelector } from "react-redux";
import { postMakeAppraisalRequest } from "../../../redux/redux-mail-store/actions";

const FormSection = () => {
  const recaptchaRef = useRef(null);
  //redux store
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.makeAppraisalData);

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(postMakeAppraisalRequest(values));
    setSubmitting(false);
    resetForm();
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] pb-20 flex flex-col gap-5 w-full justify-center items-center">
      <div className="space-y-4 text-center">
        <h1 className="text-2xl font-playfair">
          GET MARKET INSIGHT INTO YOUR AREA
        </h1>
        <p className="max-w-[650px]">
          Our team of industry-leading software engineers and data scientists
          are building analytical tools to help you answer real estate’s
          toughest questions.
        </p>
        <p className="font-bold">
          Enter your Suburb to discover insights for your area.
        </p>
      </div>
      <Formik
        initialValues={{
          fname: "",
          lname: "",
          phone: "",
          email: "",
          address: "",
          information: "",
          recaptcha: "",
        }}
        validationSchema={homeSectionValidationSchema}
        va
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="flex flex-col gap-3 sm:px-16 sm:py-16 w-full max-w-[500px] justify-start items-start rounded sm:shadow-custom">
            <div className="flex flex-col mb:flex-row gap-3 w-full">
              <InputField type="text" name="fname" placeholder="First Name*" />
              <InputField type="text" name="lname" placeholder="Last Name*" />
            </div>
            <InputField type="text" name="phone" placeholder="Phone*" />
            <InputField type="text" name="email" placeholder="Email*" />
            <InputField type="text" name="address" placeholder="Suburb*" />
            <div className="mt-2">
              <ReCAPTCHA
                sitekey={clientSiteKey}
                onChange={(value) => setFieldValue("recaptcha", value)}
                size="normal"
              />
              <ErrorMessage
                name="recaptcha"
                component="div"
                className="text-red text-[12px]"
              />
            </div>
            <ButtonSubmit
              type="submit"
              name="Discover"
              className="px-6 py-2 w-full mt-6 cursor-pointer bg-yellow-light text-ash-500"
              disabled={isSubmitting}
              loader={isLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormSection;
