export const privacyData = [
    {
        heading: "What is Personal Information?",
        description: "When used in this Privacy Policy, the term “Personal Information” has the meaning given to it in the Act. In general terms, it is any information that can be used to personally identify you. This may include your name, address, telephone number, email address and profession or occupation. If the information we collect personally identifies you, or you are reasonably identifiable from it, the information will be considered Personal Information."
    },
    {
        heading: "What type of Personal Information does Primus Property collect and hold about me?",
        description: {
            d1: "Primus Property may collect any of the following information about you if you are a current or prospective vendor, purchaser or landlord of real estate or if you visit our website or make an enquiry with us via another method:",


            b1: `contact details (including, name, address, telephone number and email address);
                 driver’s license number;
                 current property ownership and title details;
                 desired property ownership details; and
                 details of properties sold or acquired by you`,
            d2: "If you are a current or prospective tenant for a rental property, Primus Property may collect the following additional information from you:",
            b2: `contact details (including, name, address, telephone number and email address);
                 driver’s license number;
                 employment details such as your employment status, employer, salary, length of employment;
                 other sources of income; and
                 personal and financial referees whom we may contact about your application.`,
            d3: "Sometimes Primus Property may also need to ask you for other forms of Personal Information to enable us to provide services to you."
        }
    },

    {
        heading: "How does Primus Property collect Personal Information about me?",
        description: {
            d1: "Primus Property collects your Personal Information directly from you unless it is unreasonable or impracticable to do so. When collecting Personal Information from you, we may collect in various ways, including through when you write to or email us, when you access and use our website and during conversations between you and our representatives;",


            b1: ``,
            d2: "Primus Property may collect information about you from the following third party sources:",
            b2: `various databases in the public domain such as Telstra White Pages, Australia Post DPID database, Titles Office or other property databases;
                 credit reporting bodies, law enforcement agencies and other government entities; and
                 referrals and recommendations from existing clients of Primus Property.`,
            d3: "As part of its due diligence process prior to entering into lease or rental agreements with you, Primus Property may disclose your information to authorised credit-checking agencies."
        }
    },

    {
        heading: "What happens if we can’t collect your Personal Information?",
        description: {
            d1: "If you do not provide us with the Personal Information described above, some or all of the following may happen:",


            b1: `we may not be able to provide our services to you, either to the same standard or at all;
                 we may not be able to provide you with information about services that you may want, including information about new properties that are available for lease or purchase; or
                 we may be unable to tailor the content of our website to your preferences and your experience of our website may not be as enjoyable or useful.`,

            d2: "",
            b2: ``,
            d3: " "
        }
    },
    {
        heading: "Why does Primus Property collect, hold, use and disclose your Personal Information?",
        description: {
            d1: "Primus Property collects this information for a range of purposes including:",


            b1: `enable Primus Property to provide you with the services and/or products you may require;
                 to comply with local, state and federal legislation or regulations, including with respect to real estate property sales, rental and administration;
                 to enable Primus Property and its third party partner businesses to assist you with related or ancillary services, as required;
                 for our internal administrative, marketing, planning, product development and research requirements
                 to share your Personal Information within the Primus Property group (including between Primus Property Pty Ltd’s related entities and Primus Property franchisees), contractors or service providers;
                 to update Primus Property’s records and keep your contact details up to date;
                 to deal with your queries or customer service issues promptly, whether by email, telephone or mail;
                 to keep you up-to-date with services we think would be of particular interest to you;
                 to conduct relevant business processing functions; and
                 to process and respond to any complaint made by you.`,

            d2: "Your Personal Information will not be shared, sold, rented or disclosed other than as described in this Privacy Policy.",
            b2: ``,
            d3: " "
        }
    },
    {
        heading: "How does Primus Property use the Personal Information held about me?",
        description: {
            d1: "Any Personal Information about you that Primus Property collects and records will only be used or disclosed by Primus Property for the purpose of:",


            b1: `compliance with obligations under real-estate regulations and laws applicable for all states in Australia;
                 Helping Primus Property efficiently and effectively deliver and improve on products and services;
                 any other purpose if you would reasonably expect Primus Property to use or disclose that Personal Information for that other purpose and that other purpose is related to the purpose for which the information is collected;
                 to provide you with access to protected areas of our website and to assess the performance of our website and to improve the operation of our website;
                 to conduct business processing functions including sharing Personal Information within Primus Property and with its contractors, service providers or other third parties; and
                 for Primus Property’s administrative, planning, product or service development, quality control and research purposes; and
                 complying with any other relevant laws or regulations.`,

            d2: "Primus Property may also use or disclose the information it collects for any other purpose specified to you at the time of collection. If Primus Property wishes to use or disclose any of your Personal Information in any other ways than described above, Primus Property will not do so unless it:",
            b2: `has your express consent to do so;
                  has reasonable grounds to believe that the use or disclosure is necessary to prevent a threat to life or health; or
                  has reasonable grounds to believe that the use or disclosure is required by law or to enforce the law.`,
            d3: ""
        }
    },
    {
        heading: "What about direct marketing?",
        description: {
            d1: "We may send you direct marketing communications and information about our services that we consider may be of interest to you. These may include:",


            b1: `offering to provide you with products or services provided by Primus Property and third party business partners; or
                  sending you news and other information about Primus Property’s activities and general promotional material which Primus Property believes may be of interest to you.`,

            d2: "These communications may be sent in various forms, including mail, SMS, fax and email, in accordance with applicable marketing laws, such as the Spam Act 2003 (Cth). When Primus Property does this, Primus Property will provide you with an express opportunity to decline receiving any further communication from Primus Property of this type via an opt-out mechanism. If you indicate a preference for a method of communication, we will endeavour to use that method whenever practical to do so.",
            b2: ``,
            d3: "You may at any time request not to receive direct marketing from Primus Property by contacting us (see the details below) or by using opt-out facilities provided in the marketing communications and we will then ensure that your name is removed from our mailing list."
        }
    },
    {
        heading: "The Primus Property website",
        description: {
            d1: "Primus Property’s privacy policy also applies to our website at www.primusagents.com.au",


            b1: ``,

            d2: "When you access our website, we may send a “cookie” (which is a small summary file containing a unique ID number) to your computer. This enables us to recognise your computer and greet you each time you visit our website without bothering you with a request to register. It also enables us to keep track of services you view so that, if you consent, we can send you news about those services. We also use cookies to measure traffic patterns, to determine which areas of our website have been visited and to measure transaction patterns in the aggregate. We use this to research our users’ habits so that we can improve our online services and create a better online experience for you. Our cookies do not collect Personal Information, although they do identify your browser. If you do not wish to receive cookies, you can set your browser so that your computer does not accept them. We may log IP addresses (that is, the electronic addresses of computers connected to the internet) to analyse trends, administer the website, track users movements, and gather broad demographic information.",
            b2: ``,
            d3: "As our website is linked to the Internet, and the Internet is inherently insecure, we cannot provide any assurance regarding the security of transmission of information you communicate to us online. We also cannot guarantee that the information you supply will not be intercepted while being transmitted over the Internet. Accordingly, any Personal Information or other information, which you transmit to us online, is transmitted at your own risk."
        }
    },

    {
        heading: "Can I be anonymous when I deal with Primus Property?",
        description: {
            d1: "To some extent you can preserve your anonymity when you deal with Primus Property. You can browse our website without providing any Personal Information, you can access our real-estate advertising in various publications and you can generally attend auctions without providing personal details if you do not intend to bid.",


            b1: ``,

            d2: "However, when you enter into transactions with Primus Property (including Primus Property franchisees) that involve the sale, purchase or rental of real estate property, then various Acts, statutory legislation and regulations require you to provide Primus Property with Personal Information so as to identify you.",
            b2: ``,
            d3: ""
        }
    },
    {
        heading: "What happens if I want to access or correct the Personal Information held about me?",
        description: {
            d1: "You may request access to your Personal Information held by Primus Property, at any time by contacting us (see details below). We will try to provide you with suitable means of accessing your Personal Information (for example, by mailing or emailing it to you), subject to some exceptions. We will respond to your request for access within 14 days and endeavour to provide the requested information within 30 days.",

            b1: `where in Primus Property’s opinion providing your access may create a serious threat to the life or health of any individual or may be an unreasonable intrusion into the privacy of another individual;
                 where your request for access is, in Primus Property’s opinion, frivolous or vexatious; or
                 where providing access would be unlawful, may prejudice an investigation of possible unlawful activity, may prejudice enforcement of law, or denying access is specifically authorised by law.`,

            d2: "If for any reason Primus Property does not allow you to access your Personal Information, Primus Property will provide you with reasons in writing for its decision.",
            b2: ``,
            d3: "If you believe that Personal Information Primus Property holds about you is incorrect, incomplete or inaccurate, then you may request that we amend it. Primus Property will consider if the information requires amendment. If Primus Property does not agree that there are grounds for amendment, then Primus Property will add a note to the Personal Information stating that you disagree with it."
        }
    },

    {
        heading: "Who does Primus Property disclose my Personal Information to?",
        description: {
            d1: "We may disclose your Personal Information:",

            b1: `within Primus Property, including between Primus Property franchisees and subsidiaries of Primus Property Pty Ltd;
                 to Primus Property employees and our contractors or service providers for the purposes of operation of our website or our business, fulfilling requests by you, and to otherwise provide services to you;
                 if you are a tenant of any property that we are managing, to the National Tenancy Database;
                 to suppliers and other third parties with whom we have commercial relationships, for business, marketing, and related purposes; and
                 to any organisation for any authorised purpose with your express consent.`,

            d2: "There are some instances when Primus Property (including Primus Property franchisees) need to provide your Personal Information to third parties. Primus Property or Primus Property franchisees may be bound by law to provide your details to government-related bodies, including the Titles Office or the Residential Tenancies Bond Authority. Sometimes Primus Property or Primus Property franchisees may need to outsource some of our administrative and other property-related functions to external organisations such as advertising agencies, signwriters, building and garden maintenance contractors or consultants such as accountants, lawyers, surveyors and other advisors. Other third parties may include web hosting providers, IT systems administrators, mailing houses, couriers, payment processors, data entry service providers, electronic network administrators, debt collectors, and other business advisors and consultants. Most of the above organisations are bound by express or implied confidentiality obligations to Primus Property.",
            b2: ``,
            d3: "Primus Property Pty Ltd may also need to provide your details to Primus Property franchisees. Primus Property may also provide your details to third party business partners for the purpose of offering to provide you with products or services provided by Primus Property or these business partners that Primus Property considers relevant to you. Primus Property does not sell your personal details to other organisations. We may however use the information about you to assist us with internal marketing and research. We may disclose Personal Information to our related bodies corporate and third party suppliers and service providers located overseas for some of the purposes listed above. We take reasonable steps to ensure that the overseas recipients of your Personal Information do not breach the privacy obligations relating to your Personal Information."
        }
    },

    {
        heading: "How does Primus Property keep my Personal Information secure?",
        description: {
            d1: "Primus Property will take reasonable steps to ensure information Primus Property collects, uses or discloses is stored in a secure environment that is accessed only by persons authorised by Primus Property so as to prevent interference, misuse, loss, unauthorised access, modification or disclosure.",


            b1: ``,

            d2: "If the Personal Information is no longer needed for any purpose, Primus Property will take reasonable steps to destroy or permanently de-identify the Personal Information.",
            b2: ``,
            d3: "Primus Property endeavours to provide a secure environment and a reliable system but you should be aware that there are inherent risks associated with the electronic storage and transmission of information (particularly via the Internet) which cannot be guaranteed to be 100% secure."
        }
    },

    {
        heading: "How does this policy change over time?",
        description: [
            "Primus Property may change this Privacy Policy from time to time. Any updated versions of this Privacy Policy will be posted on our website and will be effective from the date of posting",

          
      "This privacy policy was last updated on 16 February 2016.",
       
          "Although Primus Property intends to observe this policy at all times, it is not legally binding on Primus Property in any way unless as otherwise described as required under the relevant legislation. From time to time Primus Property may regard it as necessary or desirable to act outside the policy. Primus Property may do so, subject only to any other applicable contractual rights you have and any statutory rights you have under the Act or other applicable legislation."
        ]
    },
];