import React from 'react'
import { BiBuildingHouse } from "react-icons/bi";

const NoPropertyFound = ({className}) => {
  return (
    <div className={`w-full flex flex-col gap-2 items-center justify-center h-[250px] bg-gray ${className}`}>
        <BiBuildingHouse className='w-28 h-28 p-6 text-ash/80 rounded-full bg-white-500'/>
        <p className='text-ash/80'>No properties available!!!</p>
    </div>
  )
}

export default NoPropertyFound;