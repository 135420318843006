import axios from "axios";
import {
  AGENT_MAIL_POST_API_URL,
  MAKE_APPRAISAL_POST_API_URL,
  PROPERTY_ENQUIRY_POST_API_URL,
} from "../../api/LaravelApiUrl";

export const postAppraisalApi = async (postData) => {
  const csrfToken = document.head.querySelector(
    'meta[name="csrf-token"]'
  ).content;

  try {
    const response = await axios.post(
      MAKE_APPRAISAL_POST_API_URL,
      JSON.stringify(postData),
      {
        withCredentials: true,
        headers: {
          "X-CSRF-TOKEN": csrfToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error making POST request:", error);
  }
};

export const postPropertyApi = async (postData) => {
  const csrfToken = document.head.querySelector(
    'meta[name="csrf-token"]'
  ).content;

  try {
    const response = await axios.post(
      PROPERTY_ENQUIRY_POST_API_URL,
      JSON.stringify(postData),
      {
        withCredentials: true,
        headers: {
          "X-CSRF-TOKEN": csrfToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error making POST request:", error);
  }
};

export const postAgentMailApi = async (postData) => {
  const csrfToken = document.head.querySelector(
    'meta[name="csrf-token"]'
  ).content;

  try {
    const response = await axios.post(
      AGENT_MAIL_POST_API_URL,
      JSON.stringify(postData),
      {
        withCredentials: true,
        headers: {
          "X-CSRF-TOKEN": csrfToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error making POST request:", error);
  }
};
