import BuyPageLayout from "./pages/BuyPage/BuyPageLayout.js";
import ExpertsPageLayout from "./pages/ExpertsPage/ExpertsPageLayout.js";
// import StrataPageLayout from "./pages/StrataPage/StrataPageLayout";
import ServicesPageLayout from "./pages/ServicesPage/ServicesPageLayout.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout.js";
import BuyInspectionPage from "./pages/BuyPage/InspectionPage/Buy.InspectionPage.js";
import BuyAuctionPage from "./pages/BuyPage/AuctionPage/Buy.AuctionPage.js";
import SellPageLayout from "./pages/SellPage/SellPageLayout.js";
import HomePageLayout from "./pages/HomePage/HomePageLayout.js";
import RentPageLayout from "./pages/RentPage/RentPageLayout.js";
import CommercialPageLayout from "./pages/CommercialPage/CommercialPageLayout.js";
import ContactPageLayout from "./pages/ContactPage/ContactPageLayout.js";
import MarketAppraisalPage from "./pages/ExpertsPage/MarketAppraisalPage/Experts.MarketAppraisalPage.js";
import CareersPage from "./pages/ExpertsPage/Careers/Experts.CareersPage.js";
import { NavBarProvider } from "./pages/NavigationBar/ConextAPI/NavBarContext.js";
// import AgentLoginPage from "./pages/AgentLoginPage/AgentLoginPage.js";
import PropertyHubLoginPage from "./pages/PropertyHubLoginPage/PropertyHubLoginPage.js";
import IndividualProfilePage from "./pages/ExpertsPage/ExpertProfilePage/Expert.IndividualProfilePage.js";
import RentIndividualPropertyPage from "./pages/RentPage/PropertyPage/Rent.IndividualPropertyPage.js";
import PrivacyPolicyPage from "./pages/Footer/PrivacyPolicyPage.js";
import RentInspectionPage from "./pages/RentPage/InspectionPage/Rent.InspectionPage.js";
import DocumentsPageLayout from "./pages/DocumentsPage/DocumentsPageLayout.js";
import RentLeasedPage from "./pages/RentPage/LeasedPage/Rent.LeasedPage.js";
import BuyIndividualPropertyPage from "./pages/BuyPage/PropertyPage/Buy.IndividualPropertyPage.js";
import BuySoldPage from "./pages/BuyPage/SoldPage/Buy.SoldPage.js";
import CommercialBusinessSalePage from "./pages/CommercialPage/BusinessSalePage/CommercialBusinessSalePage.js";
import CommercialLeasePage from "./pages/CommercialPage/PropertyLeasePage/CommercialLeasePage.js";
import LeasedCommercialPage from "./pages/CommercialPage/LeasedCommercial/LeasedCommercialPage.js";
import CommercialIndividualPropertyPage from "./pages/CommercialPage/PropertyPage/Commercial.IndividualPropertyPage.js";
import SaleCommercialPage from "./pages/CommercialPage/SaleCommercial/SaleCommercialPage.js";
import BusinessCommercialPage from "./pages/CommercialPage/BusinessCommercial/BusinessCommercialPage.js";

const App = () => {
  return (
    <NavBarProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePageLayout />} />
              <Route path="sell" element={<SellPageLayout />} />
              {/* Buy routes start */}
              <Route path="buy" element={<BuyPageLayout />} />
              <Route path="buy/:id" element={<BuyIndividualPropertyPage />} />
              <Route
                path="buy/inspection-times"
                element={<BuyInspectionPage />}
              />
              <Route path="buy/auction-times" element={<BuyAuctionPage />} />
              <Route path="buy/sold-properties" element={<BuySoldPage />} />
              {/* Buy routes end */}
              {/* Rent routes start */}
              <Route path="rent" element={<RentPageLayout />} />
              <Route path="rent/:id" element={<RentIndividualPropertyPage />} />
              <Route
                path="rent/inspection-times"
                element={<RentInspectionPage />}
              />
              <Route
                path="rent/leased-properties"
                element={<RentLeasedPage />}
              />
              {/* Rent routes end */}
              {/* Commercial routes start */}
              <Route path="commercial" element={<CommercialPageLayout />} />
              <Route
                path="commercial/commercial-for-lease"
                element={<CommercialLeasePage />}
              />
              <Route
                path="commercial/business-for-sale"
                element={<CommercialBusinessSalePage />}
              />
              <Route
                path="commercial/sale-commercial"
                element={<SaleCommercialPage />}
              />
              <Route
                path="commercial/leased-commercial"
                element={<LeasedCommercialPage />}
              />
              <Route
                path="commercial/business-commercial"
                element={<BusinessCommercialPage />}
              />
              <Route
                path="commercial/:id"
                element={<CommercialIndividualPropertyPage />}
              />
              {/* Commercial routes end */}
              {/* Expert routes start */}
              <Route path="experts" element={<ExpertsPageLayout />} />
              <Route path="experts/:id" element={<IndividualProfilePage />} />
              <Route
                path="experts/market-appraisal"
                element={<MarketAppraisalPage />}
              />
              <Route path="experts/careers" element={<CareersPage />} />
              {/* Expert routes end */}
              {/* <Route path="strata" element={<StrataPageLayout />} /> */}
              <Route path="services" element={<ServicesPageLayout />} />
              <Route path="contact-us" element={<ContactPageLayout />} />
              {/* <Route path="agent-login" element={<AgentLoginPage />} /> */}
              <Route path="client-login" element={<PropertyHubLoginPage />} />
              {/* privacy policy */}
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              {/* document route */}
              <Route path="documents" element={<DocumentsPageLayout />} />
            </Route>
          </Routes>
        </Router>
    </NavBarProvider>
  );
};

export default App;
