import React from 'react'
import { Image } from "../../../components/Image";

const StrataListComponent = ({data, title}) => {
  return (
    <div>
        <h1 className='mb-6'>{title}</h1>
        <div className='space-y-2'>
            {data.map((item, index)=>(
                <div key={index} className='flex gap-1 '>
                    <Image src="../../../assets/sell-images/tick.png" className="w-5 h-5 mr-2" />
                    <p className='-mt-1'>{item}</p>
                </div>
            ))}
        </div>
    </div>
  )
}

export default StrataListComponent;