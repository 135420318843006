import {
  RENT_GET_REQUEST,
  RENT_GET_SUCCESS,
  RENT_GET_FAILURE,
} from "./actionTypes";

export const getRentRequest = () => {
  return {
    type: RENT_GET_REQUEST,
    meta: "Rent data request",
  };
};

export const getRentSuccess = (data) => {
  return {
    type: RENT_GET_SUCCESS,
    payload: data,
    meta: "Rent data success",
  };
};

export const getRentFailure = (error) => {
  return {
    type: RENT_GET_FAILURE,
    payload: error,
    meta: "Rent data failure",
  };
};
