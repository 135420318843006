import React from "react";
import { home_section_data } from "../../../data/homeData";
const LandingSection = () => {
  const image_src = {
    backgroundImage: `url(${home_section_data.image_url})`,
  };
  return (
    <div className="pt-20 relative">
      <div style={image_src} className="bg-cover h-[450px] bg-center"></div>
      <div className="bg-cover h-[450px] w-full bg-center bg-yellow opacity-10 absolute top-20 left-0 right-0 bottom-0"></div>
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] w-full text-white absolute top-20 left-0 right-0 bottom-0 flex flex-col justify-center items-center gap-6">
        <div className="text-4xl  xl:text-5xl flex flex-col gap-3 lg:gap-6 bg-ash/80 p-6 rounded">
          <h1 className="text-center font-playfair">{home_section_data.title}</h1>
          <p className="text-lg text-center font-lato">{home_section_data.description}</p>
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
