import CryptoJS from 'crypto-js';

const SECRET = "kgDP1gs71L%CjCXFvUJCKqxLly5qLV";

export const hash = (message)=>{
    const encrypted = CryptoJS.AES.encrypt(message, SECRET);
    return encrypted;
}

export const dehash = (encrypted)=>{
    return CryptoJS.AES.decrypt(encrypted, SECRET);
}