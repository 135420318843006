import React from 'react'
import { sell_table_data } from '../../../data/sellData';
import { Image } from '../../../components/Image';

const SellTableSection = () => {
  return (
    <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw] pb-20 '>
    <div className='shadow-custom p-3 -mt-[55px] sm:text-sm overflow-x-auto'>
        <table className='w-full'>
        <thead>
            <tr className='bg-gray'>
                <th className="px-4 py-3"></th>
                <th className="px-4 py-3 sm:truncate">Primus Agents</th>
                <th className="px-4 py-3 sm:truncate">Other Agencies</th>
            </tr>
        </thead>
        {sell_table_data.map((item)=>(
            <tbody key={item.id}>
                <tr className={item.id % 2 === 0 ? "bg-gray" : "bg-white"}>
                    <td className="px-4 py-2.5">{item.question}</td>
                    <td><div  className="flex justify-center w-auto px-4 py-2.5"><Image src="../../../assets/sell-images/tick.png" className="h-7"/></div></td>
                    <td className="px-4 py-2.5 text-center">{item.other_agencies}</td>
                </tr>
            </tbody>
        ))}
        </table>
    </div>
    </div>
  )
}

export default SellTableSection;