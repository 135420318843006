import {
  RENT_GET_REQUEST,
  RENT_GET_SUCCESS,
  RENT_GET_FAILURE
} from "./actionTypes";

const initialRentState = {
  isLoading: false,
  rent_properties: {},
  error: {},
};

export const rentReducer = (state = initialRentState, action) => {
  switch (action.type) {
    case RENT_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RENT_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rent_properties: action.payload,
      };
    case RENT_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};


