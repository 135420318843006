import * as Yup from "yup";

export const homeSectionValidationSchema = Yup.object().shape({
  fname: Yup.string().required("Name is required*"),
  address: Yup.string().required("Suburb is required*"),
  phone: Yup.string()
    .required("Phone is required*")
    .matches(/^(\d{8}|\d{3}-\d{3}-\d{4})$/, "Invalid phone number*"),
  email: Yup.string().email("Invalid email").required("Email is required*"),
  recaptcha: Yup.string().required("Please verify that you are not a robot."),
});

export const agentLoginValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required*"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters!")
    .required("Password is required*")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      "Password should contain at least one uppercase and lowercase character!"
    )
    .matches(/\d/, "Password should contain at least one number!")
    .matches(
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      "Password should contain at least one special character!"
    ),
});

export const clientLoginValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email!").required("Email is required*"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters!")
    .required("Password is required*")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      "Password should contain at least one uppercase and lowercase character!"
    )
    .matches(/\d/, "Password should contain at least one number!")
    .matches(
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      "Password should contain at least one special character!"
    ),
});

export const contactFormValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required*"),
  email: Yup.string().email("Invalid email!").required("Email is required*"),
  subject: Yup.string().required("Subject is required*"),
  message: Yup.string().required("Message is required*"),
  recaptcha: Yup.string().required("Please verify that you are not a robot."),
});

export const marketAppraisalFormValidationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required*"),
  email: Yup.string().email("Invalid email!").required("Email is required*"),
  phone: Yup.string()
    .required("Phone is required*")
    .matches(/^(\d{8}|\d{3}-\d{3}-\d{4})$/, "Invalid phone number!"),
  address: Yup.string().required("Property address is required*"),
  recaptcha: Yup.string().required("Please verify that you are not a robot."),
});

export const makeAppointmentFormValidationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required*"),
  email: Yup.string().email("Invalid email!").required("Email is required*"),
  phone: Yup.string()
    .required("Phone is required*")
    .matches(/^(\d{8}|\d{3}-\d{3}-\d{4})$/, "Invalid phone number!"),
  recaptcha: Yup.string().required("Please verify that you are not a robot."),
});

export const emailAgentFormValidationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required*"),
  email: Yup.string().email("Invalid email!").required("Email is required*"),
  phone: Yup.string()
    .required("Phone is required*")
    .matches(/^(\d{8}|\d{3}-\d{3}-\d{4})$/, "Invalid phone number!"),
  message: Yup.string().required("Message is required*"),
  recaptcha: Yup.string().required("Please verify that you are not a robot!"),
});

export const requestAppraisalFormValidationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required*"),
  email: Yup.string().email("Invalid email!").required("Email is required*"),
  phone: Yup.string()
    .required("Phone is required*")
    .matches(/^(\d{8}|\d{3}-\d{3}-\d{4})$/, "Invalid phone number!"),
  address: Yup.string().required("Property address is required*"),
  recaptcha: Yup.string().required("Please verify that you are not a robot!"),
});
