import React from 'react'
import LandingSection from './section/LandingSection';
import SellWithUsSection from './section/SellWithUsSection';
import CardContainerSection from './section/CardContainerSection';
import SetUsApartSection from './section/SetUsApartSection';
import SellTableSection from './section/SellTableSection';
import SellAdvertiseSection from './section/SellAdvertiseSection';
import ParallaxSection from './section/ParallaxSection';
import NumberTellStorySection from './section/NumberTellStorySection';
import NextStepSection from './section/NextStepSection';

const SellPageLayout = () => {
  return (
    <>
      <LandingSection/>
      <SellWithUsSection/>
      <CardContainerSection/>
      <SetUsApartSection/>
      <SellTableSection/>
      <SellAdvertiseSection/>
      <ParallaxSection/>
      <NumberTellStorySection/>
      <NextStepSection/>
    </>
  )
}

export default SellPageLayout;
