import React, { useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import InputField from "../../../components/InputField";
import TextArea from "../../../components/TextArea";
import { ButtonSubmit } from "../../../components/Button";
import ReCAPTCHA from "react-google-recaptcha";
import { clientSiteKey } from "../../../api/reCaptchaKey";
import { emailAgentFormValidationSchema } from "../../../utils/formikValidateForm";
import { useSelector, useDispatch } from "react-redux";
import { postAgentEmailRequest } from "../../../redux/redux-mail-store/actions";

const EmailAgentButton = ({ handleCloseModal, expertID }) => {
  const recaptchaRef = useRef(null);
  //redux store
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.agentMailData);

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(postAgentEmailRequest(values));
    setSubmitting(false);
    resetForm();
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  return (
    <div className="flex flex-col max-w-[550px] p-6 rounded-lg mt-[450px] ">
      <div className="flex items-center justify-between text-xl bg-yellow p-6">
        <p>Connect with Agent</p>
        <AiOutlineClose
          className="cursor-pointer"
          onClick={() => handleCloseModal(false)}
        />
      </div>

      <div className="bg-white p-6">
        <p>
          Please complete the form below and one of our friendly team will be in
          touch soon.
        </p>
        <div>
          <Formik
            initialValues={{
              agent_id: expertID,
              fname: "",
              lname: "",
              phone: "",
              email: "",
              message: "",
              recaptcha: "",
            }}
            validationSchema={emailAgentFormValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="flex flex-col gap-3 py-5 w-full justify-start items-start rounded">
                <div className="w-full flex flex-col gap-3">
                  <InputField
                    type="text"
                    name="fname"
                    placeholder="First Name*"
                  />
                  <InputField
                    type="text"
                    name="lname"
                    placeholder="Last Name*"
                  />
                  <InputField type="text" name="phone" placeholder="Phone*" />
                  <InputField type="text" name="email" placeholder="Email*" />
                </div>
                <TextArea name="message" placeholder="Message*" />
                <div>
                  <ReCAPTCHA
                    sitekey={clientSiteKey}
                    onChange={(value) => setFieldValue("recaptcha", value)}
                    size="normal"
                    ref={recaptchaRef}
                  />
                  <ErrorMessage
                    name="recaptcha"
                    component="div"
                    className="text-red-900 text-[12px]"
                  />
                </div>
                <ButtonSubmit
                  type="submit"
                  name="SUBMIT"
                  className="px-6 py-2 w-full mt-6 uppercase bg-yellow-light text-ash-200"
                  disabled={isSubmitting}
                  loader={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EmailAgentButton;
