import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRentRequest } from "../../RentPage/ReduxStore/actions";
import RentCardComponent from "../../RentPage/component/RentCardComponent";
import { getBuyRequest } from "../../BuyPage/ReduxStore/actions";
import BuyCardComponent from "../../BuyPage/component/BuyCardComponent";
import { getCommercialRequest } from "../../CommercialPage/ReduxStore/actions";
import LeasedCommercialCardComponent from "../../CommercialPage/component/LeasedCommercialCardComponent";
import SoldCardComponent from "../../BuyPage/component/SoldCardComponent";

const PropertyPortfolioTab = ({ expertID }) => {
  const [activeTab, setActiveTab] = useState(1);

  const [rentProperties, setRentProperties] = useState([]);
  const [buyProperties, setBuyProperties] = useState([]);
  const [commercialProperties, setCommercialProperties] = useState([]);

  //use redux hooks
  const dispatch = useDispatch();
  const { rent_properties = {} } = useSelector((state) => state.rentData);
  const { buy_properties = {} } = useSelector((state) => state.buyData);
  const { commercial_properties = {} } = useSelector(
    (state) => state.commercialData
  );

  useEffect(() => {
    dispatch(getBuyRequest());
    dispatch(getRentRequest());
    dispatch(getCommercialRequest());
  }, [dispatch]);

  useEffect(() => {
    // Update local state when experts_profile changes
    if (Object.keys(buy_properties).length !== 0) {
      setBuyProperties(buy_properties.data || []);
    }

    if (Object.keys(rent_properties).length !== 0) {
      setRentProperties(rent_properties.data || []);
    }

    if (Object.keys(commercial_properties).length !== 0) {
      setCommercialProperties(commercial_properties.data || []);
    }
  }, [buy_properties, rent_properties, commercial_properties]);

  //filter available rent property from rent_properties.data
  const expert_rent_properties = rentProperties.filter(
    (obj) =>
      obj.agents.some(agent => 
        Array.isArray(agent.user_id) 
          ? agent.user_id.includes(expertID) 
          : agent.user_id === expertID
      ) &&
      obj.status === false &&
      obj.category === "rent"
  );
  

  //filter available buy property from buy_properties.data
  const expert_buy_properties = buyProperties.filter(
    (obj) =>
      obj.agents.some(agent => 
        Array.isArray(agent.user_id) 
          ? agent.user_id.includes(expertID) 
          : agent.user_id === expertID
      ) &&
      obj.status === false &&
      obj.category === "buy"
  );

  const expert_buy_sold_properties = buyProperties.filter(
    (obj) =>
      obj.agents.some(agent => 
        Array.isArray(agent.user_id) 
          ? agent.user_id.includes(expertID) 
          : agent.user_id === expertID
      ) &&
      obj.status === true &&
      obj.category === "buy"
  );

  //filter available commercial property from commercial_properties.data
  const expert_commercial_properties = commercialProperties.filter(
    (obj) =>
      obj.agents.some(agent => 
        Array.isArray(agent.user_id) 
          ? agent.user_id.includes(expertID) 
          : agent.user_id === expertID
      ) &&
      obj.status === true &&
      obj.category === "commercial"
  );

  const handleTabClick = (tabNumber) => {
    if (tabNumber === 1) {
      setActiveTab(tabNumber);
    } else if (tabNumber === 2) {
      setActiveTab(tabNumber);
    } else if (tabNumber === 3) {
      setActiveTab(tabNumber);
    } else {
      setActiveTab(tabNumber);
    }
  };

  return (
    <div>
      <div className="space-y-3 uppercase text-center">
        <h1 className="font-playfair text-xl ">Property Portfolio</h1>
        <div className="flex flex-wrap justify-center space-x-3 text-[14px]">
          <p
            className={`truncate cursor-pointer p-1 ${
              activeTab === 1 ? "text-yellow-light" : "text-black"
            }`}
            onClick={() => handleTabClick(1)}
          >
            For Sale
          </p>
          <p
            className={`truncate cursor-pointer p-1 ${
              activeTab === 2 ? "text-yellow-light" : "text-black"
            }`}
            onClick={() => handleTabClick(2)}
          >
            For Rent
          </p>
          <p
            className={`truncate cursor-pointer p-1 ${
              activeTab === 3 ? "text-yellow-light" : "text-black"
            }`}
            onClick={() => handleTabClick(3)}
          >
            Sold
          </p>
          <p
            className={`truncate cursor-pointer p-1 ${
              activeTab === 4 ? "text-yellow-light" : "text-black"
            }`}
            onClick={() => handleTabClick(4)}
          >
            Leased Commercial
          </p>
        </div>
      </div>
      {/* outlet */}
      <div className="flex flex-col items-center py-10">
        {/* For sale */}
        {expert_buy_properties.length !== 0 && activeTab === 1 && (
          <div className="py-5 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
            {expert_buy_properties.map((item) => (
              <BuyCardComponent key={item.id} data={item} />
            ))}
          </div>
        )}

        {/* For rent */}
        {expert_rent_properties.length !== 0 && activeTab === 2 && (
          <div className="py-5 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
            {expert_rent_properties.map((item) => (
              <RentCardComponent key={item.id} data={item} />
            ))}
          </div>
        )}

        {/* For sold */}
        {expert_buy_sold_properties.length !== 0 && activeTab === 3 && (
          <div className="py-5 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
            {expert_buy_sold_properties.map((item) => (
              <SoldCardComponent key={item.id} data={item} />
            ))}
          </div>
        )}

        {/* For leased commercial */}
        {expert_commercial_properties.length !== 0 && activeTab === 4 && (
          <div className="py-5 flex flex-col justify-center sm:flex-row flex-wrap gap-5">
            {expert_commercial_properties.map((item) => (
              <LeasedCommercialCardComponent key={item.id} data={item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyPortfolioTab;
