export const service_property_management = {
    title: 'PROPERTY MANAGEMENT',
    sub_title: 'Let us help you get more from your investment',
    para1: 'Primus Agents understands the importance of extracting the most value from your investment properties. Unlike most real estate brands, our management professionals are not working for a wage, they own the rent rolls they manage. This means the value you receive for your investment asset directly effects the value the Primus management professional receives. In other words our interests are directly aligned with yours!',
    para2: 'Contact primus agents today to discuss management options!'
}

export const service_commercial = {
    title: 'COMMERCIAL',
    sub_title: 'Let us help you find or sell your commercial space',
    para1: 'For your business to succeed, you need to be in the right place at the right time. That’s where we come in. Talk to our primus experts today about our commercial property for sales and commercial property for lease today.​',
    para2: 'Our national network of experienced agents, Primus Commercial conducts meticulous analysis and provides trusted advice to business owners nationally. Using our customized software and support, our agents provide comprehensive options and marketing catered for you. With proven track records Primus Commercial agents makes it their business to develop an understanding of yours.'
}

export const service_conveyancing = {
    title: 'CONVEYANCING',
    sub_title: 'Made Easy',
    card:[
        {
            id: 1,
            title: 'SIMPLICITY',
            description:'Choose your package, provide your information and check your inbox. It’s that simple.',
            image: require('../assets/services-images/simplicity.png'),
        },
        {
            id: 2,
            title: 'ON-TIME GUARANTEE',
            description:'If your settlement is delayed due to Primus conveyancing, you don’t pay anything.',
            image: require('../assets/services-images/timer.png'),
        },
        {
            id: 3,
            title: 'COST EFFECTIVE',
            description:'Don’t pay more than you have to for conveyancing. Primus conveyancing does more for less.',
            image: require('../assets/services-images/cost-effective.png'),
        }
    ],
    para1: 'Primus Conveyancing is your one stop shop for all of your conveyancing needs. Whether you are buying or selling, our services will make sure that you are covered throughout the process and our on-time guarantee will ensure we do not delay the settlement process.',
    para2: 'It is very important to be aware of what services your conveyancer is performing. Although some conveyancers may seem cheaper, you need to be careful because they may not be providing all of the required services for that quote. Complete the form below to find out more about Primus Conveyancing’s services and pricing.',
}