import React, { useState, useEffect } from "react";
import { IoDocumentsOutline } from "react-icons/io5";
import { BsFiletypePdf } from "react-icons/bs";
import { MdOutlineDownload, MdGridView } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getDocumentRequest } from "../../redux/document-store/actions";
import { getFileNameExtension } from "../../utils/getFileNameExtention";
import PuffLoader from "react-spinners/PuffLoader";

const DocumentsPageLayout = () => {
  const [propertyDocuments, setPropertyDocuments] = useState([]);

  //use redux hooks
  const dispatch = useDispatch();
  const { isLoading, property_documents } = useSelector(
    (state) => state.documentData
  );

  useEffect(() => {
    dispatch(getDocumentRequest());
  }, [dispatch]);

  useEffect(() => {
    // Update local state when experts_profile changes
    if (property_documents && Object.keys(property_documents).length !== 0) {
      setPropertyDocuments(property_documents.data || []);
    } else {
      setPropertyDocuments([]);
    }
  }, [property_documents]);

  //filter available rent property from rent_properties.data
  const available_rent_documents = propertyDocuments.filter(
    (obj) => obj.category === "rent"
  );

  const openPDFViewer = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] pt-28 lg:pt-32 bg-gray flex gap-2 uppercase items-center py-5 font-playfair">
        <IoDocumentsOutline className="w-5 h-5" />
        <p>Important Documents</p>
      </div>
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] h-[55vh]">
        <div>
          <div className="flex gap-2 items-center text-[14px]">
            <MdGridView className="w-4 h-4" />
            <h1 className="uppercase py-5">Rent Forms</h1>
          </div>
          <div>
            {isLoading ? (
              <div className="h-[30vh] w-full flex items-center justify-center">
                <PuffLoader color="#36d7b7" size={100} />
              </div>
            ) : (
              available_rent_documents.map((file, index) => (
                <div key={index} className="flex gap-2 items-center">
                  <p>{index + 1}.</p>
                  <div className="flex items-center gap-[5vw]">
                    <div className="flex gap-2 items-center">
                      <BsFiletypePdf color="#FF0000" />
                      <p
                        className="truncate hover:underline hover:text-midnight cursor-pointer"
                        onClick={() => openPDFViewer(file.url)}
                      >
                        {file.name + getFileNameExtension(file.url)}
                      </p>
                    </div>
                    <a href={file.url} target="_blank" rel="noreferrer">
                      <MdOutlineDownload className="w-8 h-8 p-2 bg-gray rounded-full cursor-pointer hover:bg-ash-200/40" />
                    </a>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsPageLayout;
