import React from 'react'
import StrataListComponent from './component/StrataListComponent';
import { conclusion, strata_additional, strata_community, strata_financial, strata_insurance, strata_management, strata_thirdParty } from '../../data/strataData';

const StrataPageLayout = () => {
  return (
    <>
      <div className='mb-10 h-[300px] bg-yellow flex flex-col justify-center items-center'>
        <h1 className='pt-24 text-4xl text-center'>STRATA MANAGEMENT</h1>
        <p className='my-2 px-[5vw] sm:px-[10vw] xl:px-[15vw] text-center'>If you are looking for a scheme in any of the following capacities, we are here to help.</p>
      </div>
      <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw]'>
      <div>
        <h1 className='mb-4 text-xl lg:text-3xl'>{strata_management.title}</h1>
        <StrataListComponent data={strata_management.details} title={strata_management.description}/>
      </div>
      <div>
        <h1 className='mb-4 mt-10 text-xl lg:text-3xl'>{strata_community.title}</h1>
        <StrataListComponent data={strata_community.details} title={strata_community.description}/>
      </div>
      <div>
        <h1 className='mb-4 mt-10 text-xl lg:text-3xl'>{strata_financial.title}</h1>
        <StrataListComponent data={strata_financial.details} title={strata_financial.description}/>
      </div>
      <div>
        <h1 className='mb-4 mt-10 text-xl lg:text-3xl'>{strata_thirdParty.title}</h1>
        <StrataListComponent data={strata_thirdParty.details} title={strata_thirdParty.description}/>
      </div>
      <div>
        <h1 className='mb-4 mt-10 text-xl lg:text-3xl'>{strata_insurance.title}</h1>
        <StrataListComponent data={strata_insurance.details} title={strata_insurance.description}/>
      </div>
      <div>
        <h1 className='mb-4 mt-10 text-xl lg:text-3xl'>{strata_additional.title}</h1>
        <StrataListComponent data={strata_additional.details} title={strata_additional.description}/>
      </div>
      <div className='space-y-2 mt-6 mb-12'>
        <p>{conclusion.first}</p>
        <p>{conclusion.last}</p>
      </div>
    </div>
    </>
  )
}

export default StrataPageLayout;