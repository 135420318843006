export const searchExpert = (experts, searchInputData) => {
  if (searchInputData.name === "" && searchInputData.location === "") {
    return experts;
  }
    
  const filteredExpert = experts.filter((expert) => {
    return Object.entries(searchInputData).every(([key, value]) => {
      // Handle special cases (e.g., case-insensitive string comparison)
      switch (key) {
        case "location":
          return expert.location.toLowerCase().includes(value.toLowerCase());
        case "name":
          return expert.name.toLowerCase().includes(value.toLowerCase());
        default:
          return expert[key] === value;
      }
    });
  });
  return filteredExpert;
};
