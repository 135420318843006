import React, { useEffect, useState } from "react";
import RentSearchComponent from "./component/RentSearchComponent";
import RentCardComponent from "./component/RentCardComponent";
import Pagination from "../../components/Pagination";
import NoPropertyFound from "../../components/NoPropertyFound";
import { searchProperty } from "../../utils/searchProperty";
import { useSelector, useDispatch } from "react-redux";
import { getRentRequest } from "./ReduxStore/actions";
import PuffLoader from "react-spinners/PuffLoader";
import { getExpertRequest } from "../ExpertsPage/ReduxStore/actions";
import { useLocation } from "react-router-dom";

const itemsPerPage = 8;

const RentPageLayout = () => {
  const queryLocation = useLocation();
  const queryParams = new URLSearchParams(queryLocation.search);
  const receivedData = queryParams.get("search");

  const [searchData, setSearchData] = useState([]);
  const [searchOutlet, setSearchOutlet] = useState(false);
  const [rentProperties, setRentProperties] = useState([]);

  //use redux hooks
  const dispatch = useDispatch();
  const { isLoading, rent_properties } = useSelector((state) => state.rentData);

  useEffect(() => {
    dispatch(getRentRequest());
    // call expert_profile
    dispatch(getExpertRequest());
  }, [dispatch]);

  useEffect(() => {
    // Update local state when experts_profile changes
    if (Object.keys(rent_properties).length !== 0) {
      setRentProperties(rent_properties.data || []);
    }
  }, [rent_properties]);

  //filter available rent property from rent_properties.data
  const available_rent_properties = rentProperties.filter(
    (obj) => obj.status === false && obj.category === "rent"
  );

  //it used when home page search active
  useEffect(() => {
    if (receivedData !== null) {
      const filterExpert = searchProperty(rentProperties, {
        suburb: receivedData,
      });
      setSearchData(filterExpert);
      setSearchOutlet(true);
    }
  }, [receivedData, rentProperties]);

  const handleSearchInput = (searchCriteria) => {
    const filteredProperties = searchProperty(rentProperties, searchCriteria);
    setSearchData(filteredProperties);
    setSearchOutlet(true);
  };

  return (
    <>
      <RentSearchComponent
        totalProduct={available_rent_properties.length}
        getInput={handleSearchInput}
      />
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] py-10">
        {isLoading ? (
          <div className="h-[30vh] w-full flex items-center justify-center">
            <PuffLoader color="#36d7b7" size={100} />
          </div>
        ) : (
          <>
            {searchOutlet ? (
              <>
                {searchData.length === 0 ? (
                  <NoPropertyFound />
                ) : (
                  <>
                    <Pagination
                      items={searchData}
                      itemsPerPage={itemsPerPage}
                      componentName={RentCardComponent}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {available_rent_properties.length === 0 ? (
                  <NoPropertyFound />
                ) : (
                  <Pagination
                    items={available_rent_properties}
                    itemsPerPage={itemsPerPage}
                    componentName={RentCardComponent}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RentPageLayout;
