import React from "react";
import { Button } from "../../../components/Button";
import { service_commercial } from "../../../data/servicesData";
import { NavLink } from "react-router-dom";

const CommercialSection = () => {
  return (
    <div className="bg-gray flex flex-col w-full items-center px-[5vw] sm:px-[10vw] xl:px-[15vw]">
      <div className="py-16 space-y-8 text-center xl:w-[50vw]">
        <div className="space-y-2">
          <h1 className="text-xl lg:text-2xl font-playfair">{service_commercial.title}</h1>
          <p>{service_commercial.sub_title}</p>
        </div>
        <div className="space-y-2 text-justify text-[#4c4c09]">
          <p>{service_commercial.para1}</p>
          <p>{service_commercial.para2}</p>
        </div>
        <div>
          <NavLink to="/commercial">
            <Button name="View Available Properties" className="px-5 py-2 bg-yellow-light text-ash-500"/>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default CommercialSection;
