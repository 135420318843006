import React, { useState, useEffect } from "react";
import { ButtonIcon } from "../../../components/Button";
import { BsSearch } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const ExpertSearchComponent = ({ getExpertInput }) => {
  const queryLocation = useLocation();
  const queryParams = new URLSearchParams(queryLocation.search);
  const receivedData = queryParams.get("search");

  const [searchData, setSearchData] = useState({ name: "", location: "" });

  //used it for search param
  useEffect(() => {
    if (receivedData !== null) {
      setSearchData({ location: receivedData });
    }
  }, [receivedData]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    getExpertInput(searchData);
  };

  return (
    <div className="pt-28 lg:pt-32 pb-10 px-[5vw] sm:px-[10vw] xl:px-[15vw] bg-yellow flex flex-col gap-4">
      <div className="flex flex-col gap-1 text-center">
        <h1 className="uppercase text-xl lg:text-2xl text-ash-500">
          SEARCH EXPERTS
        </h1>
        <p className="text-ash-500">
          Find an expert who knows your market best
        </p>
      </div>
      <form
        className="flex flex-col gap-3 md:flex-row"
        onSubmit={handleFormSubmit}
      >
        <input
          type="text"
          className="w-full px-4 py-2.5 md:px-6 md:py-4 rounded-xl md:rounded-full shadow-custom-500 focus:outline-gray border border-white-500"
          placeholder="Search by Name"
          value={searchData.name}
          onChange={(event) =>
            setSearchData({
              ...searchData,
              name: event.target.value,
            })
          }
        />
        <input
          type="text"
          className="w-full px-4 py-2.5 md:px-6 md:py-4 rounded-xl md:rounded-full shadow-custom-500 focus:outline-gray border border-white-500"
          placeholder="Search by Suburb"
          value={searchData.location}
          onChange={(event) =>
            setSearchData({
              ...searchData,
              location: event.target.value,
            })
          }
        />
        <ButtonIcon
          className="bg-white px-4 py-2.5 md:px-10 md:py-4 rounded-xl md:rounded-full shadow-custom-500 cursor-pointer w-full md:w-fit flex justify-center border border-white-500"
          Icon={<BsSearch className="w-8 h-6" />}
          type="submit"
        />
      </form>
    </div>
  );
};

export default ExpertSearchComponent;
