import React from "react";
import {
  BiLogoFacebook,
  BiLogoYoutube,
  BiLogoLinkedin,
  BiLogoInstagram,
} from "react-icons/bi";
import { social_link } from "../../data/footerData";
import { NavLink } from "react-router-dom";
import { Image } from "../../components/Image";
import deft_image_url from "../../assets/footer-images/deft-logo-sized.svg";
import gomanager_image_url from "../../assets/footer-images/go-manager-logo.png";
import agent_matrix_image_url from "../../assets/footer-images/agent-matrix-logo.png";

const Footer = () => {
  return (
    <div className="bg-ash text-white-500 py-10 px-[5vw] xl:px-[12vw] flex flex-col items-center gap-8 text-base md:items-center md:flex-row md:justify-between">
      {/* company info */}
      <div className="flex flex-col gap-8">
        <div className="flex flex-col items-center md:items-start text-md gap-1">
          <p className="font-bold">Primus Pty Ltd.</p>
          {/* <a href="#number" target="_blank" className="hover:text-yellow">
            18008&nbsp;Primus
          </a> */}
          <a href="mailto:" className="hover:text-yellow">
            support@primusagents.com.au
          </a>
        </div>
      </div>
      {/* middle section */}
      <div className="flex flex-col items-center gap-4 justify-center">
        <a href="https://www.deft.com.au/" target="_blank" rel="noreferrer">
          <Image src={deft_image_url} className="h-[23px]" />
        </a>
        <a href="https://managedapp.com.au/" target="_blank" rel="noreferrer">
          <Image src={gomanager_image_url} className="h-[30px]" />
        </a>
        {/* <div className="flex gap-2 items-center">
          <p>Powered By</p>
          <Image src={agent_matrix_image_url} className="h-[70px]" />
        </div> */}
      </div>
      <div className="flex flex-col gap-2 items-center">
        <p>Powered By</p>
        <a href="https://agentmatrix.com.au/" target="_blank" rel="noreferrer">
          <Image src={agent_matrix_image_url} className="h-[70px]" />
        </a>
      </div>
      {/* social link */}
      <div className="flex flex-col items-center md:items-start gap-2">
        <div className="flex gap-3">
          <a href={social_link.facebook} target="_blank" rel="noreferrer">
            <BiLogoFacebook className="h-7 w-7 p-1 bg-gray rounded-full transition-transform duration-300 ease-in-out hover:-translate-y-1 text-[#1877F2]" />
          </a>
          <a href={social_link.linkedin} target="_blank" rel="noreferrer">
            <BiLogoLinkedin className="h-7 w-7 p-1 bg-gray rounded-full transition-transform duration-300 ease-in-out hover:-translate-y-1 text-[#0072b1]" />
          </a>
          <a href={social_link.instagram} target="_blank" rel="noreferrer">
            <BiLogoInstagram className="h-7 w-7 p-1 bg-gray rounded-full transition-transform duration-300 ease-in-out hover:-translate-y-1 text-[#d62976]" />
          </a>
          <a href={social_link.youtube} target="_blank" rel="noreferrer">
            <BiLogoYoutube className="h-7 w-7 p-1 bg-gray rounded-full transition-transform duration-300 ease-in-out hover:-translate-y-1 text-[#CD201F]" />
          </a>
        </div>
        <div className="flex flex-col items-center md:items-start gap-1  text-md">
          <p>&copy;&nbsp; copyright Primus Pty Ltd 2023</p>
          <NavLink
            to="/privacy-policy"
            className="hover:text-yellow underline decoration-1"
          >
            Privacy Policy
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
