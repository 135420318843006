import React from 'react'
import { Image } from '../../../components/Image';
import { sets_us_apart_data } from '../../../data/sellData';

const SetUsApartSection = () => {
  return (
    <div className='px-[5vw] sm:px-[10vw] xl:px-[15vw] sm:h-[350px] pt-14 pb-28 sm:py-0 bg-gray flex flex-col sm:flex-row gap-[5vw]'>
        <Image src={sets_us_apart_data.image_url} className="w-full max-h-[300px] sm:hidden" />
        <div className='flex flex-col gap-3 justify-center lg:w-[30vw]'>
            <h1 className='text-2xl lg:text-4xl font-playfair'>{sets_us_apart_data.title}</h1>
            <p className='text-base text-[#4c4c09]'>{sets_us_apart_data.description}</p>
        </div>
        <Image src={sets_us_apart_data.image_url} className="hidden sm:block sm:w-1/2 lg:w-[350px] h-[350px] sm:rounded-t-full sm:mt-[-70px]" />
    </div>
  )
}

export default SetUsApartSection;