import React from 'react'
import { Button } from '../../../components/Button';
import {useNavigate} from 'react-router-dom';

const RegisterButtonSection = () => {
  const navigate = useNavigate();

  return (
    <div className='bg-gray px-[5vw] sm:px-[10vw] xl:px-[10vw] flex items-center justify-center w-full py-5'>
        <div className='flex flex-col sm:flex-row gap-2 md:gap-5 items-center justify-center max-w-[350px] sm:max-w-full'>
            <p className='text-black/90 text-lg md:text-xl text-center font-semibold'>Register to receive the latest property news and alerts</p>
            <Button name="Register" className="px-3 py-1 bg-yellow-light text-ash-500" onClick={()=>navigate("/client-login")}/>
        </div>
    </div>
  )
}

export default RegisterButtonSection;